import { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Form } from "react-bootstrap"
import useValidationSchema from "../../customHooks/useValidation"
import { inviteAdmin, clearInviteAdminData } from "../../redux/actions/listingActions"
import { emailSchema, handleValidate } from "../../helpers/validation"
import Modal from "../../components/Modal"
import InviteModal from "../../components/Modal/InviteModal"
import css from "./Admins.module.scss"

const NewAdminModal = ({ onHide, show, inviteAdmin, adminFetching, adminError, adminData, clearInviteAdminData }) => {
   const [inputData, setInputData] = useState("")
   const [inviteModal, setInviteModal] = useState(false)
   const [formErrors, setFormErrors] = useState({
      email: "",
   })
   const validate = useValidationSchema(emailSchema, { email: inputData })

   const handleChange = (e) => {
      const { value } = e.target
      setInputData(value)
   }

   const handleSubmit = async () => {
      const isValidate = await handleValidate(setFormErrors, validate)

      if (isValidate) {
         // console.log("create modal")
         inviteAdmin({
            email: inputData,
         })
      }
   }

   useEffect(() => {
      if (adminError || adminData) {
         onHide()
         setInviteModal(true)
      }
   }, [adminError, adminData, onHide])

   const handleCancel = () => {
      onHide()
      setInputData("")
      setFormErrors({ email: "" })
   }

   const handleCloseInvite = () => {
      setInviteModal(false)
      setInputData("")
      clearInviteAdminData()
   }

   return inviteModal ? (
      <InviteModal show={inviteModal} onHide={handleCloseInvite} error={adminError}>
         <div className={css.ownerSelected__item_data}>
            {adminError ? (
               <p>{adminError}</p>
            ) : (
               <>
                  <p>An onboarding email has been sent</p>
                  <p>to {inputData}</p>
               </>
            )}
         </div>
      </InviteModal>
   ) : (
      <Modal
         title="Invite new admin"
         subtitle="Enter the email address of the person that you would like to invite. This person will then receive an invitation email with a link to complete their onboarding."
         show={show}
         onHide={handleCancel}
         handleSubmit={handleSubmit}
         btnCreateText="Send invite"
         btnDisable={Boolean(!inputData)}
      >
         <div className={css.ownerForm}>
            <Form.Group className="mb-3">
               <Form.Label>Email</Form.Label>
               <Form.Control
                  type="email"
                  placeholder="Email address"
                  onChange={handleChange}
                  value={inputData}
                  isInvalid={!!formErrors.email}
               />
               <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
            </Form.Group>
         </div>
      </Modal>
   )
}

const mapStateToProps = (state) => {
   return {
      adminFetching: state.inviteAdmin.fetching,
      adminError: state.inviteAdmin.error,
      adminData: state.inviteAdmin.data,
   }
}
export default connect(mapStateToProps, { inviteAdmin, clearInviteAdminData })(NewAdminModal)
