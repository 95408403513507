import axios from "axios"
import LocalStorageService from "../helpers/storage"
import history from "../history"

const LOGIN_URL = "/login"
const LOG_OUT_URL = "/logout"
const REFRESH_TOKEN_URL = "/refresh-token"

//export const BASE_URL = "https://portal.scaniahub-stage.cetrez.net" // staging
export const BASE_URL = "https://portal.scaniahub.cetrez.com" // prod
// export const BASE_URL = "http://192.168.3.49:8083" //local
export const API_URL = `${BASE_URL}/api/v1`

const instance = axios.create({
   baseURL: API_URL,
   responseType: "json",
   headers: {
      "Content-Type": "application/json",
   },
})

// Add a request interceptor
instance.interceptors.request.use(
   (config) => {
      const token = LocalStorageService.getAccessToken()
      if (token) {
         config.headers["Authorization"] = "Bearer " + token
      }
      // config.headers['Content-Type'] = 'application/json';
      return config
   },
   (error) => {
      Promise.reject(error)
   }
)

//Add a response interceptor
instance.interceptors.response.use(
   (response) => {
      if (response.config.url === LOGIN_URL) {
         LocalStorageService.setToken(response.data)

         history.push("/")
      } else if (response.config.url === LOG_OUT_URL) {
         LocalStorageService.clearToken()

         history.push(LOGIN_URL)
      }
      return response
   },
   async (error) => {
      const originalRequest = error.config
      const errorStatus = error.response.status

      // console.log("error.url", originalRequest.url)
      // console.log("errorStatus", errorStatus)
      // console.log("cond", error.response.status === 401 && originalRequest.url === REFRESH_TOKEN_URL)
      // console.log(" originalRequest._retry", originalRequest._retry)

      if ((errorStatus === 401 || errorStatus === 422) && originalRequest.url === REFRESH_TOKEN_URL) {
         history.push(LOGIN_URL)
         LocalStorageService.clearToken()
         return Promise.reject(error)
      }

      if (errorStatus === 401 && !originalRequest._retry) {
         originalRequest._retry = true
         const refreshToken = LocalStorageService.getRefreshToken()
         const resultRefresh = await instance.post(REFRESH_TOKEN_URL, {
            refresh_token: refreshToken,
         })
         if (resultRefresh.status === 200 || resultRefresh.status === 201) {
            LocalStorageService.setToken(resultRefresh.data)
            instance.defaults.headers.common["Authorization"] = "Bearer " + LocalStorageService.getAccessToken()
            return instance(originalRequest)
         }
      }
      return Promise.reject(error)
   }
)

export default instance
