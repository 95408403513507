import React, { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import useValidationSchema from "../../../customHooks/useValidation"
import { FPCCodesSchema, handleValidate } from "../../../helpers/validation"

import css from "../MoviesEdit.module.scss"

const CodesForm = ({ handleSave, value, status, setStatisticsView }) => {
   const [formData, setFormData] = useState({
      fpc_codes: "",
   })
   const [touched, setTouched] = useState(false)
   const [formErrors, setFormErrors] = useState({})
   const validate = useValidationSchema(FPCCodesSchema, formData)

   useEffect(() => {
      setFormData({
         fpc_codes: value ? value : "",
      })
      setFormErrors({})
      setTouched(false)
   }, [value])

   const handleChange = (e) => {
      const { name, value } = e.target
      setFormData({
         ...formData,
         [name]: value,
      })
      if (status === 1) {
         setTouched(false)
      } else {
         setTouched(true)
      }
   }

   const handleSubmit = async () => {
      const isValid = await handleValidate(setFormErrors, validate)

      if (isValid) {
         handleSave(formData)
         setTouched(false)
      }
   }

   return (
      <Form className={css.settings__form}>
         <Form.Group className="mb-4">
            <Form.Control
               as="textarea"
               rows={3}
               placeholder="Enter configuration code combination for this movie"
               onChange={handleChange}
               value={formData.fpc_codes}
               name="fpc_codes"
               isInvalid={!!formErrors.fpc_codes}
            />
            <Form.Control.Feedback type="invalid">{formErrors.fpc_codes}</Form.Control.Feedback>
         </Form.Group>

         <div className={css.settings__btns}>
            <div className={css.settings__btns_tip}>
               Enter your configuration code or make a combination of codes by separating them with a + You can also add
               a statement to match with any of a list of codes with a parenthases and comma separated codes Example:
               08344B+05113G+(04579A, 04579B, 04579D, 04579F)
            </div>
            { status === 1 ?
                <Button onClick={() => setStatisticsView(true)} variant="outline-primary">
                   View Statistics{" "}
                </Button>
                :
               <Button disabled={!touched} onClick={handleSubmit}>
                  {touched ? "Save" : "Saved"}
               </Button>}
         </div>
      </Form>
   )
}
export default CodesForm
