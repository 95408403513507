import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { getSurvey } from "../../redux/actions/listingActions"
import CreateBtnHeader from "../../components/CreateBtnHeader"
import Header from "../../components/Header"
import SearchBar from "../../components/SearchBar"
import CreateSurveyModal from "./CreateSurveyModal"
import ItemsList from "../../components/TableLists/ItemsList"

const dataTitles = [
   {
      name: "Title",
      key: "title",
   },
   {
      name: "Views",
      key: "viewed_count",
      default: 0,
   },
   {
      name: "Published",
      key: "published_at",
   },
   {
      name: "Published by",
      key: "published_by",
   },
   {
      name: "Status",
      key: "status",
      default: "Draft",
   },
]

const Surveys = ({ getSurvey, data, fetch, error }) => {
   const [modalShow, setModalShow] = useState(false)

   useEffect(() => {
      getSurvey()
   }, [getSurvey])

   const handleSearch = (inputValue) => {
      getSurvey(null, 1, inputValue)
   }
   const getDataByQuery = (sort, page = 1) => {
      getSurvey(sort, page)
   }

   return (
      <>
         <Header title="Surveys">
            <SearchBar handleSearch={handleSearch} />
            <CreateBtnHeader title="Create survey" clickHandler={() => setModalShow(true)} />
         </Header>

         <ItemsList
            urlEdit="/surveys/edit/"
            initialSort={"title"}
            data={data}
            fetch={fetch}
            error={error}
            getDataByQuery={getDataByQuery}
            dataTitles={dataTitles}
         />
         <CreateSurveyModal show={modalShow} onHide={() => setModalShow(false)} />
      </>
   )
}

const mapStateToProps = (state) => ({
   data: state.surveys.data,
   fetch: state.surveys.fetch,
   error: state.surveys.error,
})

export default connect(mapStateToProps, { getSurvey })(Surveys)
