import React from "react"
import { Route, Redirect } from "react-router-dom"
import LocalStorageService from "./helpers/storage"

const isAuthenticated = () => {
   const token = LocalStorageService.getAccessToken()

   return token
}

export const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => {
   return (
      <Route
         {...rest}
         render={(props) => <Layout>{!isAuthenticated() ? <Component {...props} /> : <Redirect to="/" />}</Layout>}
      />
   )
}

export const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
   return (
      <Route
         {...rest}
         render={(props) => <Layout>{isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />}</Layout>}
      />
   )
}
