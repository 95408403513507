import {useEffect, useState} from "react"
import { Form } from "react-bootstrap"
import Modal from "../../components/Modal"
import useValidationSchema from "../../customHooks/useValidation"
import { createCampaignSchema, handleValidate } from "../../helpers/validation"
import "./Surveys.module.scss"
import {clearSurvey, createSurvey} from "../../redux/actions/listingActions";
import {connect} from "react-redux";
import history from "../../history";

const CreateSurveyModal = ({ onHide, show, data, fetching, error, clearSurvey, createSurvey }) => {
   const [formData, setFormData] = useState({
      title: "",
   })
   const [formErrors, setFormErrors] = useState({
      title: "",
   })
   const validate = useValidationSchema(createCampaignSchema, formData);

   useEffect(() => {
      if (data?.id && !fetching && !error) {
         clearSurvey();
         history.push(`/surveys/edit/${data.id}`);
      }
   }, [data, fetching, error, clearSurvey]);

   const handleChange = (e) => {
      const { name, value } = e.target
      setFormData({
         ...formData,
         [name]: value,
      });
   }

   const handleCreate = async () => {
      const isValidate = await handleValidate(setFormErrors, validate)

      if (isValidate) {
         await createSurvey({
            title: formData.title,
         });
      }
   }
   const handleCancel = () => {
      onHide()
      setFormData({
         title: "",
      })
   }

   return (
      <Modal
         title="Create Survey"
         subtitle="Enter the internal title of your survey."
         show={show}
         onHide={handleCancel}
         handleSubmit={handleCreate}
         btnCreateText="Create survey"
      >
         <Form.Group className="mb-3">
            <Form.Label>Internal title</Form.Label>
            <Form.Control
               type="text"
               placeholder="Enter internal title"
               onChange={handleChange}
               value={formData.title}
               name="title"
               isInvalid={!!formErrors.title}
            />
            <Form.Control.Feedback type="invalid">{formErrors.title}</Form.Control.Feedback>
         </Form.Group>
      </Modal>
   )
}

const mapStateToProps = state => ({
   data: state.createSurveys.data,
   fetching: state.createSurveys.fetching,
   error: state.createSurveys.error,
});

export default connect(mapStateToProps, {createSurvey, clearSurvey})(CreateSurveyModal)
