import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { getUserById, deleteUser } from "../../redux/actions/listingActions"
import HeaderUserPage from "../../components/HeaderUserPage"
import UserInformation from "./UserInformation"
import AssociatedVehicles from "./AssociatedVehicles"
import Analytics from "./Analytics"
import DeleteModal from "./DeleteModal"
import history from "../../history"
import { inviteOwnerBackgroundCall } from "../../redux/actions/listingActions"
import { Button } from "react-bootstrap"
import { useLocation } from "react-router-dom"

const UserInfo = ({ getUserById, user, deleteUser, inviteOwnerBackgroundCall }) => {
   const { id } = useParams()
   const [showModalDelete, setModalShowDelete] = useState(false)
   const [onboardingReady, setOnboardingReady] = useState(false)
   const query = new URLSearchParams(useLocation().search)
   useEffect(() => {
      getUserById(id)
   }, [id, getUserById])

   useEffect(() => {
      const isready = user && user.onboarding_ready
      setOnboardingReady(isready)
   }, [user])

   const handleDelete = () => {
      deleteUser(id)
      setModalShowDelete(false)
   }

   const handleRetrieveOwnerData = () => {
      inviteOwnerBackgroundCall({
         externalStaffReference: user.ext_staff_ref,
         externalCustomerReference: user.ext_customer_ref,
      })
      setOnboardingReady(false)
   }

   return (
      <>
         <div>
            <HeaderUserPage
               title={user && `${user.contact_first_name} ${user.contact_last_name}`}
               status={user && user.status}
               role="Admin"
               joined={user && user.created_at}
               handleDelete={() => setModalShowDelete(true)}
               btnDeleteText="Delete user"
               handleGoBack={() => history.push("/users")}
            />
            {query?.get("update") === "200" ? (
               <Button variant="outline-danger" onClick={handleRetrieveOwnerData}>
                  Update
               </Button>
            ) : (
               ""
            )}
            <UserInformation data={user} isOnboardingReady={onboardingReady} />
            <AssociatedVehicles id={id} />
            <Analytics id={id} />
         </div>
         <DeleteModal
            show={showModalDelete}
            onHide={() => setModalShowDelete(false)}
            handleSubmit={handleDelete}
            userName={user && `${user.contact_first_name} ${user.contact_last_name}`}
         />
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      user: state.userById.data,
      fetch: state.userById.fetching,
      error: state.userById.error,
   }
}
export default connect(mapStateToProps, { getUserById, deleteUser, inviteOwnerBackgroundCall })(UserInfo)
