import clx from "classnames"
import { Table, Button, Spinner } from "react-bootstrap"
import { ArrowDownIcon, ArrowUpIcon } from "../../assets/icons"

import history from "../../history"

import css from "./TableLists.module.scss"

const UsersTable = ({ url, data, getDataByQuery, dataTitles, sortConfig, setSortConfig, fetch, error }) => {
   const statusItem = (status) => {
      if (status === 0) {
         return "Not accepted"
      } else if (status === 1) {
         return "Active"
      } else {
         return "Inactive"
      }
   }

   const getIconDirection = (name) => {
      if (!sortConfig) {
         return
      }
      return sortConfig.key === name ? (
         sortConfig.direction === "desc" ? (
            <ArrowDownIcon />
         ) : (
            <ArrowUpIcon />
         )
      ) : (
         <ArrowDownIcon />
      )
   }

   const getClassNamesFor = (name) => {
      if (!sortConfig) {
         return
      }
      return sortConfig.key === name ? `btn-icon_active` : undefined
   }

   const requestSort = (key) => {
      let direction = "asc"
      if (sortConfig.key === key && sortConfig.direction === "asc") {
         direction = "desc"
      }

      setSortConfig({ key, direction })
      getDataByQuery({ key, direction })
   }

   const renderTitle = (item, key) => {
      switch (key) {
         case "first_name":
            const lastName = item.contact_last_name ? item.contact_last_name : item.last_name
            return <td key={key}>{`${item[key]} ${lastName}`}</td>
         case "status":
            return (
               <td key={key}>
                  <span className={clx(css.items__status, css[`items__status_${item.status}`])} />
                  {statusItem(item.status)}
               </td>
            )

         default:
            return <td key={key}>{item[key]}</td>
      }
   }

   return (
      <Table size="sm">
         <thead>
            <tr>
               {dataTitles.map((item) => {
                  return (
                     <th key={item.name}>
                        {item.name}{" "}
                        <Button
                           variant="icon"
                           onClick={() => requestSort(item.key)}
                           className={getClassNamesFor(item.key)}
                        >
                           {getIconDirection(item.key)}
                        </Button>
                     </th>
                  )
               })}
            </tr>
         </thead>
         <tbody>
            {fetch ? (
               <tr>
                  <td>
                     <Spinner animation="border" variant="primary" className={css.inviteFetching__spinner} />
                  </td>
               </tr>
            ) : error ? (
               <tr>
                  <td>Something went wrong</td>
               </tr>
            ) : data && data.data.length > 0 ? (
               data.data.map((item) => {
                  return (
                     <tr key={item.id} onClick={() => history.push(`${url}/${item.id}`)}>
                        {dataTitles &&
                           dataTitles.map((title) => {
                              return renderTitle(item, title.key)
                           })}
                     </tr>
                  )
               })
            ) : (
               <tr>
                  <td>No data to show</td>
               </tr>
            )}
         </tbody>
      </Table>
   )
}
export default UsersTable
