import React from "react"
import clx from "classnames"
import { Button } from "react-bootstrap"
import { GoBackIcon, DeleteIcon } from "../../assets/icons"

import css from "./HeaderUserPage.module.scss"

const HeaderUserPage = ({ title, status, role, joined, handleDelete, handleGoBack, btnDeleteText }) => {
   const statusItem = (status) => {
      if (status === 0) {
         return "Not accepted"
      } else if (status === 1) {
         return "Active"
      } else {
         return "Inactive"
      }
   }

   return (
      <>
         <header className={css.header}>
            <div className={css.header__btnGoBack}>
               <Button variant="icon" onClick={handleGoBack}>
                  <GoBackIcon />
               </Button>
            </div>
            <div className={css.header__right}>
               <h3>{title}</h3>
               <div className={css.header__info}>
                  <p>
                     Role: <span>{role}</span>
                  </p>
                  <p>
                     Joined: <span>{joined && joined.slice(0, 10)}</span>
                  </p>
                  <p>
                     Status: <span>{statusItem(status)}</span>{" "}
                     <span className={clx(css.header__info_status, css[`header__info_status_${status}`])} />
                  </p>
                  <Button onClick={handleDelete} variant="warning">
                     <DeleteIcon /> {btnDeleteText}
                  </Button>
               </div>
            </div>
         </header>
         <div className={css.header__space} />
      </>
   )
}
export default HeaderUserPage
