import React from "react"
import { Form } from "react-bootstrap"
import { createSliderWithTooltip, Range } from "rc-slider"
import "rc-slider/assets/index.css"
import css from "./Segmentation.module.scss"


const RangeSlider = createSliderWithTooltip(Range)

const TabVehicles = ({ data, onChange, handleChangeSlider }) => {
   const checkboxesVehicles = [
      {
         type: "Model",
         items: [
            {
               label: "Truck",
               name: "truck",
               checked: data.truck,
            },
            {
               label: "Tractor",
               name: "tractor",
               checked: data.tractor,
            },
         ],
      },
      {
         type: "Model year",
         range: data.model_year,
      },
      {
         type: "Engine",
         items: [
            {
               label: "13L",
               name: "l13",
               checked: data.l13,
            },
            {
               label: "16L / V8",
               name: "l16v8",
               checked: data.l16v8,
            },
            {
               label: "9L",
               name: "l9",
               checked: data.l9,
            },
            {
               label: "7L",
               name: "l7",
               checked: data.l7,
            },
            {
               label: "9L gas",
               name: "l9Gas",
               checked: data.l9Gas,
            },
            {
               label: "13L gas",
               name: "l13Gas",
               checked: data.l13Gas,
            },
            {
               label: "13 ethanol",
               name: "ethanol13",
               checked: data.ethanol13,
            },

            {
               label: "PHEV",
               name: "phev",
               checked: data.phev,
            },
            {
               label: "BEV",
               name: "bev",
               checked: data.bev,
            },
         ],
      },
      {
         type: "Gearbox",
         items: [
            {
               label: "Range split",
               name: "rangeSplit",
               checked: data.rangeSplit,
            },
            {
               label: "Range",
               name: "range",
               checked: data.range,
            },
            {
               label: "Automatic",
               name: "automatic",
               checked: data.automatic,
            },
         ],
      },

      {
         type: "Cab",
         items: [
            {
               label: "L",
               name: "cabL",
               checked: data.cabL,
            },
            {
               label: "P",
               name: "cabP",
               checked: data.cabP,
            },
            {
               label: "G",
               name: "cabG",
               checked: data.cabG,
            },
            {
               label: "R",
               name: "cabR",
               checked: data.cabR,
            },
            {
               label: "S",
               name: "cabS",
               checked: data.cabS,
            },
            {
               label: "Crewcab",
               name: "crewCab",
               checked: data.crewCab,
            },
         ],
      },
   ]
   return (
      <div className={css.tabInner}>
         {checkboxesVehicles.map((item) => {
            return (
               <div className={css.tabInner__item} key={item.type}>
                  <div className={css.tabInner__item_title}>
                     <p>{item.type}</p>
                  </div>
                  <div className={css.tabInner__item_list}>
                     {item.hasOwnProperty("items") ? (
                        item.items.map((checkbox) => {
                           return (
                              <Form.Check
                                 label={checkbox.label}
                                 checked={checkbox.checked}
                                 name={checkbox.name}
                                 onChange={onChange}
                                 type="checkbox"
                                 bsPrefix={css.tabInner__item_checkbox}
                                 key={checkbox.name}
                              />
                           )
                        })
                     ) : (
                        <RangeSlider
                           value={item.range}
                           min={2008}
                           max={2021}
                           tipFormatter={(value) => `${value}`}
                           onChange={(value) => handleChangeSlider(value)}
                           tipProps={{
                              placement: "top",
                              visible: true,
                           }}
                        />
                     )}
                  </div>
               </div>
            )
         })}
      </div>
   )
}
export default TabVehicles
