import React, { useEffect } from "react"
import { connect } from "react-redux"
import { getAnalyticsContent } from "../../redux/actions/analyticsActions"
import DoughnutChart from "./DoughnutChart"
import css from "./Analytics.module.scss"

const ContentAnalytics = ({ getAnalyticsContent, data }) => {
   useEffect(() => {
      getAnalyticsContent()
   }, [getAnalyticsContent])

   const arr = [
      {
         title: "Campaigns",
         value1: data?.campaigns.views,
         value2: data?.campaigns.clicks,
         name1: "Views",
         name2: "Clicks",
         count: data?.campaigns.count,
      },
      {
         title: "Leads",
         value1: data?.leads.booked,
         value2: data?.leads.performed,
         name1: "Booked",
         name2: "Performed",
         count: data?.leads.count,
      },
      {
         title: "News posts",
         value1: data?.news.views,
         value2: data?.news.clicks,
         name1: "Views",
         name2: "Clicks",
         count: data?.news.count,
      },
      {
         title: "Sustainability",
         value1: data?.sustainability.views,
         value2: data?.sustainability.clicks,
         name1: "Views",
         name2: "Clicks",
         count: data?.sustainability.count,
      },
      {
         title: "Movies",
         value1: data?.movies.views,
         value2: data?.movies.plays,
         name1: "Views",
         name2: "Plays",
         count: data?.movies.count,
      },
      {
         title: "Surveys",
         value1: data?.surveys.opened,
         value2: data?.surveys.discarded,
         name1: "Opened",
         name2: "Discarded",
         count: data?.surveys.count,
      },
   ]

   return (
      <div className={css.analytics__content}>
         <h2>Content Analytics</h2>
         <div className={css.analytics__grid}>
            {data &&
               arr.map((item) => {
                  return (
                     <DoughnutChart
                        key={item.title}
                        title={item.title}
                        value1={item.value1}
                        value2={item.value2}
                        name1={item.name1}
                        name2={item.name2}
                        count={item.count}
                     />
                  )
               })}
         </div>
      </div>
   )
}
const mapStateToProps = (state) => {
   return {
      data: state.analyticsContent.data,
      dataFetch: state.analyticsContent.fetching,
      dataError: state.analyticsContent.error,
   }
}
export default connect(mapStateToProps, { getAnalyticsContent })(ContentAnalytics)
