import React from "react"
import video from "../../assets/video/video.mp4"
import Poster from "../../assets/images/poster.jpg"
import css from "./Login.module.scss"

const Login = () => {
   return (
      <div className={css.loginPage}>
         <video className={css.loginPage__background} autoPlay loop muted poster={Poster}>
            <source src={video} type="video/mp4" />
            <source src={video} type="video/ogg" />
         </video>
      </div>
   )
}
export default Login
