import { useState } from "react"
import { connect } from "react-redux"
import clx from "classnames"
import { Button, Form } from "react-bootstrap"
import useValidationSchema from "../../../customHooks/useValidation"
import { changePasswordSchema, handleValidate } from "../../../helpers/validation"
import { changePassword } from "../../../redux/actions/userActions"
import Notifications from "../../../components/Notifications"
import css from "../Sidebar.module.scss"

const ChangePasswordModal = ({ onHide, changePassword, password, errorPassword }) => {
   const [formData, setFormData] = useState({
      currentPassword: "",
      newPassword: "",
      repeatNewPassword: "",
   })
   const [formErrors, setFormErrors] = useState({})

   const validate = useValidationSchema(changePasswordSchema, formData)

   const handleChange = (e) => {
      const { name, value } = e.target
      setFormData({
         ...formData,
         [name]: value,
      })
   }

   const handleSubmit = async () => {
      const isValid = await handleValidate(setFormErrors, validate)

      if (isValid) {
         changePassword({
            password: formData.currentPassword,
            new_password: formData.newPassword,
         })
         setFormData({
            currentPassword: "",
            newPassword: "",
            repeatNewPassword: "",
         })
         //onHide()
      }
   }

   // useEffect(() => {
   //    if (!errorPassword && Object.keys(password).length > 0) {
   //       onHide()
   //    }
   // }, [errorPassword, password, onHide])

   const disableBtn = () => {
      return Object.values(formData).includes("")
   }
   console.log("errorPassword", errorPassword)
   // console.log("password", password)
   return (
      <div className={clx("modalInner", css.modalForm)}>
         <Notifications />
         <div className={css.modalInvite__header}>
            <h3>Change Password</h3>
         </div>
         <Form>
            <Form.Group className="mb-4">
               <Form.Label>Current password</Form.Label>

               <Form.Control
                  type="password"
                  placeholder="Current password"
                  onChange={handleChange}
                  value={formData.currentPassword}
                  name="currentPassword"
                  isInvalid={!!formErrors.currentPassword}
               />
               <Form.Control.Feedback type="invalid">{formErrors.currentPassword}</Form.Control.Feedback>
               {errorPassword && <p className={css.modalForm__error}>{errorPassword}</p>}
               <div className={css.modalForm__description}>
                  <p>Your new password needs to meet the following requirements:</p>
                  <ul>
                     <li>Minimum of 16 characters</li>
                     <li>At least 1 letter</li>
                     <li>At least 1 number</li>
                     <li>At least 1 symbol</li>
                  </ul>
               </div>
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>New password</Form.Label>

               <Form.Control
                  type="password"
                  placeholder="New password"
                  onChange={handleChange}
                  value={formData.newPassword}
                  name="newPassword"
                  isInvalid={!!formErrors.newPassword}
               />
               <Form.Control.Feedback type="invalid">{formErrors.newPassword}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Repeat new password</Form.Label>

               <Form.Control
                  type="password"
                  placeholder="New password"
                  onChange={handleChange}
                  value={formData.repeatNewPassword}
                  name="repeatNewPassword"
                  isInvalid={!!formErrors.repeatNewPassword}
               />
               <Form.Control.Feedback type="invalid">{formErrors.repeatNewPassword}</Form.Control.Feedback>
            </Form.Group>

            <div className={css.modalForm__btns}>
               <Button variant="outline-primary" onClick={onHide}>
                  Close
               </Button>
               <Button onClick={handleSubmit} disabled={disableBtn()}>
                  Change Password
               </Button>
            </div>
         </Form>{" "}
      </div>
   )
}
const mapStateToProps = (state) => {
   return {
      password: state.password.data,
      errorPassword: state.password.error,
   }
}
export default connect(mapStateToProps, { changePassword })(ChangePasswordModal)
