import React, { useRef, useEffect, useCallback } from "react"
import { UserIcon, HamburgerIcon } from "../../../assets/icons"

import css from "../Sidebar.module.scss"
import {connect} from "react-redux";
import {getUserData} from "../../../redux/actions/userActions";

const UserBtn = ({ openSidebar, setOpenSideBar, refSidebar, user, getUserData }) => {
   const refBtn = useRef();

   useEffect(() => {
      getUserData();
   }, [getUserData]);

   const handleClickOutside = useCallback(
      (e) => {
         if (
            !refSidebar.current.contains(e.target) &&
            !refBtn.current.contains(e.target) &&
            !e.target.closest(".modal") &&
            !e.target.closest(".modalInner")
         ) {
            setOpenSideBar(false)
         }
      },
      [refSidebar, setOpenSideBar]
   )
   useEffect(() => {
      if (openSidebar) {
         document.addEventListener("mousedown", handleClickOutside)
      }
      return () => document.removeEventListener("mousedown", handleClickOutside)
   }, [openSidebar, handleClickOutside])

   return (
      <div ref={refBtn}>
         <div className={css.sidebar__user} onClick={() => setOpenSideBar(!openSidebar)}>
            <div className={css.sidebar__user_left}>
               <UserIcon />
               <div className={css.sidebar__user_info}>
                  <p>Logged in as:</p>
                  <h4>{user ? `${user?.first_name ?? ""} ${user?.last_name ?? ""}`.trim() : ""}</h4>
               </div>
            </div>

            <HamburgerIcon />
         </div>
      </div>
   )
}
const mapStateToProps = state => ({
    user: state.user.data
});

export default connect(mapStateToProps, {getUserData})(UserBtn);
