import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button, Dropdown, Form, Spinner } from "react-bootstrap"
import { getVideos, updateMovie } from "../../../redux/actions/listingActions"
import EditBlock from "../../../components/EditBlock"
import useValidationSchema from "../../../customHooks/useValidation"
import { usePrevious } from "../../../customHooks/usePrevious"
import { movieSettingsSchema, handleValidate } from "../../../helpers/validation"

import css from "../MoviesEdit.module.scss"
import TheHubExperienceCompany from "../../../components/TheHubExperienceCompany"

const blankForm = {
   title: "",
   subject_line: "",
   intro_text: "",
   url: "",
   hub_experience_company: false,
   push_notification: false,
}
const CreateSettings = ({ data, updateMovie, fetchingVideos, videosData, getVideos }) => {
   const [formData, setFormData] = useState({ ...blankForm })
   const [touched, setTouched] = useState(false)
   const [complete, setComplete] = useState(false)
   const [formErrors, setFormErrors] = useState({})
   const prevData = usePrevious({
      title: data.title || "",
      subject_line: data.subject_line || "",
      intro_text: data.intro_text || "",
      url: data.url || "",
      hub_experience_company: data?.hub_experience_company || false,
      push_notification: data?.push_notification ?? false,
   })
   const validate = useValidationSchema(movieSettingsSchema, formData)

   useEffect(() => {
      getVideos()
   }, [getVideos])

   useEffect(() => {
      const newData = {
         title: data.title || "",
         subject_line: data.subject_line || "",
         intro_text: data.intro_text || "",
         url: data.url || "",
         hub_experience_company: data?.hub_experience_company || false,
         push_notification: data?.push_notification || false,
      }

      if (JSON.stringify(prevData) !== JSON.stringify(newData)) {
         setFormData(newData)
         setTouched(false)
      }
      if (data.title && data.subject_line && data.intro_text && data.url) {
         setComplete(true)
      } else {
         setComplete(false)
      }
   }, [data, prevData])

   const handleChange = (e) => {
      const { name } = e.target
      const value = e.target.type === "checkbox" ? e.target.checked : e.target.value
      setFormData({
         ...formData,
         [name]: value,
      })
      setTouched(true)
   }
   const handleChangeCheckbox = (e) => {
      const { name, checked } = e.target
      console.log(checked)

      setFormData({
         ...formData,
         [name]: checked,
      })
      setTouched(true)
   }
   const handleSave = async () => {
      const isValid = await handleValidate(setFormErrors, validate)

      if (isValid) {
         await updateMovie({ ...formData, id: data?.id })
         setTouched(false)
      }
   }

   const handleDiscard = () => {
      if (data) {
         setFormData({
            title: data.title || "",
            subject_line: data.subject_line || "",
            intro_text: data.intro_text || "",
            url: data.url || "",
            hub_experience_company: data?.hub_experience_company || false,
            push_notification: data?.push_notification || false,
         })
      } else {
         setFormData({ ...blankForm })
      }
      setFormErrors({})
      setTouched(false)
   }

   const renderList = () => {
      const list = videosData.filter(({ name }) => formData.url && name.includes(formData.url))
      const item = videosData.find((i) => i.link === formData.url)

      if (item) {
         return null
      } else if (list.length > 0) {
         return (
            <Dropdown.Menu show={true} className={css.videoFetching__dropdown}>
               {list.slice(0, 10).map((v) => {
                  return (
                     <Dropdown.Item
                        onClick={() => handleChange({ target: { name: "url", value: v.link } })}
                        key={v.link}
                     >
                        {v.name}
                     </Dropdown.Item>
                  )
               })}
            </Dropdown.Menu>
         )
      } else if (list.length === 0 && formData.url !== "") {
         return (
            <Dropdown.Menu show={true} className={css.videoFetching__dropdown}>
               <Dropdown.Item>No results</Dropdown.Item>
            </Dropdown.Menu>
         )
      }
      return null
   }

   return (
      <EditBlock title="Settings" titleNum="2" complete={complete}>
         <Form className={css.settings__form}>
            <Form.Group className="mb-4">
               <Form.Label>Internal title</Form.Label>
               <Form.Control
                  type="text"
                  placeholder="Enter internal title"
                  onChange={handleChange}
                  value={formData.title || ""}
                  name="title"
                  isInvalid={!!formErrors.title}
               />
               <Form.Control.Feedback type="invalid">{formErrors.title}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Headline</Form.Label>
               <Form.Control
                  type="text"
                  placeholder="Enter your headline"
                  onChange={handleChange}
                  value={formData.subject_line || ""}
                  name="subject_line"
                  isInvalid={!!formErrors.subject_line}
               />
               <Form.Control.Feedback type="invalid">{formErrors.subject_line}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Information text</Form.Label>
               <Form.Control
                  type="text"
                  placeholder="Enter your information text"
                  onChange={handleChange}
                  value={formData.intro_text || ""}
                  name="intro_text"
                  isInvalid={!!formErrors.intro_text}
               />
               <Form.Control.Feedback type="invalid">{formErrors.intro_text}</Form.Control.Feedback>
            </Form.Group>
            <div className={css.videosRow}>
               <Form.Group className="mb-4">
                  <Form.Label>Video URL</Form.Label>
                  <Form.Control
                     type="text"
                     autoComplete="off"
                     placeholder="Enter your video URL"
                     onChange={handleChange}
                     value={formData.url || ""}
                     name="url"
                     isInvalid={!!formErrors.url}
                  />
                  <Form.Control.Feedback type="invalid">{formErrors.url}</Form.Control.Feedback>
               </Form.Group>
               {fetchingVideos ? (
                  <div className={css.videoFetching}>
                     <Spinner animation="border" variant="primary" className={css.videoFetching__spinner} />
                  </div>
               ) : videosData ? (
                  renderList()
               ) : null}
            </div>
            <Form.Group className="mb-4">
               <Form.Check
                  type="checkbox"
                  label="Push notification"
                  bsPrefix="form-check-p"
                  onChange={handleChangeCheckbox}
                  name="push_notification"
                  checked={formData.push_notification}
               />
            </Form.Group>
            <TheHubExperienceCompany
               hub_experience_company={formData.hub_experience_company}
               status={data.status}
               handleChange={handleChange}
            />
            <div className={css.settings__btns}>
               {touched ? (
                  <Button variant="outline-primary" onClick={handleDiscard}>
                     Discard
                  </Button>
               ) : null}
               <Button disabled={!touched} onClick={handleSave}>
                  {touched ? "Save" : "Saved"}
               </Button>
            </div>
         </Form>
      </EditBlock>
   )
}

const mapStateToProps = (state) => ({
   fetchingVideos: state.videos.fetching,
   videosData: state.videos.data,
})

export default connect(mapStateToProps, { updateMovie, getVideos })(CreateSettings)
