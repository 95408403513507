export const SearchIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
         fill="#000"
         fillRule="evenodd"
         d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
         clipRule="evenodd"
      ></path>
   </svg>
)

export const ArrowDownIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" fill="none" viewBox="0 0 9 10">
      <path
         fill="#C2C2C2"
         fillRule="evenodd"
         d="M7.775 5.235a.8.8 0 010 1.13l-3.2 3.2a.8.8 0 01-1.13 0l-3.2-3.2a.8.8 0 111.13-1.13L3.21 7.069V1a.8.8 0 011.6 0V7.07l1.834-1.834a.8.8 0 011.131 0z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const ArrowUpIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" fill="none" viewBox="0 0 9 10">
      <path
         fill="#C2C2C2"
         fillRule="evenodd"
         d="M.244 4.582A.8.8 0 01.242 3.45L3.435.244A.8.8 0 014.566.24l3.207 3.194a.8.8 0 11-1.129 1.133l-1.838-1.83.013 6.068a.8.8 0 11-1.6.004L3.206 2.74 1.376 4.58a.8.8 0 01-1.132.003z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const ArrowLeftIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" fill="none" viewBox="0 0 14 12">
      <path
         fill="#CDCDCD"
         fillRule="evenodd"
         d="M6.766 11.366a.8.8 0 01-1.132 0l-4.8-4.8a.8.8 0 010-1.131l4.8-4.8a.8.8 0 011.132 1.131L3.33 5.2H12.6a.8.8 0 010 1.6H3.33l3.435 3.435a.8.8 0 010 1.131z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const ArrowRightIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" fill="none" viewBox="0 0 14 12">
      <path
         fill="#CDCDCD"
         fillRule="evenodd"
         d="M7.234.634a.8.8 0 011.132 0l4.8 4.8a.8.8 0 010 1.132l-4.8 4.8a.8.8 0 01-1.132-1.132L10.67 6.8H1.4a.8.8 0 110-1.6h9.269L7.234 1.766a.8.8 0 010-1.132z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const GoBackIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="none" viewBox="0 0 10 16">
      <path
         fill="#0D2033"
         fillRule="evenodd"
         d="M9.331.468a1.6 1.6 0 010 2.263L4.062 8l5.269 5.268a1.6 1.6 0 01-2.262 2.263l-6.4-6.4a1.6 1.6 0 010-2.263l6.4-6.4a1.6 1.6 0 012.262 0z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const PlusIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="69" height="68" fill="none" viewBox="0 0 69 68">
      <path
         fill="#A2AAB7"
         fillRule="evenodd"
         d="M38 0h-7v30.5H.5v7H31V68h7V37.5h30.5v-7H38V0z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const CreateIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path fill="#fff" d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
      <path
         fill="#fff"
         fillRule="evenodd"
         d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 012 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const UploadIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" fill="none" viewBox="0 0 66 66">
      <path
         fill="#B5B5B5"
         d="M18.15 42.9a11.55 11.55 0 01-1.218-23.034 13.201 13.201 0 1125.585-6.524A14.85 14.85 0 1144.55 42.9H36.3V31.063l4.267 4.267a3.3 3.3 0 004.666-4.666l-9.9-9.9a3.3 3.3 0 00-4.666 0l-9.9 9.9a3.3 3.3 0 004.666 4.666l4.267-4.264V42.9H18.15z"
      ></path>
      <path fill="#B5B5B5" d="M29.7 42.9h6.6v16.5a3.3 3.3 0 01-6.6 0V42.9z"></path>
   </svg>
)
export const CloseIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="none" viewBox="0 0 8 8">
      <path
         fill="#fff"
         fillRule="evenodd"
         d="M.195.203a.666.666 0 01.942 0l2.859 2.86L6.855.202a.666.666 0 11.942.942l-2.86 2.86 2.86 2.858a.666.666 0 01-.942.942l-2.86-2.86-2.858 2.86a.666.666 0 01-.942-.942l2.86-2.859-2.86-2.859a.666.666 0 010-.942z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const InviteCheckIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="none" viewBox="0 0 64 64">
      <path
         fill="#1ABC9C"
         fillRule="evenodd"
         d="M32 64a32 32 0 100-64 32 32 0 000 64zm14.828-37.172a4 4 0 00-5.656-5.656L28 34.344l-5.172-5.172a4 4 0 00-5.656 5.656l8 8a4 4 0 005.656 0l16-16z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const DeleteIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
         fill="#fff"
         fillRule="evenodd"
         d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 002 0V8a1 1 0 00-1-1z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const AnalyticsViewIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" fill="none" viewBox="0 0 17 15">
      <path
         fill="#000"
         d="M0 8.571c0-.284.112-.556.311-.757.2-.201.47-.314.752-.314h2.125c.281 0 .552.113.75.314.2.2.312.473.312.757v5.358c0 .284-.112.556-.311.757-.2.201-.47.314-.752.314H1.063c-.282 0-.553-.113-.752-.314-.2-.2-.311-.473-.311-.757V8.57zm6.375-4.285c0-.284.112-.557.311-.758.2-.2.47-.314.752-.314h2.125c.281 0 .551.113.75.314.2.201.312.474.312.758v9.643c0 .284-.112.556-.311.757-.2.201-.47.314-.752.314H7.438c-.282 0-.553-.113-.752-.314-.2-.2-.311-.473-.311-.757V4.286zM12.75 1.07c0-.284.112-.556.311-.757.2-.201.47-.314.752-.314h2.124c.282 0 .553.113.752.314.2.2.311.473.311.757V13.93c0 .284-.112.556-.311.757-.2.201-.47.314-.752.314h-2.124c-.282 0-.553-.113-.752-.314-.2-.2-.311-.473-.311-.757V1.07z"
      ></path>
   </svg>
)
export const HamburgerMenuIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none" viewBox="0 0 19 19">
      <path
         fill="#0D2033"
         fillRule="evenodd"
         d="M2.85 4.75a.95.95 0 01.95-.95h11.4a.95.95 0 110 1.9H3.8a.95.95 0 01-.95-.95zm0 4.75a.95.95 0 01.95-.95h11.4a.95.95 0 110 1.9H3.8a.95.95 0 01-.95-.95zm0 4.75a.95.95 0 01.95-.95h11.4a.95.95 0 110 1.9H3.8a.95.95 0 01-.95-.95z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const DuplicateIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path fill="#057EEE" d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z"></path>
      <path fill="#057EEE" d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z"></path>
   </svg>
)
export const DeleteBlueIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
         fill="#057EEE"
         fillRule="evenodd"
         d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 002 0V8a1 1 0 00-1-1z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const SelectIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none" viewBox="0 0 11 7">
      <path
         fill="#057EEE"
         fillRule="evenodd"
         d="M.293 1.293a1 1 0 011.414 0L5 4.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const ErrorIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="46" height="42" fill="none" viewBox="0 0 46 42">
      <path
         fill="#B30949"
         fillRule="evenodd"
         d="M17.771 3.297c2.295-4.08 8.166-4.08 10.458 0l16.74 29.76c2.25 4.002-.639 8.94-5.226 8.94H6.26c-4.59 0-7.479-4.938-5.229-8.94l16.74-29.76zM26.001 33a3 3 0 11-6 0 3 3 0 016 0zM23 9a3 3 0 00-3 3v9a3 3 0 106 0v-9a3 3 0 00-3-3z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const ResetSearchIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
         fill="#D1D1D1"
         fillRule="evenodd"
         d="M8 16A8 8 0 108-.001 8 8 0 008 16zM6.707 5.293a1 1 0 00-1.414 1.414L6.586 8 5.293 9.293a1 1 0 101.414 1.414L8 9.414l1.293 1.293a1 1 0 001.414-1.414L9.414 8l1.293-1.293a1 1 0 00-1.414-1.414L8 6.586 6.707 5.293z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const SaveSuccessIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
         fill="#fff"
         fillRule="evenodd"
         d="M8 16A8 8 0 108-.001 8 8 0 008 16zm3.707-9.293a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const SaveErrorIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
         fill="#FFFCFC"
         fillRule="evenodd"
         d="M8 16A8 8 0 108-.001 8 8 0 008 16zM6.707 5.293a1 1 0 00-1.414 1.414L6.586 8 5.293 9.293a1 1 0 101.414 1.414L8 9.414l1.293 1.293a1 1 0 001.414-1.414L9.414 8l1.293-1.293a1 1 0 00-1.414-1.414L8 6.586 6.707 5.293z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const CompleteIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
         fill="#1ABC9C"
         fillRule="evenodd"
         d="M10 18a8 8 0 100-16.001A8 8 0 0010 18zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const ScheduleIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
         fill="#fff"
         fillRule="evenodd"
         d="M8 16A8 8 0 108-.001 8 8 0 008 16zM9 4a1 1 0 00-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 001.415-1.415L9 7.586V4z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const UpIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14">
      <path
         fill="#06D6A0"
         d="M0 8a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H1a1 1 0 01-1-1V8zm6-4a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H7a1 1 0 01-1-1V4zm6-3a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V1z"
      ></path>
   </svg>
)
export const DownIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14">
      <path
         fill="#EF476F"
         d="M16 8a1 1 0 00-1-1h-2a1 1 0 00-1 1v5a1 1 0 001 1h2a1 1 0 001-1V8zm-6-4a1 1 0 00-1-1H7a1 1 0 00-1 1v9a1 1 0 001 1h2a1 1 0 001-1V4zM4 1a1 1 0 00-1-1H1a1 1 0 00-1 1v12a1 1 0 001 1h2a1 1 0 001-1V1z"
      ></path>
   </svg>
)
