import React, { useEffect } from "react"
import { connect } from "react-redux"

import VehiclesList from "../../../components/TableLists/VehiclesList"
import UserInfoBlock from "../../../components/UserInfoBlock"
import { getVehicles } from "../../../redux/actions/listingActions"

const dataTitles = [
   {
      name: "Registration no.",
      key: "registration_number",
   },
   {
      name: "VIN/Chassis serial no.",
      key: "vin",
   },
   {
      name: "Alias",
      key: "alias",
   },

   {
      name: "Equipment type",
      key: "equipmentType",
   },
   {
      name: "Segment",
      key: "segment",
   },
]

const AssociatedVehicles = ({ getVehicles, vehicles, id }) => {
   useEffect(() => {
      getVehicles(null, null, id)
   }, [getVehicles, id])

   const getDataByQuery = (sort, page = 1) => {
      getVehicles(sort, page, id)
   }

   return (
      <UserInfoBlock title="Associated Vehicles">
         <VehiclesList data={vehicles} getDataByQuery={getDataByQuery} dataTitles={dataTitles} />
      </UserInfoBlock>
   )
}
const mapStateToProps = (state) => {
   return {
      vehicles: state.vehicles.data,
      fetch: state.vehicles.fetching,
      error: state.vehicles.error,
   }
}
export default connect(mapStateToProps, { getVehicles })(AssociatedVehicles)
