const initialState = {
   data: {},
}

export default function notification(params = "") {
   return (state = initialState, action = {}) => {
      switch (action.type) {
         case `notifications/${params}/ADD`:
            return {
               ...state,
               data: action.payload,
            }
         case `notifications/${params}/REMOVE`:
            return {
               ...state,
               data: {},
            }
         default:
            return state
      }
   }
}
