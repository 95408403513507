import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import EditBlock from "../../../components/EditBlock"
import CodesForm from "./CodesForm"
import StatisticsView from "./StatisticsView"
import { setMovieFPC } from "../../../redux/actions/listingActions"

const FPCCodes = ({ data, setMovieFPC }) => {
   const [statisticsView, setStatisticsView] = useState(false)

   const handleSave = async (formData) => {
      await setMovieFPC(data.id, formData)

      setStatisticsView(true)
   }

   useEffect(() => {
      data.fpc_codes ? setStatisticsView(true) : setStatisticsView(false)
   }, [data.fpc_codes])

   return (
      <EditBlock title="FPC Codes" titleNum="3" complete={Boolean(data?.fpc_codes)}>
         {statisticsView ? (
            <StatisticsView
                status={data?.status}
                owners={data?.recipients_owner}
                recipients={data?.recipients_count}
                setStatisticsView={setStatisticsView} />
         ) : (
            <CodesForm value={data?.fpc_codes} handleSave={handleSave} status={data?.status} setStatisticsView={setStatisticsView}/>
         )}
      </EditBlock>
   )
}
export default connect(null, { setMovieFPC })(FPCCodes)
