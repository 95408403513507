import React, { useState, useEffect } from "react"
import moment from "moment"
import { Form, Button } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { CustomInputDate, CustomInputTime } from "./CustomInput"

import "react-datepicker/dist/react-datepicker.css"
import css from "./Accordion.module.scss"

const Calendar = ({ handleSaveScheduling, schedule }) => {
   const [touched, setTouched] = useState(false)
   const [data, setData] = useState({
      startDatePublish: null,
      startTimePublish: null,
      publish: false,
      startDateUnpublish: null,
      startTimeUnpublish: null,
      unpublish: false,
      test: null,
   })

   const [formErrors, setFormErrors] = useState({ unpublish: "", publish: "", time: "" })

   useEffect(() => {
      if (schedule) {
         setData({
            startDatePublish: schedule.publish_at ? new Date(schedule.publish_at) : null,
            startTimePublish: schedule.publish_at ? new Date(schedule.publish_at) : null,
            publish: schedule.publish,
            startDateUnpublish: schedule.unpublish_at ? new Date(schedule.unpublish_at) : null,
            startTimeUnpublish: schedule.unpublish_at ? new Date(schedule.unpublish_at) : null,
            unpublish: schedule.unpublish,
         })
      } else {
         setData({
            startDatePublish: null,
            startTimePublish: null,
            publish: false,
            startDateUnpublish: null,
            startTimeUnpublish: null,
            unpublish: false,
         })
      }
   }, [schedule])

   const handleChangeCheckboxPublish = (e) => {
      const { checked } = e.target

      if (checked) {
         setData({
            ...data,
            publish: checked,
         })
      } else {
         setData((prev) => {
            return {
               ...prev,
               publish: checked,
               startDatePublish: null,
               startTimePublish: null,
            }
         })
         setFormErrors((prev) => ({ ...prev, publish: null, time: null }))
      }

      setTouched(true)
   }

   const handleChangeCheckboxUnpublish = (e) => {
      const { checked } = e.target

      if (checked) {
         setData({
            ...data,
            unpublish: checked,
         })
      } else {
         setData((prev) => {
            return {
               ...prev,
               unpublish: checked,
               startDateUnpublish: null,
               startTimeUnpublish: null,
            }
         })
         setFormErrors((prev) => ({ ...prev, publish: null, time: null }))
      }

      setTouched(true)
   }

   useEffect(() => {
      setTouched(true)
   }, [data])

   const validateForm = (fields) => {
      let errors = {}
      let formIsValid = true

      if (fields["publish"] && !fields["startDatePublish"]) {
         formIsValid = false
         errors["publish"] = "The field cannot be empty"
      }
      if (fields["publish"] && !fields["startTimePublish"]) {
         formIsValid = false
         errors["publish"] = "The field cannot be empty"
      }
      if (fields["unpublish"] && !fields["startDateUnpublish"]) {
         formIsValid = false
         errors["unpublish"] = "The field cannot be empty"
      }
      if (fields["unpublish"] && !fields["startTimeUnpublish"]) {
         formIsValid = false
         errors["unpublish"] = "The field cannot be empty"
      }

      if (
         fields["publish"] &&
         fields["unpublish"] &&
         fields["startDatePublish"] &&
         fields["startDateUnpublish"] &&
         fields["startTimePublish"] &&
         fields["startTimeUnpublish"]
      ) {
         const publish_at_year = moment(fields["startDatePublish"]).format("YYYY-MM-DD")
         const publish_at_time = moment(fields["startTimePublish"]).format("HH:mm:ss")
         const unpublish_at_year = moment(fields["startDateUnpublish"]).format("YYYY-MM-DD")
         const unpublish_at_time = moment(fields["startTimeUnpublish"]).format("HH:mm:ss")
         const publish_at = new Date(`${publish_at_year} ${publish_at_time}`)
         const unpublish_at = new Date(`${unpublish_at_year} ${unpublish_at_time}`)

         if (unpublish_at < publish_at) {
            formIsValid = false
            errors["time"] = "The Unpublish date cannot be earlier than Publish date "
         }
      }

      return {
         formIsValid,
         errors,
      }
   }

   const handleSave = () => {
      const { formIsValid, errors } = validateForm(data)
      setFormErrors(errors)
      if (formIsValid) {
         const publish_at_year = moment(data.startDatePublish).format("YYYY-MM-DD")
         const publish_at_time = moment(data.startTimePublish).format("HH:mm:ss")
         const unpublish_at_year = moment(data.startDateUnpublish).format("YYYY-MM-DD")
         const unpublish_at_time = moment(data.startTimeUnpublish).format("HH:mm:ss")
         const dataSend = {
            publish: data.publish,
            publish_at: data.startDatePublish && data.startTimePublish ? `${publish_at_year} ${publish_at_time}` : null,
            unpublish: data.unpublish,
            unpublish_at:
               data.startDateUnpublish && data.startTimeUnpublish ? `${unpublish_at_year} ${unpublish_at_time}` : null,
         }

         handleSaveScheduling(dataSend)
         setTouched(false)
      }
   }

   return (
      <div className={css.formCalendar}>
         <div className={css.publishCalendar}>
            <div className={css.publishCalendar__inner}>
               <Form.Check
                  type="checkbox"
                  label="Scheduling - Publishing"
                  bsPrefix="form-check-p"
                  onChange={handleChangeCheckboxPublish}
                  name="publish"
                  checked={data.publish}
               />
               <div className={css.calendar}>
                  <div className={css.calendar__date}>
                     <h3>Date</h3>

                     <DatePicker
                        selected={data.startDatePublish}
                        onChange={(date) => setData({ ...data, startDatePublish: date })}
                        minDate={new Date()}
                        className={css.calendar__input}
                        disabled={!data.publish}
                        customInput={<CustomInputDate placeholderText="Select date" disabled={!data.publish} />}
                     />
                  </div>
                  <div className={css.calendar__time}>
                     <h3>Time</h3>
                     <DatePicker
                        selected={data.startTimePublish}
                        onChange={(date) => setData({ ...data, startTimePublish: date })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className={css.calendar__input}
                        disabled={!data.publish}
                        customInput={<CustomInputTime placeholderText="Select time" disabled={!data.publish} />}
                     />
                  </div>
               </div>
               {formErrors.publish && <div className={css.publishCalendar__inner_error}>{formErrors.publish}</div>}
            </div>
            <div className={css.publishCalendar__inner}>
               <Form.Check
                  type="checkbox"
                  label="Scheduling - Unpublishing"
                  bsPrefix="form-check-p"
                  onChange={handleChangeCheckboxUnpublish}
                  name="unpublish"
                  checked={data.unpublish}
               />

               <div className={css.calendar}>
                  <div className={css.calendar__date}>
                     <h3>Date</h3>

                     <DatePicker
                        selected={data.startDateUnpublish}
                        onChange={(date) => setData({ ...data, startDateUnpublish: date })}
                        minDate={new Date()}
                        className={css.calendar__input}
                        disabled={!data.unpublish}
                        customInput={<CustomInputDate placeholderText="Select date" disabled={!data.unpublish} />}
                     />
                  </div>
                  <div className={css.calendar__time}>
                     <h3>Time</h3>
                     <DatePicker
                        selected={data.startTimeUnpublish}
                        onChange={(date) => setData({ ...data, startTimeUnpublish: date })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className={css.calendar__input}
                        disabled={!data.unpublish}
                        customInput={<CustomInputTime placeholderText="Select time" disabled={!data.unpublish} />}
                     />
                  </div>
               </div>
               {formErrors.unpublish && <div className={css.publishCalendar__inner_error}>{formErrors.unpublish}</div>}
            </div>
         </div>
         {formErrors.time && <div className={css.publishCalendar__error}>{formErrors.time}</div>}
         <div className={css.formCalendar__btns}>
            <Button disabled={!touched} onClick={handleSave}>
               {touched ? "Save" : "Saved"}
            </Button>
         </div>
      </div>
   )
}
export default Calendar
