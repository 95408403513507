import axios from "../../utils/axios"
import {
   CAMPAIGNS_REQUEST,
   CAMPAIGNS_SUCCESS,
   CAMPAIGNS_FAILURE,
   CAMPAIGN_CREATE_REQUEST,
   CAMPAIGN_CREATE_SUCCESS,
   CAMPAIGN_CREATE_FAILURE,
   CAMPAIGN_BY_ID_REQUEST,
   CAMPAIGN_BY_ID_SUCCESS,
   CAMPAIGN_BY_ID_FAILURE,
   CAMPAIGN_UPDATE_SUCCESS,
   CAMPAIGN_UPDATE_FAILURE,
   DELETE_CAMPAIGN_SUCCESS,
   DELETE_CAMPAIGN_FAILURE,
   CAMPAIGN_CREATE_CLEAR,
   DUPLICATE_CAMPAIGN_REQUEST,
   DUPLICATE_CAMPAIGN_SUCCESS,
   DUPLICATE_CAMPAIGN_FAILURE,
   SCHEDULING_REQUEST,
   SCHEDULING_SUCCESS,
   SCHEDULING_FAILURE,
} from "./actionTypes"

import { callFailure, callSuccess, callRequest } from "./functions"
import history from "../../history"
//import { addNotification } from "./otherActions"

export const getCampaigns =
   (sort = null, page = null, searchQuery = null, companyId = null) =>
   (dispatch) => {
      const sortUrl = sort ? `sort=${sort.key}&sort_type=${sort.direction}` : `sort=title&sort_type=asc`
      const search = searchQuery ? `&search=${searchQuery}` : ""
      const pageCurrent = page ? `&page=${page}` : `&page=1`
      const company = companyId ? `&company_id=${companyId}` : ""
      if (!sort && !page) {
         dispatch(callRequest(CAMPAIGNS_REQUEST))
      }

      axios
         .get(`/campaign?${sortUrl}${pageCurrent}${search}${company}&per_page=9`)
         .then((res) => {
            return dispatch(callSuccess(CAMPAIGNS_SUCCESS, res.data.data))
         })
         .catch((error) => {
            return dispatch(callFailure(CAMPAIGNS_FAILURE, error))
         })
   }

export const createCampaign = (data) => (dispatch) => {
   dispatch(callRequest(CAMPAIGN_CREATE_REQUEST))

   axios
      .post(`/campaign/store`, data)
      .then((res) => {
         return dispatch(callSuccess(CAMPAIGN_CREATE_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(CAMPAIGN_CREATE_FAILURE, error))
      })
}
export const clearCampaign = () => ({
   type: CAMPAIGN_CREATE_CLEAR,
})

export const getCampaignById = (id) => (dispatch) => {
   dispatch(callRequest(CAMPAIGN_BY_ID_REQUEST))

   axios
      .get(`/campaign/${id}`)
      .then((res) => {
         return dispatch(callSuccess(CAMPAIGN_BY_ID_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(CAMPAIGN_BY_ID_FAILURE, error))
      })
}

export const updateCampaign = (data) => (dispatch) => {
   axios
      .put(`/campaign/update`, data)
      .then(({ data }) => {
         return dispatch(callSuccess(CAMPAIGN_UPDATE_SUCCESS, data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(CAMPAIGN_UPDATE_FAILURE, error))
      })
}

export const deleteCampaign = (id) => (dispatch) => {
   axios
      .delete(`/campaign/${id}`)
      .then(() => {
         dispatch(callSuccess(DELETE_CAMPAIGN_SUCCESS))
         history.push("/campaigns")
         return
      })
      .catch((error) => {
         return dispatch(callFailure(DELETE_CAMPAIGN_FAILURE, error))
      })
}

export const duplicateCampaign = (id) => (dispatch) => {
   dispatch(callRequest(DUPLICATE_CAMPAIGN_REQUEST))

   axios
      .get(`/campaign/duplicate/${id}`)
      .then(
         ({
            data: {
               data: { id },
            },
         }) => {
            dispatch(callSuccess(DUPLICATE_CAMPAIGN_SUCCESS))
            history.push(`/campaigns/edit/${id}`)
            return
         }
      )
      .catch((error) => {
         return dispatch(callFailure(DUPLICATE_CAMPAIGN_FAILURE, error))
      })
}

export const setCampaignStatus = (data) => (dispatch) => {
   dispatch(callRequest(CAMPAIGN_BY_ID_REQUEST))
   axios
      .patch("/campaign/status", data)
      .then(({ data }) => {
         return dispatch(callSuccess(CAMPAIGN_UPDATE_SUCCESS, data.data))
      })
      .catch((err) => {
         return dispatch(callFailure(CAMPAIGN_UPDATE_FAILURE, err))
      })
}
export const schedulingCampaign = (data) => (dispatch) => {
   dispatch(callRequest(SCHEDULING_REQUEST))

   axios
      .post(`/scheduling`, data)
      .then((res) => {
         return dispatch(callSuccess(SCHEDULING_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(SCHEDULING_FAILURE, error))
      })
}
