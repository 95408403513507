import React from "react"
import { CompleteIcon } from "../../assets/icons"
import css from "./EditBlock.module.scss"
import clx from "classnames";

const EditBlock = ({ title, titleNum = false, complete = false, justifyStart=false, children }) => {
   return (
      <div className={clx(css.editBlock, justifyStart ? css.editBlock__flex_start : undefined)}>
         <h3>
            {titleNum && <span>{titleNum}</span>} {title} {complete && <CompleteIcon />}
         </h3>
         {children}
      </div>
   )
}
export default EditBlock
