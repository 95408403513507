import React from "react"
import {useLocation} from "react-router-dom";
import {Form} from "react-bootstrap";


const TheHubExperienceCompany = ({ hub_experience_company, status, handleChange }) => {
    const query = new URLSearchParams(useLocation().search)
    return (query?.get("update") === "200" && status !== 1 && (
        <Form.Group className="mb-4">
            <Form.Check
                type="checkbox"
                label="The Hub Experience Company"
                bsPrefix="form-check-p"
                onChange={handleChange}
                name="hub_experience_company"
                //value={formData.hub_experience_company}
                checked={hub_experience_company}
            />
        </Form.Group>
    ));
}
export default TheHubExperienceCompany
