import {useEffect, useState} from "react"
import { Form } from "react-bootstrap"
import Modal from "../../components/Modal"
import useValidationSchema from "../../customHooks/useValidation"
import { createCampaignSchema, handleValidate } from "../../helpers/validation"
import "./Movies.module.scss"
import {connect} from "react-redux";
import {clearMovie, createMovie} from "../../redux/actions/listingActions";
import history from "../../history";

const CreateMovieModal = ({ onHide, show, data, fetching, error, createMovie, clearMovie }) => {
   const [formData, setFormData] = useState({
      title: "",
   })

   const [formErrors, setFormErrors] = useState({
      title: "",
   })

   const validate = useValidationSchema(createCampaignSchema, formData)

   const handleChange = (e) => {
      const { name, value } = e.target
      setFormData({
         ...formData,
         [name]: value,
      })
   }

   useEffect(() => {
      if (data?.id && !fetching && !error) {
         clearMovie();
         history.push(`/movies/edit/${data.id}`);
      }
   }, [data, fetching, error, clearMovie]);

   const handleCreate = async () => {
      const isValidate = await handleValidate(setFormErrors, validate)

      if (isValidate) {
         await createMovie({
            title: formData.title
         });
      }
   }
   const handleCancel = () => {
      onHide()
      setFormData({
         title: "",
      })
   }

   return (
      <Modal
         title="Create Movie Entry"
         subtitle="Enter the internal title of your movie entry."
         show={show}
         onHide={handleCancel}
         handleSubmit={handleCreate}
         btnCreateText="Create movie"
      >
         <Form.Group className="mb-3">
            <Form.Label>Internal title</Form.Label>
            <Form.Control
               type="text"
               placeholder="Enter internal title"
               onChange={handleChange}
               value={formData.title}
               name="title"
               isInvalid={!!formErrors.title}
            />
            <Form.Control.Feedback type="invalid">{formErrors.title}</Form.Control.Feedback>
         </Form.Group>
      </Modal>
   )
}
const mapStateToProps = state => ({
   data: state.createMovie.data,
   fetching: state.createMovie.fetching,
   error: state.createMovie.error,
});

export default connect(mapStateToProps, { createMovie, clearMovie })(CreateMovieModal)
