import React from "react"
import { Button } from "react-bootstrap"
import { InviteCheckIcon, ErrorIcon } from "../../assets/icons"
import history from "../../history"
import css from "./OnBoarding.module.scss"

const Success = ({ adminInviteError }) => {
   return (
      <div className={css.onBoarding__info}>
         {adminInviteError ? (
            <>
               <ErrorIcon />
               <h3>Something went wrong</h3>
            </>
         ) : (
            <>
               <InviteCheckIcon />
               <h3>User Created</h3>
               <p>Click the button below to return to the login screen to sign in to the admin portal.</p>
            </>
         )}
         <Button onClick={() => history.push("/login")}>To Login Screen</Button>
      </div>
   )
}
export default Success
