import React, { useState } from "react"

import { Button, Form } from "react-bootstrap"
import useValidationSchema from "../../customHooks/useValidation"
import { createPasswordSchema, handleValidate } from "../../helpers/validation"
import css from "./OnBoarding.module.scss"

const CreatePassword = ({ nextStep, prevStep, handleSubmit, values }) => {
   const [formData, setFormData] = useState({
      password: "",
      passwordRepeat: "",
   })
   const [formErrors, setFormErrors] = useState({})

   const validate = useValidationSchema(createPasswordSchema, formData)

   const handleChange = (e) => {
      const { name, value } = e.target
      setFormData({
         ...formData,
         [name]: value,
      })
   }

   const handleSave = async () => {
      const isValid = await handleValidate(setFormErrors, validate)

      if (isValid) {
         const { first_name, last_name, email, token, company, country, phone_number } = values

         handleSubmit({
            first_name,
            last_name,
            email,
            company,
            country,
            token,
            phone_number,
            password: formData.password,
         })

         nextStep()
      }
   }
   const disableBtn = () => {
      return Object.values(formData).includes("")
   }
   return (
      <>
         <div className={css.onBoarding__header}>
            <h3>Create Password</h3>
            <p>Set your password for future logins to the platform.</p>
         </div>

         <Form>
            <Form.Group className="mb-4">
               <div className={css.userForm__description}>
                  <p>Your new password needs to meet the following requirements:</p>
                  <ul>
                     <li>Minimum of 16 characters</li>
                     <li>At least 1 letter</li>
                     <li>At least 1 number</li>
                     <li>At least 1 symbol</li>
                  </ul>
               </div>
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>New password</Form.Label>

               <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={formData.password}
                  name="password"
                  isInvalid={!!formErrors.password}
               />
               <Form.Control.Feedback type="invalid">{formErrors.password}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Repeat password</Form.Label>

               <Form.Control
                  type="password"
                  placeholder="New password"
                  onChange={handleChange}
                  value={formData.passwordRepeat}
                  name="passwordRepeat"
                  isInvalid={!!formErrors.passwordRepeat}
               />
               <Form.Control.Feedback type="invalid">{formErrors.passwordRepeat}</Form.Control.Feedback>
            </Form.Group>
            <div className={css.userForm__btns}>
               <Button onClick={prevStep} variant="outline-primary">
                  Back
               </Button>
               <Button onClick={handleSave} disabled={disableBtn()}>
                  Save & Submit
               </Button>
            </div>
         </Form>
      </>
   )
}
export default CreatePassword
