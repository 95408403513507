/*global ContentEditor*/
import React, { createRef, useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import { loadContentEditor } from "./contentEditor"
import css from "./EditorC5.module.scss"

const ContentEditorMain = ({
   contentData,
   editorConfig,
   baseRequestUrl,
   themeOptions,
   languageCode,
   requestHeaders,
   onChange,
   onEditorReady,
   onOpenExternalMediaDialog,
   onOpenLinkDialog,
   saveRef,
   onDiscardData,
}) => {
   const editorRef = createRef()
   const [editorLoaded, setEditorLoaded] = useState(false)
   const [editor, setEditor] = useState(null)

   useEffect(() => {
      let timeOutId = null
      if (!editorLoaded) {
         loadContentEditor((loaded) => {
            timeOutId = setTimeout(() => {
               setEditorLoaded(loaded)
            }, 500)
         })
      }
      return () => clearTimeout(timeOutId)
   }, [editorLoaded])

   useEffect(() => {
      if (editorLoaded) {
         //console.log("c5b = ", contentData)
         ContentEditor.prepareInitData(
            {
               languageCode: languageCode,
               baseRequestPath: baseRequestUrl,
               assetPath: "",
               templateOptions: themeOptions,
               contentData: contentData,
               requestHeaders: requestHeaders,
            },
            function (err, init) {
               if (err) {
                  throw err
               }

               try {
                  const editor = new ContentEditor(init, {
                     ...editorConfig,
                     imageResampleTaskScheduler: window.contentEditorImageResampleTaskScheduler,
                     openExternalMediaDialog: onOpenExternalMediaDialog,
                     openLinkDialog: onOpenLinkDialog,
                  })

                  editor.node.style.setProperty("height", "100%")
                  editor.node.style.setProperty("width", `100%`)

                  if (editorRef && editorRef.current) {
                     editorRef.current.appendChild(editor.node)
                     saveRef.current = editor
                  }

                  editor.afterInsert()
                  setEditor(editor)

                  if (onChange) {
                     editor.onChange = onChange
                  }

                  // Handle change of content data from outside
                  editor.replaceContentData = (newContentData) => {
                     // console.log("replace")
                     ContentEditor.prepareInitData(
                        {
                           languageCode: languageCode,
                           baseRequestPath: baseRequestUrl,
                           assetPath: "",
                           templateOptions: themeOptions,
                           contentData: newContentData,
                           requestHeaders: requestHeaders,
                        },
                        function (err, init) {
                           if (err) {
                              throw err
                           }
                           editor.replaceInitData(init)
                        }
                     )
                  }

                  if (onEditorReady) {
                     onEditorReady(editor)
                  }
               } catch {
                  setEditor(null)
               }
            }
         )
      }
      // TODO: Implement destructing of editor, below might be what we need but disabled for now
      return () => {
         if (editor) {
            editor.beforeRemove() // Will just hide not also kill so can be used when toggling ie. settings to keep change history in editor
            editor.destroy() // Kill it completely (when exiting document screen
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [editorLoaded, setEditorLoaded])

   if (!editorLoaded && !editor) {
      return (
         <div className={css.editor__main}>
            <div className={css.editor__main_inner}>
               <Spinner animation="border" variant="primary" className={css.editor__main_spinner} />
            </div>
         </div>
      )
   }

   return <div className={css.editor__main} ref={editorRef} />
}

export default ContentEditorMain
