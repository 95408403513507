import React, { useEffect } from "react"
import { connect } from "react-redux"
import { getMovies } from "../../../redux/actions/listingActions"
import ItemsList from "../../../components/TableLists/ItemsList"

const dataTitles = [
   {
      name: "Title",
      key: "title",
   },
   {
      name: "Subject",
      key: "subject_line",
   },
   {
      name: "Views",
      key: "viewed_count",
   },
   {
      name: "Clicks",
      key: "clicked_count",
   },
]

const TabMovies = ({ id, getMovies, movies, fetch, error }) => {
   const getDataByQuery = (sort, page = 1) => {
      getMovies(sort, page, null, id)
   }

   useEffect(() => {
      getMovies(null, null, null, id)
   }, [getMovies, id])

   return (
      <ItemsList
         urlEdit="/movies/edit/"
         initialSort={"title"}
         data={movies}
         fetch={fetch}
         error={error}
         getDataByQuery={getDataByQuery}
         dataTitles={dataTitles}
      />
   )
}
const mapStateToProps = (state) => ({
   movies: state.movies.data,
   fetch: state.movies.fetching,
   error: state.movies.error,
})

export default connect(mapStateToProps, { getMovies })(TabMovies)
