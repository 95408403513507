import React from "react"
import InviteModal from "../../../components/Modal/InviteModal"
import css from "../Users.module.scss"

const InviteUser = ({ inviteOwnerError, inviteOwnerData, show, handleCloseInvite }) => {
   return (
      <InviteModal show={show} onHide={handleCloseInvite} error={inviteOwnerError}>
         {inviteOwnerError ? (
            <p style={{ textAlign: "center" }}>{inviteOwnerError}</p>
         ) : (
            inviteOwnerData && (
               <div className={css.ownerSelected__item_data}>
                  <p>
                     <span>Company:</span> {inviteOwnerData.name}
                  </p>
                  <p>
                     <span>Owner:</span>{" "}
                     {`${inviteOwnerData.contact_first_name ? inviteOwnerData.contact_first_name : ""} ${
                        inviteOwnerData.contact_last_name ? inviteOwnerData.contact_last_name : ""
                     }`}
                  </p>
               </div>
            )
         )}
      </InviteModal>
   )
}
export default InviteUser
