const initialState = {
   data: null,
   fetching: false,
   error: null,
}

export default function listings(params = "") {
   return (state = initialState, action = {}) => {
      switch (action.type) {
         case `listings/${params}/REQUEST`:
            return { ...state, fetching: true, data: null }

         case `listings/${params}/SUCCESS`:
            return {
               ...state,
               fetching: false,
               error: false,
               data: action.payload,
            }

         case `listings/${params}/FAILURE`:
            return { ...state, fetching: false, error: action.payload }

         case `listings/${params}/CLEAR`:
            return { fetching: false, error: false, data: null }

         case `listings/${params}/UPDATE_REQUEST`:
            return { ...state, fetching: true, error: null }
         case `listings/${params}/UPDATE_SUCCESS`:
            return { ...state, fetching: false, error: null, data: { ...state.data, ...action.payload } }
         case `listings/${params}/UPDATE_ERROR`:
            return { ...state, fetching: false, error: action.payload }

         case `listings/${params}/UPDATE`:
            return { fetching: false, error: false, data: { ...state.data, ...action.payload } }

         default:
            return state
      }
   }
}
