import React, { useState } from "react"
import { Tabs, Tab } from "react-bootstrap"
import TabCampaigns from "./TabCampaigns"
import TabNewsfeed from "./TabNewsfeed"
import TabMovies from "./TabMovies"
import TabSustainability from "./TabSustainability"

import css from "../UserInfo.module.scss"

const AnalyticsTabs = ({ id }) => {
   const [activeTab, setActiveTab] = useState("campaigns")

   const tabsItems = [
      {
         name: "Campaigns",
         key: "campaigns",
         content: <TabCampaigns id={id} />,
      },
      {
         name: "Newsfeed",
         key: "newsfeed",
         content: <TabNewsfeed id={id} />,
      },
      {
         name: "Movies",
         key: "movies",
         content: <TabMovies id={id} />,
      },
      {
         name: "Sustainability",
         key: "sustainability",
         content: <TabSustainability id={id} />,
      },
   ]

   return (
      <div className={css.segmentation__tabs}>
         <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
            {tabsItems.map((item) => {
               return (
                  <Tab key={item.key} eventKey={item.key} title={item.name} tabClassName={css.segmentation__tabs_nav}>
                     {item.content}
                  </Tab>
               )
            })}
         </Tabs>
      </div>
   )
}
export default AnalyticsTabs
