import CustomSelect from "../CustomSelect";
import css from "./Segmentation.module.scss"

const TabSegment = ({ data, onChange, handleSelectAll }) => {
   const checkboxesSegment = [
      {
         type: "Construction",
         items: [
            {
               label: "Mixer truck",
               name: "mixerTruck",
               checked: data.mixerTruck,
            },
            {
               label: "Tipper truck",
               name: "tipperTruck",
               checked: data.tipperTruck,
            },
            {
               label: "Skip loader",
               name: "skipLoader",
               checked: data.skipLoader,
            },
            {
               label: "Hooklift",
               name: "hooklift",
               checked: data.hooklift,
            },
         ],
      },
      {
         type: "Distribution",
         items: [
            {
               label: "Distribution",
               name: "distribution",
               checked: data.distribution,
            },
            {
               label: "Retail temperature controlled transport",
               name: "retailTempTransport",
               checked: data.retailTempTransport,
            },
            {
               label: "General cargo transport",
               name: "genCargoTransport",
               checked: data.genCargoTransport,
            },
         ],
      },
      {
         type: "Timber",
         items: [
            {
               label: "Wood chip transport",
               name: "woodChipTransport",
               checked: data.woodChipTransport,
            },
            {
               label: "Timber transport",
               name: "timberTransport",
               checked: data.timberTransport,
            },
         ],
      },
      {
         type: "Waste handling",
         items: [
            {
               label: "Refuse truck",
               name: "refuseTrack",
               checked: data.refuseTrack,
            },
         ],
      },
      {
         type: "Special vehicles",
         items: [
            {
               label: "Fire truck",
               name: "fireTruck",
               checked: data.fireTruck,
            },
            {
               label: "Heavy haulage",
               name: "heavyHaulage",
               checked: data.heavyHaulage,
            },
            {
               label: "Mining",
               name: "mining",
               checked: data.mining,
            },
         ],
      },
      {
         type: "Long haulage",
         items: [
            {
               label: "Vehicle transport",
               name: "vehicleTransport",
               checked: data.vehicleTransport,
            },
            {
               label: "Retail volume cargo transport",
               name: "retailVolumeCargoTransport",
               checked: data.retailVolumeCargoTransport,
            },
            {
               label: "General cargo transport",
               name: "genCargoTransportLongHaulage",
               checked: data.genCargoTransportLongHaulage,
            },
            {
               label: "Retail temperature controlled transport",
               name: "retailTempTransportLongHaulage",
               checked: data.retailTempTransportLongHaulage,
            },
            {
               label: "Fuel truck",
               name: "fuelTrack",
               checked: data.fuelTrack,
            },
         ],
      },
      {
         type: "Other",
         items: [
            {
               label: "Other",
               name: "other",
               checked: data.other,
            },
         ],
      },
   ]

   return (
      <div className={css.tabInner}>
         <div className={css.tabInner__item}>
            <div className={css.tabInner__item_title}>
               <p>Vehicle type</p>
            </div>
            <div className={css.tabInner__item_list}>
               {checkboxesSegment.map((item) => {
                  return (
                     <CustomSelect
                        title={item.type}
                        data={item.items}
                        onChange={onChange}
                        handleSelectAll={handleSelectAll}
                        key={item.type}
                     />
                  )
               })}
            </div>
         </div>
      </div>
   )
}
export default TabSegment
