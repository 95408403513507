import LoginForm from "../../components/LoginForm"
import css from "./LoginLayout.module.scss"

const LoginLayout = ({ children }) => {
   return (
      <main className={css.main}>
         <LoginForm />

         <div className={css.main__panel}>{children}</div>
      </main>
   )
}

export default LoginLayout
