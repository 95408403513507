import React, { useState } from "react"
import clx from "classnames"
import { Button } from "react-bootstrap"
import { UserInfoIcon, KeyIcon, LogoutIcon } from "../../../assets/icons"
import UserModal from "../Modals"

import css from "../Sidebar.module.scss"

const UserMenu = ({ refSidebar, openSidebar, refModal }) => {
   const [openModal, setOpenModal] = useState(null)
   const openClass = openSidebar ? "sidebar__user_menu_open" : ""
   return (
      <div className={clx(css.sidebar__user_menu, css[openClass])} ref={refSidebar}>
         <div className={css.sidebar__user_menu_inner}>
            <h3>User Options</h3>

            <Button variant="icon" className={css.sidebar__user_btn} onClick={() => setOpenModal("changePassword")}>
               <div>
                  <KeyIcon />
               </div>
               Change password
            </Button>
            <Button variant="icon" className={css.sidebar__user_btn} onClick={() => setOpenModal("editInfo")}>
               <div>
                  <UserInfoIcon />
               </div>
               Edit user profile
            </Button>

            <hr />
            <Button variant="icon" className={css.sidebar__user_btn} onClick={() => setOpenModal("logout")}>
               <div>
                  <LogoutIcon />
               </div>
               Log out
            </Button>
         </div>
         <UserModal show={Boolean(openModal)} type={openModal} onHide={() => setOpenModal(null)} />
      </div>
   )
}
export default UserMenu
