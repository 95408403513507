import React from "react"
import Modal from "../../../components/Modal"

const PublishModal = ({ show, onHide, handleSubmit, status }) => {
   const renderModal = () => {
      if (status === "Draft") {
         return (
            <Modal
               title="Publish Post"
               subtitle="Click the publish button to confirm that you want to publish the post and have it send to the segmented app users."
               show={show}
               onHide={onHide}
               handleSubmit={handleSubmit}
               btnCreateText="Publish post"
               btnVariant="success" />
         )
      } else if (status === "Published") {
         return (
            <Modal
               title="Unpublish Post"
               subtitle="Click the publish button to confirm that you want to unpublish the post and have it removed from the users feeds."
               show={show}
               onHide={onHide}
               handleSubmit={handleSubmit}
               btnCreateText="Unpublish post"
               btnVariant="warning" />
         )
      } else if (status === "Unpublished") {
         return (
            <Modal
               title="Publish Post"
               subtitle="Click the publish button to confirm that you want to publish the post and have it send to the segmented app users."
               show={show}
               onHide={onHide}
               handleSubmit={handleSubmit}
               btnCreateText="Republish post"
               btnVariant="success" />
         )
      }
   }
   return renderModal()
}
export default PublishModal
