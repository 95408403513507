import clx from "classnames"
import { Table, Spinner } from "react-bootstrap"
import history from "../../history"
import css from "./TableLists.module.scss"

const AnalyticsTop = ({ url, data, dataTitles, fetch, error }) => {
   // console.log(data)
   const statusItem = (status) => {
      if (status === 0) {
         return "Draft"
      } else if (status === 1) {
         return "Published"
      } else {
         return "Unpublished"
      }
   }

   const renderTitle = (item, key, index) => {
      switch (key) {
         case "index":
            return <td key={key}>{index + 1}</td>
         case "recipients_count":
            return (
               <td key={key}>
                  <div className={css.tooltip}>
                     {item.recipients_count}
                     <span className={css.tooltip__text}>
                        {renderTooltip(item.recipients_owner, item.recipients_count)}
                     </span>
                  </div>
               </td>
            )

         case "status":
            return (
               <td key={key}>
                  <span className={clx(css.items__status, css[`items__status_${item.status ?? 0}`])} />
                  {statusItem(item.status)}
               </td>
            )

         default:
            return <td key={key}>{item[key]}</td>
      }
   }
   const renderUrl = (url) => {
      let newUrl = ""
      if (url === "campaign") {
         newUrl = "campaigns"
      } else if (url === "movie") {
         newUrl = "movies"
      } else if (url === "survey") {
         newUrl = "surveys"
      } else {
         newUrl = url
      }
      return newUrl
   }

   const renderTooltip = (owners, recipients) => {
      const drivers = recipients > 0 && recipients - owners
      return (
         <p>
            Owners: {owners} Drivers: {drivers ? drivers : 0}
         </p>
      )
   }

   const titles =
      url !== "campaign"
         ? dataTitles.filter(
              (item) => item.key !== "leads_count" && item.key !== "booked_count" && item.key !== "performed_count"
           )
         : dataTitles
   return (
      <div className={css.items__list}>
         <Table size="sm">
            <thead>
               <tr>
                  {titles.map((item) => {
                     return <th key={item.name}>{item.name}</th>
                  })}
               </tr>
            </thead>
            <tbody>
               {fetch ? (
                  <tr>
                     <td>
                        <Spinner animation="border" variant="primary" className={css.inviteFetching__spinner} />
                     </td>
                  </tr>
               ) : error ? (
                  <tr>
                     <td>Something went wrong</td>
                  </tr>
               ) : data && data.length > 0 ? (
                  data.map((item, index) => {
                     return (
                        <tr key={item.id} onClick={() => history.push(`${renderUrl(url)}/edit/${item.id}`)}>
                           {titles &&
                              titles.map((title) => {
                                 return renderTitle(item, title.key, index)
                              })}
                        </tr>
                     )
                  })
               ) : (
                  <tr>
                     <td>No data to show</td>
                  </tr>
               )}
            </tbody>
         </Table>
      </div>
   )
}
export default AnalyticsTop
