import React from "react"
import UserInfo from "./UserInfo"
import Menu from "./Menu"
import css from "./Sidebar.module.scss"

const Sidebar = () => {
   return (
      <aside className={css.sidebar}>
         <div className={css.sidebar__sticky}>
            <Menu />
            <UserInfo />
         </div>
      </aside>
   )
}
export default Sidebar
