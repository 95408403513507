import React, { useState } from "react"
import clx from "classnames"
import { Button } from "react-bootstrap"
import { GoBackIcon, HamburgerMenuIcon, DuplicateIcon, DeleteBlueIcon, ScheduleIcon } from "../../assets/icons"

import css from "./HeaderEditPage.module.scss"

const HeaderEditPage = ({
   title,
   status,
   recipients,
   handlePublish,
   handleGoBack,
   btnText,
   handleTooltipModal,
   actionVariant = "success",
   disabledBtn = false,
   schedule = null,
}) => {
   const [tooltip, setTooltip] = useState(false)
   const handleShowModal = (type) => {
      handleTooltipModal(type)
      setTooltip(false)
   }
   const activeBtn = tooltip ? "header__tooltip_btnActive" : ""

   const renderSchedule = () => {
      if (schedule) {
         return (
            <div className={clx(css.header__schedule, css[`header__schedule_${schedule}`])}>
               <ScheduleIcon /> Scheduled
            </div>
         )
      }
      return null
   }

   return (
      <>
         <header className={css.header}>
            <div className={css.header__btnGoBack}>
               <Button variant="icon" onClick={handleGoBack}>
                  <GoBackIcon />
               </Button>
            </div>
            <div className={css.header__right}>
               <h3>{title}</h3>
               <div className={css.header__info}>
                  {renderSchedule()}
                  <p>
                     Recipients: <span>{recipients}</span>
                  </p>
                  <p>
                     Status: <span>{status}</span>{" "}
                     <span className={clx(css.header__info_status, css[`header__info_status_${status}`])} />
                  </p>
                  <div className={css.header__tooltip}>
                     <Button
                        variant="icon"
                        bsPrefix={clx(css.header__tooltip_btn, css[activeBtn])}
                        onClick={() => setTooltip(!tooltip)}
                     >
                        <HamburgerMenuIcon />
                     </Button>
                     {tooltip && (
                        <div className={css.header__tooltip_content}>
                           <Button
                              variant="icon"
                              bsPrefix={css.header__tooltip_content_btn}
                              onClick={() => handleShowModal("duplicate")}
                           >
                              <DuplicateIcon /> Duplicate
                           </Button>
                           <Button
                              variant="icon"
                              bsPrefix={css.header__tooltip_content_btn}
                              onClick={() => handleShowModal("delete")}
                           >
                              <DeleteBlueIcon /> Delete
                           </Button>
                        </div>
                     )}
                  </div>

                  <Button onClick={handlePublish} variant={actionVariant} disabled={disabledBtn}>
                     {btnText}
                  </Button>
               </div>
            </div>
         </header>
         <div className={css.header__space} />
      </>
   )
}
export default HeaderEditPage
