import React, { useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import EditBlock from "../../../components/EditBlock"
import useValidationSchema from "../../../customHooks/useValidation"
import { surveysSettingsSchema, handleValidate } from "../../../helpers/validation"

import css from "../SurveysEdit.module.scss"
import { updateSurvey } from "../../../redux/actions/listingActions"
import { connect } from "react-redux"
import { DEFAULT_DEALER } from "../../../utils/constants"
import { SelectDealer } from "../../../components/SelectDealer/SelectDealer"
import TheHubExperienceCompany from "../../../components/TheHubExperienceCompany"

const blankData = {
   title: "",
   url: "",
   subject_line: "",
   message: "",
   dealer: DEFAULT_DEALER,
   hub_experience_company: false,
   push_notification: false,
}

const CreateSettings = ({ data, updateSurvey }) => {
   const [formData, setFormData] = useState({ ...blankData })
   const [touched, setTouched] = useState(false)
   const [formErrors, setFormErrors] = useState({})
   const [complete, setComplete] = useState(false)
   const validate = useValidationSchema(surveysSettingsSchema, formData)

   useEffect(() => {
      if (data) {
         setFormData({
            title: data?.title ?? "",
            url: data?.url ?? "",
            subject_line: data?.subject_line ?? "",
            message: data?.message ?? "",
            dealer: data?.dealer ?? DEFAULT_DEALER,
            hub_experience_company: data?.hub_experience_company ?? false,
            push_notification: data?.push_notification ?? false,
         })
      } else {
         setFormData({ ...blankData })
      }
      setFormErrors({})
      setTouched(false)
      if (data.title && data.dealer && data.url && data.message && data.subject_line) {
         setComplete(true)
      } else {
         setComplete(false)
      }
   }, [data])

   const handleChange = (e) => {
      const { name } = e.target
      const value = e.target.type === "checkbox" ? e.target.checked : e.target.value
      setFormData({
         ...formData,
         [name]: value,
      })
      setTouched(true)
   }
   const handleChangeCheckbox = (e) => {
      const { name, checked } = e.target

      setFormData({
         ...formData,
         [name]: checked,
      })
      setTouched(true)
   }
   const handleSave = async () => {
      const isValid = await handleValidate(setFormErrors, validate)

      if (isValid) {
         await updateSurvey({
            ...formData,
            id: data?.id,
         })
         setTouched(false)
         setFormErrors({})
      }
   }

   const handleClear = () => {
      if (data) {
         setFormData({
            title: data?.title ?? "",
            url: data?.url ?? "",
            subject_line: data?.subject_line ?? "",
            message: data?.message ?? "",
            dealer: data?.dealer ?? DEFAULT_DEALER,
            hub_experience_company: data?.hub_experience_company ?? false,
            push_notification: data?.push_notification ?? false,
         })
      } else {
         setFormData({ ...blankData })
      }
      setFormErrors({})
      setTouched(false)
   }

   return (
      <EditBlock title="Settings" titleNum="1" complete={complete}>
         <Form className={css.settings__form}>
            <div className={css.settings__form_twoCols}>
               <div>
                  <Row className="mb-4">
                     <Form.Group as={Col}>
                        <Form.Label>Internal title</Form.Label>
                        <Form.Control
                           type="text"
                           placeholder="Enter internal title"
                           onChange={handleChange}
                           value={formData.title}
                           name="title"
                           isInvalid={!!formErrors.title}
                        />
                        <Form.Control.Feedback type="invalid">{formErrors.title}</Form.Control.Feedback>
                     </Form.Group>
                     <SelectDealer value={formData.dealer} errors={formErrors.dealer} onChange={handleChange} />
                  </Row>

                  <Form.Group className="mb-4">
                     <Form.Label>Survey URL</Form.Label>
                     <Form.Control
                        type="text"
                        placeholder="Enter survey URL"
                        onChange={handleChange}
                        value={formData.url}
                        name="url"
                        isInvalid={!!formErrors.url}
                     />
                     <Form.Control.Feedback type="invalid">{formErrors.url}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-4">
                     <Form.Label>Headline</Form.Label>
                     <Form.Control
                        type="text"
                        placeholder="Enter headline"
                        onChange={handleChange}
                        value={formData.subject_line}
                        name="subject_line"
                        isInvalid={!!formErrors.subject_line}
                     />
                     <Form.Control.Feedback type="invalid">{formErrors.subject_line}</Form.Control.Feedback>
                  </Form.Group>
               </div>

               <Form.Group className="mb-4">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                     as="textarea"
                     placeholder="Enter message"
                     onChange={handleChange}
                     value={formData.message}
                     name="message"
                     isInvalid={!!formErrors.message}
                     style={{ height: "212px" }}
                  />
                  <Form.Control.Feedback type="invalid">{formErrors.message}</Form.Control.Feedback>
               </Form.Group>
            </div>
            <Form.Group className="mb-4">
               <Form.Check
                  type="checkbox"
                  label="Push notification"
                  bsPrefix="form-check-p"
                  onChange={handleChangeCheckbox}
                  name="push_notification"
                  checked={formData.push_notification}
               />
            </Form.Group>
            <TheHubExperienceCompany
               hub_experience_company={formData.hub_experience_company}
               status={data.status}
               handleChange={handleChange}
            />
            <div className={css.settings__btns}>
               {touched ? (
                  <Button variant="outline-primary" onClick={handleClear}>
                     Discard
                  </Button>
               ) : null}
               <Button onClick={handleSave} disabled={!touched}>
                  {touched ? "Save" : "Saved"}
               </Button>
            </div>
         </Form>
      </EditBlock>
   )
}
export default connect(null, { updateSurvey })(CreateSettings)
