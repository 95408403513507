import React, { useEffect } from "react"
import { connect } from "react-redux"
import { getSustainability } from "../../../redux/actions/listingActions"
import ItemsList from "../../../components/TableLists/ItemsList"

const dataTitles = [
   {
      name: "Title",
      key: "title",
   },
   {
      name: "Subject",
      key: "subject_line",
   },
   {
      name: "Views",
      key: "viewed_count",
   },
   {
      name: "Clicks",
      key: "clicked_count",
   },
]

const TabSustainability = ({ id, getSustainability, data, fetch, error }) => {
   const getDataByQuery = (sort, page = 1) => {
      getSustainability(sort, page, null, id)
   }

   useEffect(() => {
      getSustainability(null, null, null, id)
   }, [getSustainability, id])

   return (
      <ItemsList
         urlEdit="/sustainability/edit/"
         initialSort={"title"}
         data={data}
         fetch={fetch}
         error={error}
         getDataByQuery={getDataByQuery}
         dataTitles={dataTitles}
      />
   )
}
const mapStateToProps = (state) => ({
   data: state.sustainability.data,
   fetch: state.sustainability.fetching,
   error: state.sustainability.error,
})

export default connect(mapStateToProps, { getSustainability })(TabSustainability)
