import css from "../Users.module.scss"

const OwnerList = ({ ownerData, handleClickOwner }) => {
   return (
      ownerData &&
      ownerData.length > 0 && (
         <div className={css.ownerForm__list}>
            {ownerData.map((item) => {
               return (
                  <div
                     onClick={() => handleClickOwner(item)}
                     className={css.ownerForm__list_item}
                     key={`${item.ext_staff_ref}${item.ext_customer_ref}`}
                  >
                     <p>
                        <span>Company:</span> {item.name}
                     </p>
                  </div>
               )
            })}
         </div>
      )
   )
}

export default OwnerList
