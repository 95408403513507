import axios from "../../utils/axios"
import cleanAxios from "axios"

import {
   USERS_REQUEST,
   USERS_SUCCESS,
   USERS_FAILURE,
   USER_BY_ID_REQUEST,
   USER_BY_ID_SUCCESS,
   USER_BY_ID_FAILURE,
   SEARCH_USER_REQUEST,
   SEARCH_USER_SUCCESS,
   SEARCH_USER_FAILURE,
   SEARCH_USER_CLEAR,
   INVITE_OWNER_REQUEST,
   INVITE_OWNER_SUCCESS,
   INVITE_OWNER_FAILURE,
   INVITE_OWNER_CLEAR,
   ADMINS_REQUEST,
   ADMINS_SUCCESS,
   ADMINS_FAILURE,
   ADMIN_BY_ID_REQUEST,
   ADMIN_BY_ID_SUCCESS,
   ADMIN_BY_ID_FAILURE,
   INVITE_OWNER_BG_CALL_REQUEST,
   INVITE_OWNER_BG_CALL_SUCCESS,
   INVITE_OWNER_BG_CALL_FAILURE,
   DELETE_USER_REQUEST,
   DELETE_USER_SUCCESS,
   DELETE_USER_FAILURE,
   DELETE_ADMIN_REQUEST,
   DELETE_ADMIN_SUCCESS,
   DELETE_ADMIN_FAILURE,
   INVITE_ADMIN_REQUEST,
   INVITE_ADMIN_SUCCESS,
   INVITE_ADMIN_FAILURE,
   INVITE_ADMIN_CLEAR,
   VEHICLES_REQUEST,
   VEHICLES_SUCCESS,
   VEHICLES_FAILURE,
   MOVIES_REQUEST,
   MOVIES_SUCCESS,
   MOVIES_FAILURE,
   CREATE_MOVIES_REQUEST,
   CREATE_MOVIES_SUCCESS,
   CREATE_MOVIES_FAILURE,
   CREATE_MOVIES_CLEAR,
   MOVIE_BY_ID_REQUEST,
   MOVIE_BY_ID_SUCCESS,
   MOVIE_BY_ID_FAILURE,
   MOVIE_BY_ID_CLEAR,
   DELETE_MOVIE_REQUEST,
   DELETE_MOVIE_SUCCESS,
   DELETE_MOVIE_FAILURE,
   UPDATE_MOVIE_REQUEST,
   UPDATE_MOVIE_SUCCESS,
   UPDATE_MOVIE_FAILURE,
   DUPLICATE_MOVIE_SUCCESS,
   DUPLICATE_MOVIE_REQUEST,
   DUPLICATE_MOVIE_ERROR,
   NEWS_REQUEST,
   NEWS_SUCCESS,
   NEWS_FAILURE,
   CREATE_NEWS_CLEAR,
   CREATE_NEWS_REQUEST,
   CREATE_NEWS_SUCCESS,
   CREATE_NEWS_FAILURE,
   NEWS_BY_ID_REQUEST,
   NEWS_BY_ID_SUCCESS,
   NEWS_BY_ID_FAILURE,
   NEWS_BY_ID_CLEAR,
   UPDATE_NEWS_REQUEST,
   UPDATE_NEWS_SUCCESS,
   UPDATE_NEWS_FAILURE,
   DELETE_NEWS_REQUEST,
   DELETE_NEWS_SUCCESS,
   DELETE_NEWS_FAILURE,
   DUPLICATE_NEWS_REQUEST,
   DUPLICATE_NEWS_SUCCESS,
   DUPLICATE_NEWS_ERROR,
   SUSTAINABILITY_REQUEST,
   SUSTAINABILITY_SUCCESS,
   SUSTAINABILITY_FAILURE,
   CREATE_SUSTAINABILITY_REQUEST,
   CREATE_SUSTAINABILITY_SUCCESS,
   CREATE_SUSTAINABILITY_FAILURE,
   CREATE_SUSTAINABILITY_CLEAR,
   SUSTAINABILITY_BY_ID_REQUEST,
   SUSTAINABILITY_BY_ID_SUCCESS,
   SUSTAINABILITY_BY_ID_FAILURE,
   UPDATE_SUSTAINABILITY_SUCCESS,
   UPDATE_SUSTAINABILITY_REQUEST,
   UPDATE_SUSTAINABILITY_FAILURE,
   SUSTAINABILITY_BY_ID_CLEAR,
   DELETE_SUSTAINABILITY_REQUEST,
   DELETE_SUSTAINABILITY_SUCCESS,
   DELETE_SUSTAINABILITY_FAILURE,
   DUPLICATE_SUSTAINABILITY_REQUEST,
   DUPLICATE_SUSTAINABILITY_SUCCESS,
   DUPLICATE_SUSTAINABILITY_ERROR,
   SURVEYS_REQUEST,
   SURVEYS_SUCCESS,
   SURVEYS_FAILURE,
   CREATE_SURVEYS_REQUEST,
   CREATE_SURVEYS_SUCCESS,
   CREATE_SURVEYS_FAILURE,
   CREATE_SURVEYS_CLEAR,
   SURVEYS_BY_ID_REQUEST,
   SURVEYS_BY_ID_SUCCESS,
   SURVEYS_BY_ID_FAILURE,
   UPDATE_SURVEYS_REQUEST,
   UPDATE_SURVEYS_SUCCESS,
   UPDATE_SURVEYS_FAILURE,
   SURVEYS_BY_ID_CLEAR,
   DELETE_SURVEYS_REQUEST,
   DELETE_SURVEYS_SUCCESS,
   DELETE_SURVEYS_FAILURE,
   DUPLICATE_SURVEYS_SUCCESS,
   DUPLICATE_SURVEYS_REQUEST,
   DUPLICATE_SURVEYS_ERROR,
   TEMPLATES_REQUEST,
   TEMPLATES_ERROR,
   TEMPLATES_SUCCESS,
   VIDEOS_REQUEST,
   VIDEOS_SUCCESS,
   USER_STAFF_REQUEST,
   USER_STAFF_SUCCESS,
   USER_STAFF_FAILURE,
   USER_STAFF_CLEAR,
   STATISTICS_ERROR,
   STATISTICS_REQUEST,
   STATISTICS_SUCCESS,
} from "./actionTypes"
import history from "../../history"
import { callFailure, callSuccess, callRequest } from "./functions"
//import { addNotification } from "./otherActions"

export const getUsers =
   (sort = null, page = null, searchQuery = null) =>
   (dispatch) => {
      const sortUrl = sort ? `sort=${sort.key}&sort_type=${sort.direction}` : `sort=contact_first_name&sort_type=asc`
      const search = searchQuery ? `&search=${searchQuery}` : ""
      const pageCurrent = page ? `&page=${page}` : `&page=1`
      if (!sort && !page) {
         dispatch(callRequest(USERS_REQUEST))
      }

      axios
         .get(`/customer?${sortUrl}${pageCurrent}${search}&per_page=9`)
         .then((res) => {
            return dispatch(callSuccess(USERS_SUCCESS, res.data.data))
         })
         .catch((error) => {
            return dispatch(callFailure(USERS_FAILURE, error))
         })
   }

export const getUserById = (id) => (dispatch) => {
   dispatch(callRequest(USER_BY_ID_REQUEST))

   axios
      .get(`/customer/${id}`)
      .then((res) => {
         return dispatch(callSuccess(USER_BY_ID_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(USER_BY_ID_FAILURE, error))
      })
}
export const deleteUser = (id) => (dispatch) => {
   dispatch(callRequest(DELETE_USER_REQUEST))

   axios
      .delete(`/customer/${id}`)
      .then(() => {
         dispatch(callSuccess(DELETE_USER_SUCCESS))
         history.push("/users")
         return
      })
      .catch((error) => {
         return dispatch(callFailure(DELETE_USER_FAILURE, error))
      })
}

export const searchUser = (query) => (dispatch) => {
   dispatch(callRequest(SEARCH_USER_REQUEST))

   axios
      .get(`/customer/search?viewAll=1&query=${query}`)
      .then((res) => {
         return dispatch(callSuccess(SEARCH_USER_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(SEARCH_USER_FAILURE, error))
      })
}
export const getUserStaff = (data) => (dispatch) => {
   dispatch(callRequest(USER_STAFF_REQUEST))

   axios
      .post(`/customer/staff`, data)
      .then((res) => {
         return dispatch(callSuccess(USER_STAFF_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(USER_STAFF_FAILURE, error))
      })
}

export const inviteOwner = (data) => (dispatch) => {
   dispatch(callRequest(INVITE_OWNER_REQUEST))

   axios
      .post(`/customer/store`, data)
      .then((res) => {
         //console.log("res.data", res.data)
         dispatch(callSuccess(INVITE_OWNER_SUCCESS, res.data.body))
         dispatch(inviteOwnerBackgroundCall(data))
         return
      })
      .catch((error) => {
         return dispatch(callFailure(INVITE_OWNER_FAILURE, error.response.data.message))
      })
}
export const inviteOwnerBackgroundCall = (data) => (dispatch) => {
   dispatch(callRequest(INVITE_OWNER_BG_CALL_REQUEST))

   axios
      .post(`/customer/retrieve`, data)
      .then(() => {
         return dispatch(callSuccess(INVITE_OWNER_BG_CALL_SUCCESS))
      })
      .catch(() => {
         return dispatch(callFailure(INVITE_OWNER_BG_CALL_FAILURE))
      })
}

export const clearSearchUserData = () => ({
   type: SEARCH_USER_CLEAR,
})

export const clearInviteOwnerData = () => ({
   type: INVITE_OWNER_CLEAR,
})
export const clearStaffData = () => ({
   type: USER_STAFF_CLEAR,
})

/*********Admins ********/
export const getAdmins =
   (sort = null, page = null, searchQuery = null) =>
   (dispatch) => {
      const sortUrl = sort ? `sort=${sort.key}&sort_type=${sort.direction}` : `sort=first_name&sort_type=asc`
      const search = searchQuery ? `&search=${searchQuery}` : ""
      const pageCurrent = page ? `&page=${page}` : `&page=1`
      if (!sort && !page) {
         dispatch(callRequest(ADMINS_REQUEST))
      }

      axios
         .get(`/admin?${sortUrl}${pageCurrent}${search}&per_page=9`)
         .then((res) => {
            return dispatch(callSuccess(ADMINS_SUCCESS, res.data.data))
         })
         .catch((error) => {
            return dispatch(callFailure(ADMINS_FAILURE, error))
         })
   }
export const getAdminById = (id) => (dispatch) => {
   dispatch(callRequest(ADMIN_BY_ID_REQUEST))

   axios
      .get(`/admin/${id}`)
      .then((res) => {
         return dispatch(callSuccess(ADMIN_BY_ID_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(ADMIN_BY_ID_FAILURE, error))
      })
}

export const deleteAdmin = (id) => (dispatch) => {
   dispatch(callRequest(DELETE_ADMIN_REQUEST))

   axios
      .delete(`/admin/${id}`)
      .then(() => {
         dispatch(callSuccess(DELETE_ADMIN_SUCCESS))
         history.push("/admins")
         return
      })
      .catch((error) => {
         return dispatch(callFailure(DELETE_ADMIN_FAILURE, error))
      })
}
export const inviteAdmin = (data) => (dispatch) => {
   dispatch(callRequest(INVITE_ADMIN_REQUEST))

   axios
      .post(`/admin/invite`, data)
      .then((res) => {
         // console.log("res.data", res.data)
         return dispatch(callSuccess(INVITE_ADMIN_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(INVITE_ADMIN_FAILURE, error.response.data.message))
      })
}

export const clearInviteAdminData = () => ({
   type: INVITE_ADMIN_CLEAR,
})

/**************Vehicles **********/
export const getVehicles =
   (sort = null, page = null, companyId) =>
   (dispatch) => {
      const sortUrl = sort ? `sort=${sort.key}&sort_type=${sort.direction}` : `sort=registration_number&sort_type=asc`
      const pageCurrent = page ? `&page=${page}` : `&page=1`

      if (!sort && !page) {
         dispatch(callRequest(VEHICLES_REQUEST))
      }

      axios
         .get(`/vehicle?${sortUrl}${pageCurrent}&company_id=${companyId}&per_page=5`)
         .then((res) => {
            return dispatch(callSuccess(VEHICLES_SUCCESS, res.data.data))
         })
         .catch((error) => {
            return dispatch(callFailure(VEHICLES_FAILURE, error))
         })
   }

export const getMovies =
   (sort = null, page = null, searchQuery = null, companyId = null) =>
   (dispatch) => {
      const sortUrl = sort ? `sort=${sort.key}&sort_type=${sort.direction}` : `sort=title&sort_type=asc`
      const search = searchQuery ? `&search=${searchQuery}` : ""
      const pageCurrent = page ? `&page=${page}` : `&page=1`
      const company = companyId ? `&company_id=${companyId}` : ""
      if (!sort && !page) {
         dispatch(callRequest(MOVIES_REQUEST))
      }

      axios
         .get(`/movie?${sortUrl}${pageCurrent}${search}${company}&per_page=9`)
         .then((res) => {
            return dispatch(callSuccess(MOVIES_SUCCESS, res.data.data))
         })
         .catch((error) => {
            return dispatch(callFailure(MOVIES_FAILURE, error))
         })
   }

export const createMovie = (data) => (dispatch) => {
   dispatch(callRequest(CREATE_MOVIES_REQUEST))

   axios
      .post(`/movie/store`, data)
      .then((res) => {
         return dispatch(callSuccess(CREATE_MOVIES_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(CREATE_MOVIES_FAILURE, error.response.data.message))
      })
}

export const clearMovie = () => ({
   type: CREATE_MOVIES_CLEAR,
})

export const getMovieById = (id) => (dispatch) => {
   dispatch(callRequest(MOVIE_BY_ID_REQUEST))

   axios
      .get(`/movie/${id}`)
      .then((res) => {
         return dispatch(callSuccess(MOVIE_BY_ID_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(MOVIE_BY_ID_FAILURE, error))
      })
}

export const updateMovie = (data) => (dispatch) => {
   dispatch(callRequest(UPDATE_MOVIE_REQUEST))
   axios
      .post("/movie/update", data)
      .then(({ data }) => {
         return dispatch(callSuccess(UPDATE_MOVIE_SUCCESS, data.data))
      })
      .catch((err) => {
         return dispatch(callFailure(UPDATE_MOVIE_FAILURE, err))
      })
}
export const setMovieFPC = (id, data) => (dispatch) => {
   dispatch(callRequest(UPDATE_MOVIE_REQUEST))
   axios
      .post(`/movie/fpc/${id}`, data)
      .then(({ data }) => {
         return dispatch(callSuccess(UPDATE_MOVIE_SUCCESS, data.data))
      })
      .catch((err) => {
         return dispatch(callFailure(UPDATE_MOVIE_FAILURE, err))
      })
}
export const setMovieStatus = (data) => (dispatch) => {
   dispatch(callRequest(UPDATE_MOVIE_REQUEST))
   axios
      .patch("/movie/status", data)
      .then(({ data }) => {
         return dispatch(callSuccess(UPDATE_MOVIE_SUCCESS, data.data))
      })
      .catch((err) => {
         return dispatch(callFailure(UPDATE_MOVIE_FAILURE, err))
      })
}

export const clearMovieById = () => ({
   type: MOVIE_BY_ID_CLEAR,
})

export const deleteMovie = (id) => (dispatch) => {
   dispatch(callRequest(DELETE_MOVIE_REQUEST))

   axios
      .delete(`/movie/${id}`)
      .then(() => {
         dispatch(callSuccess(DELETE_MOVIE_SUCCESS))
         history.push("/movies")
         return
      })
      .catch((error) => {
         return dispatch(callFailure(DELETE_MOVIE_FAILURE, error))
      })
}
export const duplicateMovie = (id) => (dispatch) => {
   dispatch(callRequest(DUPLICATE_MOVIE_REQUEST))

   axios
      .get(`/movie/duplicate/${id}`)
      .then(
         ({
            data: {
               data: { id },
            },
         }) => {
            dispatch(callSuccess(DUPLICATE_MOVIE_SUCCESS))
            history.push(`/movies/edit/${id}`)
            return
         }
      )
      .catch((error) => {
         return dispatch(callFailure(DUPLICATE_MOVIE_ERROR, error))
      })
}
export const uploadPoster = (data) => (dispatch) => {
   dispatch(callRequest(UPDATE_MOVIE_REQUEST))

   axios
      .post("/movie/update", data)
      .then(({ data }) => {
         return dispatch(callSuccess(UPDATE_MOVIE_SUCCESS, data.data))
      })
      .catch((err) => {
         return dispatch(callFailure(UPDATE_MOVIE_FAILURE, err))
      })
}

export const deletePoster = (id) => (dispatch) => {
   dispatch(callRequest(UPDATE_MOVIE_REQUEST))

   axios
      .delete(`/movie/poster/${id}`)
      .then(({ data }) => {
         return dispatch(callSuccess(UPDATE_MOVIE_SUCCESS, data.data))
      })
      .catch((err) => {
         return dispatch(callFailure(UPDATE_MOVIE_FAILURE, err))
      })
}

export const getNews =
   (sort = null, page = null, searchQuery = null, companyId = null) =>
   (dispatch) => {
      const sortUrl = sort ? `sort=${sort.key}&sort_type=${sort.direction}` : `sort=title&sort_type=asc`
      const search = searchQuery ? `&search=${searchQuery}` : ""
      const pageCurrent = page ? `&page=${page}` : `&page=1`
      const company = companyId ? `&company_id=${companyId}` : ""
      if (!sort && !page) {
         dispatch(callRequest(NEWS_REQUEST))
      }

      axios
         .get(`/news?${sortUrl}${pageCurrent}${search}${company}&per_page=9`)
         .then((res) => {
            return dispatch(callSuccess(NEWS_SUCCESS, res.data.data))
         })
         .catch((error) => {
            return dispatch(callFailure(NEWS_FAILURE, error))
         })
   }

export const createNews = (data) => (dispatch) => {
   dispatch(callRequest(CREATE_NEWS_REQUEST))

   axios
      .post(`/news/store`, data)
      .then((res) => {
         return dispatch(callSuccess(CREATE_NEWS_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(CREATE_NEWS_FAILURE, error.response.data.message))
      })
}

export const clearNews = () => ({
   type: CREATE_NEWS_CLEAR,
})

export const setNewsStatus = (data) => (dispatch) => {
   dispatch(callRequest(UPDATE_NEWS_REQUEST))
   axios
      .patch("/news/status", data)
      .then(({ data }) => {
         return dispatch(callSuccess(UPDATE_NEWS_SUCCESS, data.data))
      })
      .catch((err) => {
         return dispatch(callFailure(UPDATE_NEWS_FAILURE, err))
      })
}

export const getNewsById = (id) => (dispatch) => {
   dispatch(callRequest(NEWS_BY_ID_REQUEST))

   axios
      .get(`/news/${id}`)
      .then((res) => {
         return dispatch(callSuccess(NEWS_BY_ID_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(NEWS_BY_ID_FAILURE, error))
      })
}

export const updateNews = (data) => (dispatch) => {
   dispatch(callRequest(UPDATE_NEWS_REQUEST))
   axios
      .put("/news/update", data)
      .then(({ data }) => {
         return dispatch(callSuccess(UPDATE_NEWS_SUCCESS, data.data))
      })
      .catch((err) => {
         return dispatch(callFailure(UPDATE_NEWS_FAILURE, err))
      })
}

export const clearNewsById = () => ({
   type: NEWS_BY_ID_CLEAR,
})

export const deleteNews = (id) => (dispatch) => {
   dispatch(callRequest(DELETE_NEWS_REQUEST))

   axios
      .delete(`/news/${id}`)
      .then(() => {
         dispatch(callSuccess(DELETE_NEWS_SUCCESS))
         history.push("/newsfeed")
         return
      })
      .catch((error) => {
         return dispatch(callFailure(DELETE_NEWS_FAILURE, error))
      })
}
export const duplicateNews = (id) => (dispatch) => {
   dispatch(callRequest(DUPLICATE_NEWS_REQUEST))

   axios
      .get(`/news/duplicate/${id}`)
      .then(
         ({
            data: {
               data: { id },
            },
         }) => {
            dispatch(callSuccess(DUPLICATE_NEWS_SUCCESS))
            history.push(`/newsfeed/edit/${id}`)
            return
         }
      )
      .catch((error) => {
         return dispatch(callFailure(DUPLICATE_NEWS_ERROR, error))
      })
}

export const getSustainability =
   (sort = null, page = null, searchQuery = null, companyId = null) =>
   (dispatch) => {
      const sortUrl = sort ? `sort=${sort.key}&sort_type=${sort.direction}` : `sort=title&sort_type=asc`
      const search = searchQuery ? `&search=${searchQuery}` : ""
      const pageCurrent = page ? `&page=${page}` : `&page=1`
      const company = companyId ? `&company_id=${companyId}` : ""
      if (!sort && !page) {
         dispatch(callRequest(SUSTAINABILITY_REQUEST))
      }

      axios
         .get(`/sustainability?${sortUrl}${pageCurrent}${search}${company}&per_page=9`)
         .then((res) => {
            return dispatch(callSuccess(SUSTAINABILITY_SUCCESS, res.data.data))
         })
         .catch((error) => {
            return dispatch(callFailure(SUSTAINABILITY_FAILURE, error))
         })
   }

export const createSustainability = (data) => (dispatch) => {
   dispatch(callRequest(CREATE_SUSTAINABILITY_REQUEST))

   axios
      .post(`/sustainability/store`, data)
      .then((res) => {
         return dispatch(callSuccess(CREATE_SUSTAINABILITY_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(CREATE_SUSTAINABILITY_FAILURE, error.response.data.message))
      })
}

export const clearSustainability = () => ({
   type: CREATE_SUSTAINABILITY_CLEAR,
})

export const getSustainabilityById = (id) => (dispatch) => {
   dispatch(callRequest(SUSTAINABILITY_BY_ID_REQUEST))

   axios
      .get(`/sustainability/${id}`)
      .then((res) => {
         return dispatch(callSuccess(SUSTAINABILITY_BY_ID_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(SUSTAINABILITY_BY_ID_FAILURE, error))
      })
}

export const updateSustainability = (data) => (dispatch) => {
   dispatch(callRequest(UPDATE_SUSTAINABILITY_REQUEST))
   axios
      .put("/sustainability/update", data)
      .then(({ data }) => {
         return dispatch(callSuccess(UPDATE_SUSTAINABILITY_SUCCESS, data.data))
      })
      .catch((err) => {
         return dispatch(callFailure(UPDATE_SUSTAINABILITY_FAILURE, err))
      })
}

export const clearSustainabilityById = () => ({
   type: SUSTAINABILITY_BY_ID_CLEAR,
})

export const deleteSustainability = (id) => (dispatch) => {
   dispatch(callRequest(DELETE_SUSTAINABILITY_REQUEST))

   axios
      .delete(`/sustainability/${id}`)
      .then(() => {
         dispatch(callSuccess(DELETE_SUSTAINABILITY_SUCCESS))
         history.push("/sustainability")
         return
      })
      .catch((error) => {
         return dispatch(callFailure(DELETE_SUSTAINABILITY_FAILURE, error))
      })
}
export const duplicateSustainability = (id) => (dispatch) => {
   dispatch(callRequest(DUPLICATE_SUSTAINABILITY_REQUEST))

   axios
      .get(`/sustainability/duplicate/${id}`)
      .then(
         ({
            data: {
               data: { id },
            },
         }) => {
            dispatch(callSuccess(DUPLICATE_SUSTAINABILITY_SUCCESS))
            history.push(`/sustainability/edit/${id}`)
            return
         }
      )
      .catch((error) => {
         return dispatch(callFailure(DUPLICATE_SUSTAINABILITY_ERROR, error))
      })
}

export const setSustainabilityStatus = (data) => (dispatch) => {
   dispatch(callRequest(UPDATE_SUSTAINABILITY_REQUEST))
   axios
      .patch("/sustainability/status", data)
      .then(({ data }) => {
         return dispatch(callSuccess(UPDATE_SUSTAINABILITY_SUCCESS, data.data))
      })
      .catch((err) => {
         return dispatch(callFailure(UPDATE_SUSTAINABILITY_FAILURE, err))
      })
}

export const getSurvey =
   (sort = null, page = null, searchQuery = null) =>
   (dispatch) => {
      const sortUrl = sort ? `sort=${sort.key}&sort_type=${sort.direction}` : `sort=title&sort_type=asc`
      const search = searchQuery ? `&search=${searchQuery}` : ""
      const pageCurrent = page ? `&page=${page}` : `&page=1`
      if (!sort && !page) {
         dispatch(callRequest(SURVEYS_REQUEST))
      }

      axios
         .get(`/surveys?${sortUrl}${pageCurrent}${search}&per_page=9`)
         .then((res) => {
            return dispatch(callSuccess(SURVEYS_SUCCESS, res.data.data))
         })
         .catch((error) => {
            return dispatch(callFailure(SURVEYS_FAILURE, error))
         })
   }

export const createSurvey = (data) => (dispatch) => {
   dispatch(callRequest(CREATE_SURVEYS_REQUEST))

   axios
      .post(`/surveys/store`, data)
      .then((res) => {
         return dispatch(callSuccess(CREATE_SURVEYS_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(CREATE_SURVEYS_FAILURE, error.response.data.message))
      })
}

export const clearSurvey = () => ({
   type: CREATE_SURVEYS_CLEAR,
})

export const getSurveyById = (id) => (dispatch) => {
   dispatch(callRequest(SURVEYS_BY_ID_REQUEST))

   axios
      .get(`/surveys/${id}`)
      .then((res) => {
         return dispatch(callSuccess(SURVEYS_BY_ID_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(SURVEYS_BY_ID_FAILURE, error))
      })
}
export const setSurveyStatus = (data) => (dispatch) => {
   dispatch(callRequest(UPDATE_SURVEYS_REQUEST))
   axios
      .patch("/surveys/status", data)
      .then(({ data }) => {
         return dispatch(callSuccess(UPDATE_SURVEYS_SUCCESS, data.data))
      })
      .catch((err) => {
         return dispatch(callFailure(UPDATE_SURVEYS_FAILURE, err))
      })
}

export const updateSurvey = (data) => (dispatch) => {
   dispatch(callRequest(UPDATE_SURVEYS_REQUEST))
   axios
      .put("/surveys/update", data)
      .then(({ data }) => {
         return dispatch(callSuccess(UPDATE_SURVEYS_SUCCESS, data.data))
      })
      .catch((err) => {
         return dispatch(callFailure(UPDATE_SURVEYS_FAILURE, err))
      })
}

export const clearSurveyById = () => ({
   type: SURVEYS_BY_ID_CLEAR,
})

export const deleteSurvey = (id) => (dispatch) => {
   dispatch(callRequest(DELETE_SURVEYS_REQUEST))

   axios
      .delete(`/surveys/${id}`)
      .then(() => {
         dispatch(callSuccess(DELETE_SURVEYS_SUCCESS))
         history.push("/surveys")
         return
      })
      .catch((error) => {
         return dispatch(callFailure(DELETE_SURVEYS_FAILURE, error))
      })
}
export const duplicateSurvey = (id) => (dispatch) => {
   dispatch(callRequest(DUPLICATE_SURVEYS_REQUEST))

   axios
      .get(`/surveys/duplicate/${id}`)
      .then(
         ({
            data: {
               data: { id },
            },
         }) => {
            dispatch(callSuccess(DUPLICATE_SURVEYS_SUCCESS))
            history.push(`/surveys/edit/${id}`)
            return
         }
      )
      .catch((error) => {
         return dispatch(callFailure(DUPLICATE_SURVEYS_ERROR, error))
      })
}

// Templates

export const getTemplates = () => (dispatch) => {
   dispatch(callRequest(TEMPLATES_REQUEST))

   axios
      .get("/template")
      .then(
         ({
            data: {
               data: { templates: data },
            },
         }) => {
            return dispatch(
               callSuccess(
                  TEMPLATES_SUCCESS,
                  Object.entries(data).map(([id, label]) => ({ id, label }))
               )
            )
         }
      )
      .catch((error) => {
         return dispatch(callFailure(TEMPLATES_ERROR, error))
      })
}

export const getVideos = () => (dispatch) => {
   dispatch(callRequest(VIDEOS_REQUEST))

   cleanAxios
      .get("https://api.vimeo.com/me/projects/5771790/videos", {
         headers: { Authorization: "bearer 391b26ac165371048a7c6acaf7b4d0c9" },
      })
      .then(({ data }) => {
         return dispatch(callSuccess(VIDEOS_SUCCESS, data.data))
      })

   // cleanAxios
   //    .post(
   //       "https://api.vimeo.com/oauth/authorize/client",
   //       {
   //         "grant_type": "client_credentials",
   //         "scope": "public"
   //       }, {
   //          headers: {
   //             Authorization: `basic ${VIMEO_AUTH}`,
   //             Accept: "application/vnd.vimeo.*+json;version=3.4"
   //          }
   //       }
   //    )
}

/****************statistics */
export const getHomeStatistics = () => (dispatch) => {
   dispatch(callRequest(STATISTICS_REQUEST))

   axios
      .get("/statistic")
      .then((res) => {
         return dispatch(callSuccess(STATISTICS_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(STATISTICS_ERROR, error))
      })
}
