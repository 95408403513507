import Sidebar from "../../components/Sidebar"
import css from "./MainLayout.module.scss"

const MainLayout = ({ children }) => {
   return (
      <main className={css.main}>
         <Sidebar />
         <div className={css.main__panel}>
            <div className={css.main__panel_children}>{children}</div>
         </div>
      </main>
   )
}

export default MainLayout
