import React, { useState, useEffect } from "react"
import { Button, Form } from "react-bootstrap"
import { SearchIcon, ResetSearchIcon } from "../../assets/icons"
import { usePrevious } from "../../customHooks/usePrevious"
import css from "./SearchBar.module.scss"

const SearchBar = ({ handleSearch }) => {
   const [input, setInput] = useState("")
   const prevData = usePrevious(input)
   const handleChange = (e) => {
      setInput(e.target.value)
   }

   useEffect(() => {
      let timeOutId = null
      if (prevData !== input) {
         timeOutId = setTimeout(() => {
            if (input.trim().length > 0) {
               handleSearch(input)
            } else handleSearch()
         }, 1000)
      }

      return () => clearTimeout(timeOutId)
   }, [input, prevData, handleSearch])

   return (
      <Form className={css.searchBar} onSubmit={(e) => e.preventDefault()}>
         <div className={css.searchBar__searchIcon}>
            <SearchIcon />
         </div>

         <Form.Control type="text" placeholder="Search for title, status" value={input} onChange={handleChange} />

         {input.length > 0 && (
            <Button variant="icon" className={css.searchBar__reset} onClick={() => setInput("")}>
               <ResetSearchIcon />
            </Button>
         )}
      </Form>
   )
}
export default SearchBar
