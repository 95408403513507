import axios from "../../utils/axios"
import { callFailure, callSuccess, callRequest } from "./functions"
import {
   ANALYTICS_USERS_REQUEST,
   ANALYTICS_USERS_SUCCESS,
   ANALYTICS_USERS_FAILURE,
   YEAR_CHART_REQUEST,
   YEAR_CHART_SUCCESS,
   YEAR_CHART_FAILURE,
   ANALYTICS_CONTENT_REQUEST,
   ANALYTICS_CONTENT_SUCCESS,
   ANALYTICS_CONTENT_FAILURE,
   ANALYTICS_TOP_REQUEST,
   ANALYTICS_TOP_SUCCESS,
   ANALYTICS_TOP_FAILURE,
} from "./actionTypes"

export const getAnalyticsUsers = (year) => (dispatch) => {
   dispatch(callRequest(ANALYTICS_USERS_REQUEST))
   axios
      .get(`/statistic/users/${year}`)
      .then((res) => {
         return dispatch(callSuccess(ANALYTICS_USERS_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(ANALYTICS_USERS_FAILURE, error))
      })
}

export const getYearChart = (year) => (dispatch) => {
   dispatch(callRequest(YEAR_CHART_REQUEST))
   axios
      .get(`/statistic/users/${year}`)
      .then((res) => {
         return dispatch(callSuccess(YEAR_CHART_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(YEAR_CHART_FAILURE, error))
      })
}
export const getAnalyticsContent = () => (dispatch) => {
   dispatch(callRequest(ANALYTICS_CONTENT_REQUEST))
   axios
      .get(`/statistic/content`)
      .then((res) => {
         return dispatch(callSuccess(ANALYTICS_CONTENT_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(ANALYTICS_CONTENT_FAILURE, error))
      })
}

export const getAnalyticsTop =
   (type = "campaign", period = "week") =>
   (dispatch) => {
      dispatch(callRequest(ANALYTICS_TOP_REQUEST))
      axios
         .get(`/statistic/top/${type}/${period}`)
         .then((res) => {
            return dispatch(callSuccess(ANALYTICS_TOP_SUCCESS, res.data.data))
         })
         .catch((error) => {
            return dispatch(callFailure(ANALYTICS_TOP_FAILURE, error))
         })
   }
