import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import HeaderEditPage from "../../components/HeaderEditPage"
import CreatePoster from "./CreatePoster"
import CreateSettings from "./CreateSettings"
import FPCCodes from "./FPCCodes"
import PublishModal from "./PublishModal"
import Analytics from "../../components/Analytics"
import {
   clearMovieById,
   deleteMovie,
   getMovieById,
   duplicateMovie,
   setMovieStatus,
} from "../../redux/actions/listingActions"
import { schedulingCampaign } from "../../redux/actions/campaignsActions"
import TooltipModal from "../CampaignsEdit/TooltipModal"
import { getStatus, getStatusToChange } from "../../utils/utils"
import AccordionCalendar from "../../components/AccordionCalendar"
import history from "../../history"
import css from "./MoviesEdit.module.scss"

const MoviesEdit = ({
   getMovieById,
   clearMovieById,
   data,
   deleteMovie,
   duplicateMovie,
   setMovieStatus,
   schedulingCampaign,
}) => {
   const [modalTooltip, setModalTooltip] = useState({
      type: null,
      show: false,
   })

   const handleShowModalTooltip = (type) => {
      setModalTooltip({
         type,
         show: true,
      })
   }

   const { id } = useParams()
   const [showModalPublish, setModalShowPublish] = useState(false)
   useEffect(() => {
      getMovieById(id)
      return () => {
         clearMovieById()
      }
   }, [id, getMovieById, clearMovieById])

   const handleCloseTooltipModal = useCallback(() => {
      setModalTooltip({ show: false, type: null })
   }, [])

   const handleDuplicateMovie = useCallback(async () => {
      duplicateMovie(id)
      handleCloseTooltipModal()
   }, [id, duplicateMovie, handleCloseTooltipModal])

   const handlePublish = async () => {
      await setMovieStatus({
         movie_id: id,
         status: getStatusToChange(data?.status),
      })
      setModalShowPublish(false)
   }

   const handleDeleteMovie = useCallback(async () => {
      await deleteMovie(id)
   }, [id, deleteMovie])

   const handleSubmitTooltip = useCallback(async () => {
      if (modalTooltip.type === "delete") {
         return handleDeleteMovie()
      } else {
         return handleDuplicateMovie()
      }
   }, [handleDeleteMovie, handleDuplicateMovie, modalTooltip.type])

   const getActionLabel = () => {
      switch (data?.status) {
         case 2:
            return "Republish Movie"
         case 1:
            return "Unpublish Movie"
         case 0:
         default:
            return "Publish Movie"
      }
   }

   const checkDisableBtn = () => {
      let disable = true
      if (data) {
         const { file_name, title, subject_line, intro_text, url } = data
         if (file_name && title && subject_line && intro_text && url) {
            disable = false
         }
      }
      return disable
   }

   const analyticsData = [
      {
         name: "Views",
         value: data?.viewed_count,
      },
      {
         name: "Clicks",
         value: data?.clicked_count,
      },
   ]
   const handleSaveScheduling = (data) => {
      console.log(data)

      const dataSend = {
         content_id: id,
         content_type: "movie",
         publish: data.publish,
         publish_at: data.publish_at,
         unpublish: data.unpublish,
         unpublish_at: data.unpublish_at,
      }
      schedulingCampaign(dataSend)
   }

   const renderScheduleStatus = () => {
      if (data && data.schedule) {
         const dateNow = new Date().toISOString()
         const { publish, publish_at, unpublish, unpublish_at } = data.schedule
         const datePublish = new Date(publish_at).toISOString()
         const dateUnpublish = new Date(unpublish_at).toISOString()

         if (publish && dateNow < datePublish) {
            return "green"
         } else if (unpublish && dateNow < dateUnpublish) {
            return "red"
         }
      }
      return null
   }

   return (
      <>
         <div className={css.campaignsEdit}>
            <HeaderEditPage
               title={data?.title ?? ""}
               status={getStatus(data?.status)}
               recipients={data?.recipients_count ?? 0}
               handleTooltipModal={handleShowModalTooltip}
               handlePublish={() => setModalShowPublish(true)}
               handleGoBack={() => history.push("/movies")}
               actionVariant={data?.status === 1 ? "warning" : undefined}
               btnText={getActionLabel()}
               disabledBtn={checkDisableBtn()}
               schedule={data && renderScheduleStatus()}
            />
            {data && <Analytics data={analyticsData} />}
            <div className={css.campaignsEdit__row}>
               <div className={css.campaignsEdit__left}>
                  {data && <CreatePoster id={data?.id} initialUrl={data?.file_name} />}
               </div>
               <div className={css.campaignsEdit__right}>{data && <CreateSettings data={data} />}</div>
            </div>
            {data && <AccordionCalendar handleSaveScheduling={handleSaveScheduling} data={data.schedule} />}
            {data && <FPCCodes data={data} />}
         </div>
         <PublishModal
            show={showModalPublish}
            onHide={() => setModalShowPublish(false)}
            status={getStatus(data?.status)}
            handleSubmit={handlePublish}
         />
         <TooltipModal
            title={"movie"}
            show={modalTooltip.show}
            type={modalTooltip.type}
            onHide={handleCloseTooltipModal}
            handleSubmit={handleSubmitTooltip}
         />
      </>
   )
}

const mapStateToProps = (state) => ({
   data: state.movieById.data,
   fetching: state.movieById.fetching,
   error: state.movieById.error,
})

export default connect(mapStateToProps, {
   getMovieById,
   clearMovieById,
   deleteMovie,
   duplicateMovie,
   setMovieStatus,
   schedulingCampaign,
})(MoviesEdit)
