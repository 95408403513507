import React from "react"
import css from "./Start.module.scss"

const AnalyticsBlock = ({ statistics }) => {
   const { users, drivers, campaigns_read, movies_viewed, news_read, owner_extended, sustainability_read, vehicles } =
      statistics

   const arr = [
      {
         name: "Active users",
         value: `${users.active}`,
      },
      {
         name: "Active owners",
         value: `${owner_extended.active}`,
      },
      {
         name: "Active drivers",
         value: `${drivers.active}`,
      },
      {
         name: "Active vehicles",
         value: `${vehicles.active}`,
      },
      {
         name: "Campaigns read",
         value: campaigns_read,
      },
      {
         name: "Posts read",
         value: news_read,
      },
      {
         name: "Sustainability posts read",
         value: sustainability_read,
      },
      {
         name: "Movies viewed",
         value: movies_viewed,
      },
   ]
   return (
      <div className={css.start__analytics}>
         {arr.map((item) => {
            return (
               <div className={css.analyticBlock} key={item.name}>
                  <h3>{item.name}</h3>
                  {/* <p>{item.value}</p> */}
                  <p dangerouslySetInnerHTML={{ __html: item.value }} />
               </div>
            )
         })}
      </div>
   )
}
export default AnalyticsBlock
