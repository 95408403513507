import { Modal, Button } from "react-bootstrap"
import css from "./Modal.module.scss"

const ModalWindow = ({
   title,
   subtitle,
   children,
   onHide,
   handleSubmit,
   btnCreateText,
   btnVariant = "primary",
   btnDisable = false,
   ...props
}) => {
   return (
      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered onHide={onHide}>
         <div className={css.modal}>
            <div className={css.modal__header}>
               <h3>{title}</h3>
               <p>{subtitle}</p>
            </div>
            <div className={css.modal__body}>{children}</div>
            <div className={css.modal__footer}>
               <Button variant="outline-primary" onClick={onHide}>
                  Cancel
               </Button>
               <Button onClick={handleSubmit} variant={btnVariant} disabled={btnDisable}>
                  {btnCreateText}
               </Button>
            </div>
         </div>
      </Modal>
   )
}

export default ModalWindow
