import React from "react"
import { Button } from "react-bootstrap"
import css from "../MoviesEdit.module.scss"

const StatisticsView = ({ setStatisticsView, status, recipients, owners = 0 }) => {
   const drivers = recipients && recipients - owners
   const data = [
      {
         name: "Total recipients",
         value: recipients ? recipients : 0,
      },
      {
         name: "Owners",
         value: owners ? owners : 0,
      },
      {
         name: "Drivers",
         value: drivers > 0 ? drivers : 0,
      },
   ]
   return (
      <>
         <div className={css.statistics}>
            {data.map((item) => {
               return (
                  <div className={css.statistics__item} key={item.name}>
                     <div className={css.statistics__item_inner}>
                        <p>{item.name}</p>
                        <h4>{item.value}</h4>
                     </div>
                  </div>
               )
            })}
         </div>
         <div className={css.segmentation__btns}>
            <Button onClick={() => setStatisticsView(false)}>
               {status === 1 ? "Preview" : "Edit"}
            </Button>
         </div>
      </>
   )
}
export default StatisticsView
