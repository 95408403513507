import React, {useMemo} from "react"
import Modal from "../../../components/Modal"

const TooltipModal = ({ show, onHide, handleSubmit, type, title="campaign" }) => {
   const upperTitle = useMemo(() => title[0].toUpperCase() + title.slice(1), [title]);

   const renderModal = () => {
      if (type === "duplicate") {
         return (
            <Modal
               title={`Duplicate ${upperTitle}`}
               show={show}
               onHide={onHide}
               handleSubmit={handleSubmit}
               btnCreateText={`Duplicate ${title}`}
            />
         )
      } else if (type === "delete") {
         return (
            <Modal
               title={`Delete ${upperTitle}`}
               subtitle={`Are you sure you want to delete ${title} ?`}
               show={show}
               onHide={onHide}
               handleSubmit={handleSubmit}
               btnCreateText={`Delete ${upperTitle}`}
               btnVariant="warning"
            />
         )
      } else {
         return null
      }
   }
   return renderModal()
}
export default TooltipModal
