import React, { useEffect, useCallback } from "react"
import clx from "classnames"
import { SaveSuccessIcon, SaveErrorIcon } from "../../assets/icons"
import css from "./Notifications.module.scss"

const MESSAGES = {
   error: {
      id: 1,
      type: "error",
      title: "error",
      icon: <SaveErrorIcon />,
   },

   success: {
      id: 2,
      type: "success",
      title: "success",
      icon: <SaveSuccessIcon />,
   },
}

const Message = ({ removeNotification, notice }) => {
   const initTimer = useCallback(() => {
      const { id } = notice
      setTimeout(() => removeNotification(id), 1000)
   }, [notice, removeNotification])

   useEffect(() => {
      initTimer()
   }, [initTimer])

   return (
      <div className={css.notifications}>
         <div className={clx(css.notifications__item, css[`notifications__item_${MESSAGES[notice.type].type}`])}>
            {MESSAGES[notice.type].icon} {notice.text}
         </div>
      </div>
   )
}

export default Message
