import React from "react"

import UserInfoBlock from "../../../components/UserInfoBlock"
import AnalyticsTabs from "./AnalyticsTabs"

const Analytics = ({ id }) => {
   return (
      <UserInfoBlock title="Analytics">
         <AnalyticsTabs id={id} />
      </UserInfoBlock>
   )
}
export default Analytics
