import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { getAdminById, deleteAdmin } from "../../redux/actions/listingActions"
import HeaderUserPage from "../../components/HeaderUserPage"
import UserInformation from "./UserInformation"
import DeleteModal from "./DeleteModal"
import history from "../../history"

const AdminInfo = ({ getAdminById, user, deleteAdmin }) => {
   const { id } = useParams()
   const [showModalDelete, setModalShowDelete] = useState(false)
   useEffect(() => {
      getAdminById(id)
   }, [id, getAdminById])

   const handleDelete = () => {
      deleteAdmin(id)
      setModalShowDelete(false)
   }

   return (
      <>
         <div>
            <HeaderUserPage
               title={user && `${user.first_name} ${user.last_name}`}
               status={user && user.status}
               role="Admin"
               joined={user && user.created_at}
               handleDelete={() => setModalShowDelete(true)}
               btnDeleteText="Delete Admin"
               handleGoBack={() => history.push("/admins")}
            />
            <UserInformation data={user} />
         </div>
         <DeleteModal
            show={showModalDelete}
            onHide={() => setModalShowDelete(false)}
            handleSubmit={handleDelete}
            userName={user && `${user.first_name} ${user.last_name}`}
         />
      </>
   )
}
const mapStateToProps = (state) => {
   return {
      user: state.adminById.data,
      fetch: state.adminById.fetching,
      error: state.adminById.error,
   }
}
export default connect(mapStateToProps, { getAdminById, deleteAdmin })(AdminInfo)
