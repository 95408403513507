export const DEFAULT_DEALER = "Scania Sverige"
export const DEFAULT_CURRENCY = "SEK"
export const SEGMENTATION_BY_DEFAULT = {
   vehicles: { model: [], engine: [], gearbox: [], cab: [], model_year: [2009, 2020] },
   segment: {
      construction: [],
      distribution: [],
      timber: [],
      waste: [],
      special_vehicles: [],
      long_haulage: [],
      other: [],
   },
   ownership: { scania_sverige_syd: [], scania_sverige_stockholm_norr: [], sales_dealer: [] },
   roles: { role: [] },
}
