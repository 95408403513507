import React from "react"
import ReactDOM from "react-dom"
import { Router } from "react-router-dom"
import { applyMiddleware, compose, createStore } from "redux"
import { Provider } from "react-redux"
import ReduxThunk from "redux-thunk"
import rootReducer from "./redux/reducers"
import history from "./history"

import App from "./App"

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const composeEnhancers = reduxDevTools

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(ReduxThunk)))

ReactDOM.render(
   <Provider store={store}>
      <Router history={history}>
         <App />
      </Router>
   </Provider>,
   document.getElementById("root")
)
