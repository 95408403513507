import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Form} from "react-bootstrap";
import {getTemplates} from "../../redux/actions/listingActions";

export const SelectTemplate = ({value, onChange}) => {
   const templates = useSelector(state => state.templates.data);
   const dispatch = useDispatch();

   useEffect(() => {
      if (templates && templates.length) {
         onChange({target: {value: templates[0].id, name: "template"}});
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [templates]);

   useEffect(() => {
      dispatch(getTemplates());
   }, [dispatch]);

   return <Form.Group className="mb-3">
      <Form.Label>Select template</Form.Label>
      <Form.Select aria-label="Default select" onChange={onChange} value={value} name="template">
         {(templates && templates.length) ? templates.map(t =>
            <option key={t.id} value={t.id}>{t.label}</option>
         ) : null}
      </Form.Select>
   </Form.Group>
};