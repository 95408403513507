import clx from "classnames"
import { connect } from "react-redux"
import { Button, Form } from "react-bootstrap"
import { LogoutIcon } from "../../../assets/icons"
import { logout } from "../../../redux/actions/auth"
import css from "../Sidebar.module.scss"

const Logout = ({ onHide, logout }) => {
   const handleSubmit = () => {
      onHide()
      logout()
   }
   return (
      <div className={clx("modalInner", css.modalForm)}>
         <div className={css.modalInvite__header}>
            <h3>Log out</h3>
         </div>
         <Form>
            <p>Are you sure that you want to log out of the platform and return to the log out screen?</p>
            <div className={css.modalForm__btns}>
               <Button variant="outline-primary" onClick={onHide}>
                  Close
               </Button>
               <Button onClick={handleSubmit} variant="success">
                  <LogoutIcon /> Log out
               </Button>
            </div>
         </Form>
      </div>
   )
}

export default connect(null, { logout })(Logout)
