import { Modal } from "react-bootstrap"
import ChangePasswordModal from "./ChangePassword"
import EditUserInfo from "./EditUserInfo"
import Logout from "./Logout"

const UserModal = ({ type, onHide, ...props }) => {
   const renderModal = () => {
      if (type === "changePassword") {
         return <ChangePasswordModal onHide={onHide} />
      } else if (type === "editInfo") {
         return <EditUserInfo onHide={onHide} />
      } else if (type === "logout") {
         return <Logout onHide={onHide} />
      }
   }
   return (
      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered onHide={onHide}>
         {renderModal()}
      </Modal>
   )
}

export default UserModal
