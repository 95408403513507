const keyToValue = {
   truck: "Truck",
   tractor: "Tractor",

   l13: "13L",
   l16v8: "16L / V8",
   l9: "9L",
   l7: "7L",
   l9Gas: "9L gas",
   l13Gas: "13L gas",
   ethanol13: "13 ethanol",
   phev: "PHEV",
   bev: "BEV",

   rangeSplit: "Range split",
   range: "Range",
   automatic: "Automatic",

   cabL: "L",
   cabP: "P",
   cabG: "G",
   cabR: "R",
   cabS: "S",
   crewCab: "Crewcab",

   mixerTruck: "Mixer truck",
   tipperTruck: "Tipper truck",
   skipLoader: "Skip loader",
   hooklift: "Hooklift",

   distribution: "Distribution",
   retailTempTransport: "Retail temperature controlled transport",
   genCargoTransport: "General cargo transport",

   woodChipTransport: "Wood chip transport",
   timberTransport: "Timber transport",

   refuseTrack: "Refuse truck",

   fireTruck: "Fire truck",
   heavyHaulage: "Heavy haulage",
   mining: "Mining",

   vehicleTransport: "Vehicle transport",
   retailVolumeCargoTransport: "Retail volume cargo transport",
   genCargoTransportLongHaulage: "General cargo transport",
   retailTempTransportLongHaulage: "Retail temperature controlled transport",
   fuelTrack: "Fuel truck",

   other: "Other",

   scaniaSyd: "Scania Syd",
   scaniaSthlmNorr: "Scania Sthlm Norr",

   enkoping: "Enköping",
   haggvik: "Häggvik",
   kallhall: "Kallhäll",
   norrtalje: "Norrtälje",

   lund: "Lund",
   malmo: "Malmö",
   helsinborg: "Helsingborg",
   tomelilla: "Tomelilla",

   owner: "Owner",
   driver: "Driver",
}

/*const defaultOnSegmentation = [
   "truck",
   "tractor",
   "l13",
   "l7",
   "phev",
   "range",
   "automatic",
   "cabP",
   "cabG",
   "cabS",
]*/

export const defaultSegmentationData = {
   model_year: [2009, 2020],
   ...Object.keys(keyToValue).reduce((acc, key) => {
      acc[key] = false
      // Select default on
      // acc[key] = defaultOnSegmentation.includes(key);
      return acc
   }, {}),
}

const model = ["truck", "tractor"]
const engine = ["l13", "l16v8", "l9", "l7", "l9Gas", "l13Gas", "ethanol13", "phev", "bev"]
const gearbox = ["rangeSplit", "range", "automatic"]

const cab = ["cabL", "cabP", "cabG", "cabR", "cabS", "crewCab"]

const construction = ["mixerTruck", "tipperTruck", "skipLoader", "hooklift"]

const distribution = ["distribution", "retailTempTransport", "genCargoTransport"]

const timber = ["woodChipTransport", "timberTransport"]

const waste = ["refuseTrack"]

const special_vehicles = ["fireTruck", "heavyHaulage", "mining"]

const long_haulage = [
   "vehicleTransport",
   "retailVolumeCargoTransport",
   "genCargoTransportLongHaulage",
   "retailTempTransportLongHaulage",
   "fuelTrack",
]

const other = ["other"]

const sales_dealer = ["scaniaSyd", "scaniaSthlmNorr"]

const scania_sverige_syd = ["lund", "malmo", "helsinborg", "tomelilla"]

const scania_sverige_stockholm_norr = ["enkoping", "haggvik", "kallhall", "norrtalje"]

// const service_dealer = ["scaniaSverigeStockholmNorrService", "scaniaSverigeSydService"]

const role = ["owner", "driver"]

const vehicles = {
   model,
   engine,
   gearbox,
   cab,
}

const segment = {
   construction,
   distribution,
   timber,
   waste,
   special_vehicles,
   long_haulage,
   other,
}

const ownership = {
   sales_dealer,
   // service_dealer,
   scania_sverige_syd,
   scania_sverige_stockholm_norr,
}

const roles = {
   role,
}

const items = {
   vehicles,
   segment,
   ownership,
   roles,
}

const valueToKey = Object.values(items).reduce((acc, val) => {
   for (let [prefix, category] of Object.entries(val)) {
      for (let item of category) {
         const value = keyToValue[item]
         acc[`${prefix}_${value}`] = item
      }
   }
   return acc
}, {})

export const collectSegmentationData = (formData) => {
   const data = {}
   for (let [key, category] of Object.entries(items)) {
      data[key] = {}
      for (let [catKey, formKeysList] of Object.entries(category)) {
         data[key][catKey] = []
         for (let formKey of formKeysList) {
            if (formData[formKey]) {
               data[key][catKey].push(keyToValue[formKey])
            }
         }
      }
   }
   // Add model year
   data.vehicles.model_year = formData.model_year

   return JSON.stringify(data)
}

export const collectSegmentationFormData = (segmentation) => {
   const data = { ...defaultSegmentationData, model_year: [...defaultSegmentationData.model_year] }
   if (!segmentation) {
      return data
   }

   const segmentationObject = JSON.parse(segmentation)
   for (let [categoryKey, category] of Object.entries(items)) {
      for (let rowKey of Object.keys(category)) {
         try {
            for (let label of segmentationObject[categoryKey][rowKey]) {
               //console.log("segmentationObject", segmentationObject[categoryKey][rowKey])

               data[valueToKey[`${rowKey}_${label}`]] = true
            }
         } catch (e) {
            console.error(e)
         }
      }
   }
   data.model_year = segmentationObject?.vehicles?.model_year
      ? [...segmentationObject.vehicles.model_year]
      : [...defaultSegmentationData.model_year]
   return data
}
