import axios from "../../utils/axios"
import {
   LOGIN_SUCCESS,
   LOGIN_FAILURE,
   LOGOUT,
   CHECK_TOKEN_SUCCESS,
   CHECK_TOKEN_FAILURE,
   ADMIN_INVITE_SUCCESS,
   ADMIN_INVITE_FAILURE,
} from "./actionTypes"

import { callFailure, callSuccess } from "./functions"

// export const register = (data) => (dispatch) => {
//    axios
//       .post(`/register`, data)
//       .then((res) => {
//          if (res.data) {
//             TokenService.setUser(res.data)
//          }
//          return dispatch(callSuccess(REGISTER_SUCCESS))
//       })
//       .catch((error) => {
//          return dispatch(callFailure(REGISTER_FAILURE, error))
//       })
// }

export const login = (data) => (dispatch) => {
   axios
      .post(`/login`, data)
      .then(() => {
         dispatch(callSuccess(LOGIN_SUCCESS))
      })
      .catch((error) => {
         dispatch(callFailure(LOGIN_FAILURE, error))
      })
}

export const logout = () => (dispatch) => {
   axios.post(`/logout`).then(() => {
      dispatch(callSuccess(LOGOUT))
   })
}

export const checkAdminToken = (token) => (dispatch) => {
   axios
      .get(`/admin/check-token?token=${token}`)
      .then((res) => {
         dispatch(callSuccess(CHECK_TOKEN_SUCCESS, res.data.data))
      })
      .catch((error) => {
         dispatch(callFailure(CHECK_TOKEN_FAILURE, error))
      })
}

export const adminInvite = (data) => (dispatch) => {
   axios
      .post(`/admin/update-user`, data)
      .then((res) => {
         dispatch(callSuccess(ADMIN_INVITE_SUCCESS, res.data.data))
      })
      .catch((error) => {
         dispatch(callFailure(ADMIN_INVITE_FAILURE, error))
      })
}
