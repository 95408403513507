import {useEffect, useState} from "react"
import { Form } from "react-bootstrap"
import Modal from "../../components/Modal"
import useValidationSchema from "../../customHooks/useValidation"
import { createCampaignSchema, handleValidate } from "../../helpers/validation"
import "./Newsfeed.module.scss"
import {connect} from "react-redux";
import {clearNews, createNews} from "../../redux/actions/listingActions";
import history from "../../history";
import {SelectTemplate} from "../../components/SelectTemplate/SelectTemplate";

const CreatePostModal = ({ onHide, show, data, fetching, error, clearNews, createNews }) => {
   const [formData, setFormData] = useState({
      title: "",
   });

   const [formErrors, setFormErrors] = useState({
      title: "",
   });

   useEffect(() => {
      if (data?.id && !fetching && !error) {
         clearNews();
         history.push(`/newsfeed/edit/${data.id}`);
      }
   }, [data, fetching, error, clearNews]);

   const validate = useValidationSchema(createCampaignSchema, formData)

   const handleChange = (e) => {
      const { name, value } = e.target
      setFormData({
         ...formData,
         [name]: value,
      })
   }

   const handleCreate = async () => {
      const isValidate = await handleValidate(setFormErrors, validate)

      if (isValidate) {
         await createNews({
            title: formData.title,
            template: formData.template
         });
      }
   }
   const handleCancel = () => {
      onHide();
      setFormData({
         title: "",
      })
   }

   return (
      <Modal
         title="Create Newsfeed Post"
         subtitle="Enter the internal title of your post and select which template you want to use for the post."
         show={show}
         onHide={handleCancel}
         handleSubmit={handleCreate}
         btnCreateText="Create post"
      >
         <Form.Group className="mb-3">
            <Form.Label>Internal title</Form.Label>
            <Form.Control
               type="text"
               placeholder="Enter internal title"
               onChange={handleChange}
               value={formData.title}
               name="title"
               isInvalid={!!formErrors.title}
            />
            <Form.Control.Feedback type="invalid">{formErrors.title}</Form.Control.Feedback>
         </Form.Group>
         <SelectTemplate value={formData.template} onChange={handleChange} />
      </Modal>
   )
}
const mapStateToProps = state => ({
   data: state.createNews.data,
   fetching: state.createNews.fetching,
   error: state.createNews.error,
});

export default connect(mapStateToProps, {createNews, clearNews})(CreatePostModal)
