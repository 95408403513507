export const callRequest = (type) => ({
  type,
});

export const callSuccess = (type, data) => ({
  type,
  payload: data,
});

export const callFailure = (type, error) => ({
  type,
  payload: error,
});
