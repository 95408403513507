import React, { useState, useRef } from "react"
import UserBtn from "./UserBtn"
import UserMenu from "./UserMenu"

const UserInfo = () => {
   const refSidebar = useRef()
   const [openSidebar, setOpenSideBar] = useState(false)

   return (
      <>
         <UserBtn
            name="Andrew Smith"
            openSidebar={openSidebar}
            setOpenSideBar={setOpenSideBar}
            refSidebar={refSidebar}
         />

         <UserMenu refSidebar={refSidebar} openSidebar={openSidebar} />
      </>
   )
}
export default UserInfo
