export const StartIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
         fill="#728497"
         d="M8.707.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L2 8.414V15a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1V8.414l.293.293a1 1 0 001.414-1.414l-7-7z"
      ></path>
   </svg>
)

export const CampaignsIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
         fill="#728497"
         fillRule="evenodd"
         d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.996.996 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
         clipRule="evenodd"
      ></path>
   </svg>
)

export const AdminIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
         fill="#728497"
         fillRule="evenodd"
         d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.953 22.953 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
         clipRule="evenodd"
      ></path>
      <path
         fill="#728497"
         d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.972 24.972 0 0110 15a24.98 24.98 0 01-8-1.308z"
      ></path>
   </svg>
)
export const AnalyticsIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path fill="#728497" d="M2 10a8 8 0 018-8v8h8a8 8 0 01-16 0z"></path>
      <path fill="#728497" d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
   </svg>
)
export const MoviesIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14">
      <path
         fill="#728497"
         fillRule="evenodd"
         d="M2 0a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2zm3 2h6v4H5V2zm8 8v2h1v-2h-1zm-2-2H5v4h6V8zm2 0h1V6h-1v2zm1-4V2h-1v2h1zM3 2v2H2V2h1zm0 4H2v2h1V6zm-1 4h1v2H2v-2z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const NewsfeedIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
         fill="#728497"
         fillRule="evenodd"
         d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
         clipRule="evenodd"
      ></path>
      <path fill="#728497" d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 11-3 0V7z"></path>
   </svg>
)
export const SurveysIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path fill="#728497" d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
      <path
         fill="#728497"
         fillRule="evenodd"
         d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const UsersIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
         fill="#728497"
         d="M13 6a3 3 0 11-6 0 3 3 0 016 0zm5 2a2 2 0 11-4 0 2 2 0 014 0zm-4 7a4 4 0 10-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zm10 10v-3a5.972 5.972 0 00-.75-2.906A3.004 3.004 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
      ></path>
   </svg>
)
export const UserIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="none" viewBox="0 0 29 29">
      <path
         fill="#fff"
         fillRule="evenodd"
         d="M28.5 14.5a14 14 0 11-28 0 14 14 0 0128 0zM18 9.25a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zm-3.5 7a8.75 8.75 0 00-7.955 5.103A10.475 10.475 0 0014.5 25a10.474 10.474 0 007.956-3.647A8.75 8.75 0 0014.5 16.25z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const SustainabilityIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
         fill="#728497"
         fillRule="evenodd"
         d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.003 6.003 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.003 6.003 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const HamburgerIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
         fill="#fff"
         fillRule="evenodd"
         d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 5a1 1 0 011-1h12a1 1 0 010 2H4a1 1 0 01-1-1z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const KeyIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
         fill="#fff"
         fillRule="evenodd"
         d="M16 6a6 6 0 01-7.743 5.743L8 12l-1 1-1 1H4v2H0v-4l4.257-4.257A6 6 0 1116 6zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 002 0 4 4 0 00-4-4z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const UserInfoIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
         fill="#fff"
         fillRule="evenodd"
         d="M10 11a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
         clipRule="evenodd"
      ></path>
   </svg>
)
export const LogoutIcon = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14">
      <path
         fill="#fff"
         fillRule="evenodd"
         d="M15 14a1 1 0 001-1V1a1 1 0 00-2 0v12a1 1 0 001 1zM4.707 4.707a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 101.414-1.414L3.414 8H11a1 1 0 100-2H3.414l1.293-1.293z"
         clipRule="evenodd"
      ></path>
   </svg>
)
