import React from "react"
import { Button, Col, Row } from "react-bootstrap"
const PageNotFound = () => {
   return (
      <div>
         <h1>404 page</h1>

         <Row>
            <Col xs={12} md={4}>
               <Button variant="primary">Create campaign</Button>
            </Col>
            <Col xs={12} md={4}>
               <Button variant="outline-primary">Cancel</Button>
            </Col>
            <Col xs={12} md={4}>
               <Button variant="warning">Unpublish Campaign</Button>
            </Col>
         </Row>
         <Row>
            <Col xs={12} md={4}>
               <Button variant="success">Publish Campaign</Button>
            </Col>
            <Col xs={12} md={4}>
               <Button disabled>Publish Campaign</Button>
            </Col>
         </Row>
      </div>
   )
}
export default PageNotFound
