import {useEffect, useState} from "react"
import CreateBtnHeader from "../../components/CreateBtnHeader"
import Header from "../../components/Header"
import SearchBar from "../../components/SearchBar"
import CreateMovieModal from "./CreateMovieModal"
import ItemsList from "../../components/TableLists/ItemsList"
import {connect} from "react-redux";
import {getMovies} from "../../redux/actions/listingActions";

const dataTitles = [
   {
      name: "Title",
      key: "title",
   },
   {
      name: "Views",
      key: "viewed_count",
      default: 0
   },
   {
      name: "Published",
      key: "published_at",
   },
   {
      name: "Published by",
      key: "published_by",
   },
   {
      name: "Status",
      key: "status",
      default: "Draft"
   }
];

const Movies = ({getMovies, movies, fetch, error}) => {
   const [modalShow, setModalShow] = useState(false);


   useEffect(() => {
      getMovies();
   }, [getMovies])

   const handleSearch = (inputValue) => {
      getMovies(null, 1, inputValue);
   }

   const getDataByQuery = (sort, page = 1) => {
      getMovies(sort, page);
   }

   return (
      <>
         <Header title="Movies">
            <SearchBar handleSearch={handleSearch}/>
            <CreateBtnHeader title="Create movie" clickHandler={() => setModalShow(true)}/>
         </Header>

         <ItemsList
            urlEdit="/movies/edit/"
            initialSort={"title"}
            data={movies}
            fetch={fetch}
            error={error}
            getDataByQuery={getDataByQuery}
            dataTitles={dataTitles}
         />

         <CreateMovieModal
            show={modalShow}
            onHide={() => setModalShow(false)}
         />
      </>
   )
}
const mapStateToProps = state => ({
   movies: state.movies.data,
   fetch: state.movies.fetching,
   error: state.movies.error,
});

export default connect(mapStateToProps, {getMovies})(Movies)
