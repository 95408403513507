import video from "../../assets/video/video.mp4"
import Poster from "../../assets/images/poster.jpg"
import css from "./OnBoardingLayout.module.scss"

const OnBoardingLayout = ({ children }) => {
   return (
      <main className={css.main}>
         <video className={css.main__background} autoPlay loop muted poster={Poster}>
            <source src={video} type="video/mp4" />
            <source src={video} type="video/ogg" />
         </video>
         {children}
      </main>
   )
}

export default OnBoardingLayout
