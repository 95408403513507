import React from "react"
import Modal from "../../../components/Modal"

const DeleteModal = ({ show, onHide, handleSubmit, userName }) => {
   return (
      <Modal
         title="Delete user"
         subtitle={`Are you sure you want to delete user ${userName}?`}
         show={show}
         onHide={onHide}
         handleSubmit={handleSubmit}
         btnCreateText="Delete"
         btnVariant="warning"
      ></Modal>
   )
}
export default DeleteModal
