import {useEffect, useState} from "react"
import { Form } from "react-bootstrap"
import Modal from "../../components/Modal"
import useValidationSchema from "../../customHooks/useValidation"
import { createCampaignSchema, handleValidate } from "../../helpers/validation"
import "./Sustainability.module.scss"
import history from "../../history";
import {connect} from "react-redux";
import {clearSustainability, createSustainability} from "../../redux/actions/listingActions";
import {SelectTemplate} from "../../components/SelectTemplate/SelectTemplate";

const CreateCampaignModal = ({ onHide, show, data, fetching, error, clearSustainability, createSustainability }) => {
   const [formData, setFormData] = useState({
      title: "",
      template: "1",
   })
   const [formErrors, setFormErrors] = useState({
      title: "",
   })
   const validate = useValidationSchema(createCampaignSchema, formData);

   useEffect(() => {
      if (data?.id && !fetching && !error) {
         clearSustainability();
         history.push(`/sustainability/edit/${data.id}`);
      }
   }, [data, fetching, error, clearSustainability]);

   const handleChange = (e) => {
      const { name, value } = e.target
      setFormData({
         ...formData,
         [name]: value,
      })
   }

   const handleCreate = async () => {
      const isValidate = await handleValidate(setFormErrors, validate)

      if (isValidate) {
         await createSustainability({
            title: formData.title,
            template: formData.template
         });
      }
   }
   const handleCancel = () => {
      onHide()
      setFormData({
         title: "",
         template: "1",
      })
   }

   return (
      <Modal
         title="Create Post"
         subtitle="Enter the internal title of your post and select which template you want to use for the post."
         show={show}
         onHide={handleCancel}
         handleSubmit={handleCreate}
         btnCreateText="Create post"
      >
         <Form.Group className="mb-3">
            <Form.Label>Internal title</Form.Label>
            <Form.Control
               type="text"
               placeholder="Enter internal title"
               onChange={handleChange}
               value={formData.title}
               name="title"
               isInvalid={!!formErrors.title}
            />
            <Form.Control.Feedback type="invalid">{formErrors.title}</Form.Control.Feedback>
         </Form.Group>
         <SelectTemplate value={formData.template} onChange={handleChange} />
      </Modal>
   )
}

const mapStateToProps = state => ({
   data: state.createSustainability.data,
   fetching: state.createSustainability.fetching,
   error: state.createSustainability.error,
});

export default connect(mapStateToProps, {clearSustainability, createSustainability})(CreateCampaignModal)
