import { NOTIFICATION_ADD, NOTIFICATION_REMOVE } from "./actionTypes"
export const addNotification = (data) => ({
   type: NOTIFICATION_ADD,
   payload: data,
})

export const removeNotification = (data) => ({
   type: NOTIFICATION_REMOVE,
   payload: data,
})
