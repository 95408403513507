import React, { useEffect } from "react"
import { connect } from "react-redux"
import { getAnalyticsUsers } from "../../redux/actions/analyticsActions"
import DoughnutChart from "./DoughnutChart"
import AverageInfo from "./AverageInfo"
import css from "./Analytics.module.scss"

const UserAnalytics = ({ getAnalyticsUsers, users }) => {
   useEffect(() => {
      const currentYear = new Date().getFullYear()
      getAnalyticsUsers(currentYear)
   }, [getAnalyticsUsers])

   return (
      <>
         <div className={css.analytics__grid}>
            {users && users.users && (
               <DoughnutChart
                  title="Users"
                  value1={users.users.owners}
                  value2={users.users.drivers}
                  name1="Owners + Extended"
                  name2="Drivers"
               />
            )}
            {users && users.onboarded && (
               <DoughnutChart
                  title="Invited drivers VS. onboarded drivers"
                  value1={users.onboarded.invited}
                  value2={users.onboarded.onboarded}
                  name1="Invited"
                  name2="Onboarded"
               />
            )}
         </div>
         <div className={css.analytics__grid}>
            {users && users.openings && (
               <AverageInfo title="Average openings" previous={users.openings.previous} week={users.openings.week} />
            )}
            {users && users.spent_time && (
               <AverageInfo
                  title="Average time spent in app"
                  previous={users.spent_time.previous}
                  week={users.spent_time.week}
                  timeMin={true}
               />
            )}
         </div>
      </>
   )
}
const mapStateToProps = (state) => {
   return {
      users: state.analyticUsers.data,
      usersFetch: state.analyticUsers.fetching,
      usersError: state.analyticUsers.error,
   }
}
export default connect(mapStateToProps, { getAnalyticsUsers })(UserAnalytics)
