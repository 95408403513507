import React, {useEffect, useState} from "react"
import { Button } from "react-bootstrap"
import { UploadIcon, CloseIcon } from "../../assets/icons"
import css from "./FileUpload.module.scss"
import {BASE_URL} from "../../utils/axios";

const FileUpload = ({updateFilesCb, initialUrl}) => {
   const [file, setFile] = useState({
      fileUrl: "",
      errorMessage: false,
      fileName: "",
   });

   useEffect(() => {
      if (initialUrl) {
         setFile(f => ({...f, fileUrl: `${BASE_URL}/${initialUrl}`}));
      }
   }, [initialUrl]);

   const handleChangeImage = (files) => {
      if (files.length === 1) {
         const reader = new FileReader()
         const file = files[0];
         if (file.size < 9000000) {
            reader.onload = (upload) => {
               updateFilesCb(file);
               setFile({
                  fileUrl: upload.target.result,
                  errorMessage: false,
                  fileName: file.name,
               })
            }
            reader.readAsDataURL(file)
         } else {
            setFile({
               fileUrl: "",
               errorMessage: "Image size has to be max 9MB",
               fileName: "",
            })
         }
      } else {
         setFile({
            fileUrl: "",
            errorMessage: "Please, select the correct type",
            fileName: "",
         })
      }
   }

   const handleCancel = () => {
      setFile({
         fileUrl: "",
         bigSize: false,
         fileName: "",
      });
      updateFilesCb(null);
   }

   const dragOver = (e) => {
      e.preventDefault()
   }

   const dragEnter = (e) => {
      e.preventDefault()
   }

   const dragLeave = (e) => {
      e.preventDefault()
   }

   const handleInputClick = (e) => {
      handleChangeImage(e.target.files)
   }

   const fileDrop = (e) => {
      e.preventDefault()
      handleChangeImage(e.dataTransfer.files)
   }
   return file.fileUrl ? (
      <div className={css.fileImg}>
         <img src={file.fileUrl} alt="movie poster" />
         <Button variant="icon" onClick={handleCancel} className={css.fileImg__close}>
            <CloseIcon />
         </Button>
      </div>
   ) : (
      <div
         className={css.uploadFile}
         onDragOver={dragOver}
         onDragEnter={dragEnter}
         onDragLeave={dragLeave}
         onDrop={fileDrop}
      >
         <input
            onChange={handleInputClick}
            type="file"
            name="files"
            id="upload-file__input"
            className={css.uploadFile__input}
            multiple
            accept="image/jpeg,image/png,image/gif"
         />
         <label className={css.uploadFile__label} htmlFor="upload-file__input">
            <UploadIcon />
            <span className={css.uploadFile__text}>{file.fileName ? file.fileName : " Drag image to upload"}</span>
         </label>
         {file.errorMessage && <p className={css.uploadFile__error}>{file.errorMessage}</p>}
      </div>
   )
}
export default FileUpload
