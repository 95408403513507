import React, { useEffect, useState } from "react"

import { Button, Form } from "react-bootstrap"
import useValidationSchema from "../../customHooks/useValidation"
import { editUserSchema, handleValidate } from "../../helpers/validation"
import css from "./OnBoarding.module.scss"

const PersonalDetails = ({ nextStep, values, handleChangeData }) => {
   const [formData, setFormData] = useState({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      country: "",
      company: "",
   })
   const [formErrors, setFormErrors] = useState({})

   useEffect(() => {
      const { first_name, last_name, email, phone_number, country, company } = values
      setFormData({
         first_name,
         last_name,
         email,
         phone_number,
         country,
         company,
      })
   }, [values])

   const validate = useValidationSchema(editUserSchema, formData)

   const handleChange = (e) => {
      const { name, value } = e.target
      setFormData({
         ...formData,
         [name]: value,
      })
   }

   const handleSubmit = async () => {
      const isValid = await handleValidate(setFormErrors, validate)

      if (isValid) {
         handleChangeData(formData)

         nextStep()
      }
   }
   const disableBtn = () => {
      return Object.values(formData).includes("")
   }
   return (
      <>
         <div className={css.onBoarding__header}>
            <h3>User Profile Setup</h3>
            <p>
               You are only a few steps away from getting started with content creation and management of the app, users
               and customers.
            </p>
         </div>

         <Form>
            <div className={css.userForm__twoCols}>
               <Form.Group className="mb-4">
                  <Form.Label>First name</Form.Label>

                  <Form.Control
                     placeholder="First name"
                     onChange={handleChange}
                     value={formData.first_name}
                     name="first_name"
                     isInvalid={!!formErrors.first_name}
                  />
                  <Form.Control.Feedback type="invalid">{formErrors.first_name}</Form.Control.Feedback>
               </Form.Group>
               <Form.Group className="mb-4">
                  <Form.Label>Last name</Form.Label>

                  <Form.Control
                     placeholder="Last name"
                     onChange={handleChange}
                     value={formData.last_name}
                     name="last_name"
                     isInvalid={!!formErrors.last_name}
                  />
                  <Form.Control.Feedback type="invalid">{formErrors.last_name}</Form.Control.Feedback>
               </Form.Group>
            </div>

            <Form.Group className="mb-4">
               <Form.Label>Email</Form.Label>

               <Form.Control
                  type="email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={formData.email}
                  name="email"
                  isInvalid={!!formErrors.email}
                  readOnly
               />
               <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Phone number</Form.Label>
               <Form.Control
                  placeholder="Phone number"
                  onChange={handleChange}
                  value={formData.phone_number}
                  name="phone_number"
                  isInvalid={!!formErrors.phone_number}
               />
               <Form.Control.Feedback type="invalid">{formErrors.phone_number}</Form.Control.Feedback>
            </Form.Group>
            <div className={css.userForm__twoCols}>
               <Form.Group className="mb-4">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                     placeholder="Company"
                     onChange={handleChange}
                     value={formData.company}
                     name="company"
                     isInvalid={!!formErrors.company}
                  />
                  <Form.Control.Feedback type="invalid">{formErrors.company}</Form.Control.Feedback>
               </Form.Group>
               <Form.Group className="mb-4">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                     placeholder="Country"
                     onChange={handleChange}
                     value={formData.country}
                     name="country"
                     isInvalid={!!formErrors.country}
                  />
                  <Form.Control.Feedback type="invalid">{formErrors.country}</Form.Control.Feedback>
               </Form.Group>
            </div>
            <div className={css.userForm__btns}>
               <div />
               <Button onClick={handleSubmit} disabled={disableBtn()}>
                  Next
               </Button>
            </div>
         </Form>
      </>
   )
}
export default PersonalDetails
