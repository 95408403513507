import { useState } from "react"
import { connect } from "react-redux"
import clx from "classnames"
import { Form, Button } from "react-bootstrap"
import { login } from "../../redux/actions/auth"
import useValidationSchema from "../../customHooks/useValidation"
import { loginSchema, handleValidate } from "../../helpers/validation"
import Logo from "../../assets/images/scania-logo.svg"

import css from "./LoginForm.module.scss"

const LoginForm = ({ login, errorAuth }) => {
   const [formData, setFormData] = useState({
      email: "",
      password: "",
   })
   const [formErrors, setFormErrors] = useState({})

   const validate = useValidationSchema(loginSchema, formData)

   const handleChange = (e) => {
      const { name, value } = e.target
      setFormData({
         ...formData,
         [name]: value,
      })
   }

   const handleSubmit = async (e) => {
      e.preventDefault()
      const isValid = await handleValidate(setFormErrors, validate)

      if (isValid) {
         login({
            email: formData.email,
            password: formData.password,
         })
      }
   }

   return (
      <aside className={css.sidebar}>
         <div className={css.sidebar__logo}>
            <img src={Logo} alt="logo" />
         </div>
         <div className={css.sidebar__form}>
            <h2>Scania Hub</h2>
            <Form onSubmit={handleSubmit}>
               <Form.Group className="mb-4">
                  <Form.Label>Username</Form.Label>
                  <div className={clx(css.input, css.input__name)}>
                     <Form.Control
                        type="text"
                        placeholder="Username"
                        onChange={handleChange}
                        value={formData.email}
                        name="email"
                        isInvalid={!!formErrors.email || !!errorAuth}
                     />
                     <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
                  </div>
               </Form.Group>
               <Form.Group className="mb-4">
                  <Form.Label>Password</Form.Label>
                  <div className={clx(css.input, css.input__password)}>
                     <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={handleChange}
                        value={formData.password}
                        name="password"
                        isInvalid={!!formErrors.password || !!errorAuth}
                     />
                     <Form.Control.Feedback type="invalid">{formErrors.password}</Form.Control.Feedback>
                  </div>
               </Form.Group>
               {errorAuth && <p className={css.sidebar__form_error}>Incorrect username/password</p>}
               <Button type="submit">Login</Button>
            </Form>{" "}
         </div>
         <div className={css.sidebar__tip}>
            <p>Please contact your local admin for access</p>
         </div>
      </aside>
   )
}
const mapStateToProps = (state) => {
   return {
      errorAuth: state.auth.error,
   }
}

export default connect(mapStateToProps, { login })(LoginForm)
