import React from "react"
import { Form } from "react-bootstrap"
import CustomSelect from "../CustomSelect"
import css from "./Segmentation.module.scss"

const TabOwnership = ({ data, onChange, handleSelectAll, dealer }) => {
   const checkboxesOwnership = [
      {
         type: "Sales dealer",
         items: [
            {
               label: "Scania Syd",
               name: "scaniaSyd",
               checked: data.scaniaSyd,
            },
            {
               label: "Scania Sthlm Norr",
               name: "scaniaSthlmNorr",
               checked: data.scaniaSthlmNorr,
            },
         ],
      },
   ]

   const checkboxesSegment = [
      {
         type: "Scania Sverige Stockholm Norr",
         items: [
            {
               label: "Enköping",
               name: "enkoping",
               checked: data.enkoping,
            },
            {
               label: "Häggvik",
               name: "haggvik",
               checked: data.haggvik,
            },
            {
               label: "Kallhäll",
               name: "kallhall",
               checked: data.kallhall,
            },
            {
               label: "Norrtälje",
               name: "norrtalje",
               checked: data.norrtalje,
            },
         ],
      },
      {
         type: "Scania Sverige Syd",
         items: [
            {
               label: "Lund",
               name: "lund",
               checked: data.lund,
            },
            {
               label: "Malmö",
               name: "malmo",
               checked: data.malmo,
            },
            {
               label: "Helsingborg",
               name: "helsinborg",
               checked: data.helsinborg,
            },
            {
               label: "Tomelilla",
               name: "tomelilla",
               checked: data.tomelilla,
            },
         ],
      },
   ]
   return (
      <>
         <div className={css.tabInner}>
            {checkboxesOwnership.map((item) => {
               return (
                  <div className={css.tabInner__item} key={item.type}>
                     <div className={css.tabInner__item_title}>
                        <p>{item.type}</p>
                     </div>
                     <div className={css.tabInner__item_list}>
                        {item.items.map((checkbox) => {
                           // if (dealer === "Scania Syd" || dealer === "Scania Sthlm Norr") {
                           //    return (
                           //       <Form.Check
                           //          label={checkbox.label}
                           //          checked={checkbox.checked}
                           //          name={checkbox.name}
                           //          onChange={onChange}
                           //          type="checkbox"
                           //          bsPrefix={css.tabInner__item_checkbox}
                           //          key={checkbox.name}
                           //          disabled={true}
                           //       />
                           //    )
                           // } else {
                              return (
                                 <Form.Check
                                    label={checkbox.label}
                                    checked={checkbox.checked}
                                    name={checkbox.name}
                                    onChange={onChange}
                                    type="checkbox"
                                    bsPrefix={css.tabInner__item_checkbox}
                                    key={checkbox.name}
                                 />
                              )
                           // }
                        })}
                     </div>
                  </div>
               )
            })}
         </div>
         <div className={css.tabInner}>
            <div className={css.tabInner__item}>
               <div className={css.tabInner__item_title}>
                  <p>Service dealer</p>
               </div>
               <div className={css.tabInner__item_list}>
                  {checkboxesSegment.map((item) => {
                     return (
                        <CustomSelect
                           title={item.type}
                           data={item.items}
                           onChange={onChange}
                           handleSelectAll={handleSelectAll}
                           key={item.type}
                           className="ownershipSelect"
                        />
                     )
                  })}
               </div>
            </div>
         </div>
      </>
   )
}
export default TabOwnership
