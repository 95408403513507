/*global ContentEditor*/
import ContentEditorImageWorkerManifest from "../../c5editor/assets/manifests/contenteditor-imageworker.manifest.json"
import ContentEditorManifest from "../../c5editor/assets/manifests/contenteditor.manifest.json"

let contentEditorStatus = "unset"

let contentEditorCallbacks = []

// TODO: Correct path for build and local environments
const imageworkerPath = "/c5editor/assets/manifests/" + ContentEditorImageWorkerManifest.entry

// Content editor instance should probably also be included in callback
//const ContentEditorCallback = (success: boolean) => void

export const loadContentEditor = (callback) => {
   if (contentEditorStatus === "unset") {
      contentEditorStatus = "loading"
      contentEditorCallbacks.push(callback)

      let elem = document.createElement("script")

      // TODO: Correct path for build and local environments
      elem.src = "/c5editor/assets/manifests/" + ContentEditorManifest.entry
      elem.addEventListener("load", () => {
         window.contentEditorImageResampleTaskScheduler = ContentEditor.createImageResampleTaskScheduler(
            new Worker(imageworkerPath)
         )

         contentEditorStatus = "loaded"

         for (let callback of contentEditorCallbacks) {
            callback(true)
         }

         contentEditorCallbacks = null
      })
      elem.addEventListener("error", () => {
         document.head.removeChild(elem)
         contentEditorStatus = "unset"

         for (let callback of contentEditorCallbacks) {
            callback(false)
         }

         contentEditorCallbacks = null
      })

      // elem.async = true;
      document.head.appendChild(elem)

      return () => {
         if (contentEditorCallbacks === null) {
            return
         }

         let i = contentEditorCallbacks.indexOf(callback)
         contentEditorCallbacks.splice(i, 1)
      }
   }

   if (contentEditorStatus === "loading") {
      return () => {}
   }

   if (contentEditorStatus === "loaded") {
      callback(true)
      return () => {}
   }

   throw new Error()
}
