import React, { useState, useEffect } from "react"
import EditBlock from "../EditBlock"
import SegmentationStatistics from "./SegmentationStatistics"
import SegmentationTabs from "./SegmentationTabs"
import { collectSegmentationData } from "./mapUtils"
import { SEGMENTATION_BY_DEFAULT } from "../../utils/constants"

const CreateSegmentation = ({ data, onUpdate }) => {
   const [statisticsView, setStatisticsView] = useState(true)
   const handleSave = async (formData) => {
      if (onUpdate) {
         const segmentation = collectSegmentationData(formData)
         //console.log(JSON.parse(segmentation))
         await onUpdate({ id: data?.id, segmentation })
      }
      setStatisticsView(true)
   }

   useEffect(() => {
      if (data.segmentation && data.segmentation !== JSON.stringify(SEGMENTATION_BY_DEFAULT)) {
         setStatisticsView(true)
      } else {
         setStatisticsView(false)
      }
   }, [data.segmentation])

   return (
      <EditBlock
         title="Segmentation"
         titleNum="3"
         complete={Boolean(data.segmentation && data.segmentation !== JSON.stringify(SEGMENTATION_BY_DEFAULT))}
      >
         {statisticsView ? (
            <SegmentationStatistics
               status={data?.status}
               owners={data?.recipients_owner}
               recipients={data?.recipients_count}
               setStatisticsView={setStatisticsView}
            />
         ) : (
            <SegmentationTabs segmentation={data?.segmentation} dealer={data?.dealer} handleSave={handleSave} status={data?.status} setStatisticsView={setStatisticsView}/>
         )}
      </EditBlock>
   )
}
export default CreateSegmentation
