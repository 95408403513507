let userData = JSON.parse(localStorage.getItem("user"))

const initialState = {
   loggedIn: !!userData,
   error: false,
}

export default function auth(params) {
   return (state = initialState, action = {}) => {
      switch (action.type) {
         case `user/${params}/SUCCESS`:
            return {
               ...state,
               error: false,
               loggedIn: true,
            }
         case `user/${params}/FAILURE`:
            return {
               ...state,
               error: action.payload,
               loggedIn: false,
            }
         case `user/${params}/RESET`:
            return {
               ...state,
               loggedIn: !!userData,
               error: false,
            }
         case `user/logout/SUCCESS`:
            return {
               ...state,
               error: false,
               loggedIn: false,
            }
         default:
            return state
      }
   }
}
