import { useEffect, useRef } from "react"

export const usePrevious = (value) => {
   const ref = useRef()

   //This useEffect will be called in the end of all rendering
   useEffect(() => {
      ref.current = value
   })

   return ref.current
}
