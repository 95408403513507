import { useState, useEffect } from "react"
import Pagination from "../Pagination"
import UsersTable from "./UsersTable"

import css from "./TableLists.module.scss"

const UsersList = ({ url, data, getDataByQuery, dataTitles, fetch, error }) => {
   const [page, setPage] = useState({
      pageSize: 10,
      pageCount: 1,
      currentPage: 1,
   })

   const [sortConfig, setSortConfig] = useState({
      direction: "asc",
      key: dataTitles[0].key,
   })

   useEffect(() => {
      if (data) {
         setPage({
            pageSize: data.per_page,
            pageCount: data.total,
            currentPage: data.current_page,
         })
      }
   }, [data])

   const handlePageChange = (pageNum) => {
      setPage({
         ...page,
         currentPage: pageNum,
      })
      getDataByQuery(sortConfig, pageNum)
   }

   // console.log(data)

   return (
      <div className={css.items__list}>
         <UsersTable
            data={data}
            dataTitles={dataTitles}
            url={url}
            getDataByQuery={getDataByQuery}
            setSortConfig={setSortConfig}
            sortConfig={sortConfig}
            fetch={fetch}
            error={error}
         />

         <Pagination
            className="pagination-bar"
            currentPage={page.currentPage}
            totalCount={page.pageCount}
            pageSize={page.pageSize}
            onPageChange={(page) => handlePageChange(page)}
         />
      </div>
   )
}
export default UsersList
