import { useState } from "react"
import EditBlock from "../../components/EditBlock"
import CreateContentPreview from "./CreateContentPreview"
import Editor from "./Editor"

const EditorC5 = ({ templateId, title, htmlData, data, postImg, error, handleUpdateEditor }) => {
   const [show, setShow] = useState(false)

   const handleClose = () => setShow(false)
   const handleShow = () => setShow(true)

   return (
      <EditBlock title="Content" titleNum="1" complete={htmlData ? true : false} justifyStart>
         {error ? (
            <p>Sorry, something went wrong</p>
         ) : (
            data && (
               <>
                  <CreateContentPreview postImg={postImg} handleShow={handleShow} />
                  <Editor
                     data={data}
                     templateId={templateId}
                     title={title}
                     handleClose={handleClose}
                     show={show}
                     htmlData={htmlData}
                     handleUpdateEditor={handleUpdateEditor}
                  />
               </>
            )
         )}
      </EditBlock>
   )
}

export default EditorC5
