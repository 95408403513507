import React from "react"
import classnames from "classnames"
import { usePagination, DOTS } from "../../customHooks/usePagination"
import { ArrowLeftIcon, ArrowRightIcon } from "../../assets/icons"
import css from "./Pagination.module.scss"

const Pagination = (props) => {
   const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props

   const paginationRange = usePagination({
      currentPage,
      totalCount,
      siblingCount,
      pageSize,
   })

   if (currentPage === 0 || paginationRange.length < 2) {
      return null
   }

   const onNext = () => {
      onPageChange(currentPage + 1)
   }

   const onPrevious = () => {
      onPageChange(currentPage - 1)
   }

   let lastPage = paginationRange[paginationRange.length - 1]

   return (
      <ul className={classnames(css.pagination__container, css[className])}>
         <li
            className={classnames(
               css.pagination__item,
               css.pagination__item_arrow,
               currentPage === 1 ? css.pagination__item_disabledArrow : ""
            )}
            onClick={onPrevious}
         >
            <ArrowLeftIcon />
         </li>
         {paginationRange.map((pageNumber) => {
            if (pageNumber === DOTS) {
               return (
                  <li key={pageNumber} className={css.pagination__item_dots}>
                     &#8230;
                  </li>
               )
            }

            return (
               <li
                  key={pageNumber}
                  className={classnames(
                     css.pagination__item,
                     pageNumber === currentPage ? css.pagination__item_selected : ""
                  )}
                  onClick={() => onPageChange(pageNumber)}
               >
                  {pageNumber}
               </li>
            )
         })}
         <li
            className={classnames(
               css.pagination__item,
               css.pagination__item_arrow,
               currentPage === lastPage ? css.pagination__item_disabledArrow : ""
            )}
            onClick={onNext}
         >
            <ArrowRightIcon />
         </li>
      </ul>
   )
}

export default Pagination
