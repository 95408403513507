import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { getYearChart } from "../../redux/actions/analyticsActions"
import { Form, Spinner } from "react-bootstrap"
import Chart from "react-apexcharts"
import css from "./Analytics.module.scss"

const YearChart = ({ getYearChart, yearChartFetch, yearChart }) => {
   const [year, setYear] = useState("")
   const [data, setData] = useState({
      series: [
         {
            name: "Desktops",
            data: [],
         },
      ],
      options: {
         chart: {
            type: "line",
            height: 200,
            zoom: {
               enabled: false,
            },
            toolbar: {
               show: false,
            },
         },
         colors: ["#06D6A0"],
         stroke: {
            show: true,
            lineCap: "butt",
            width: 1.5,
            dashArray: 0,
         },
         markers: {
            size: [7],
            hover: {
               size: 9,
               sizeOffset: 2,
            },
         },
         dataLabels: {
            enabled: false,
         },
         legend: {
            show: false,
         },
         xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            tooltip: {
               enabled: false,
            },
            crosshairs: {
               show: false,
            },
         },
         yaxis: {
            show: false,
         },
         grid: {
            show: false,
         },
         tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
               return `<div class=${css["yearChart__tooltip"]}><span>${series[seriesIndex][dataPointIndex]}</span></div>`
            },

            onDatasetHover: {
               highlightDataSeries: false,
            },
            intersect: true,
            shared: false,
            followCursor: true,
         },
      },
   })

   useEffect(() => {
      const currentYear = new Date().getFullYear()
      getYearChart(currentYear)
   }, [getYearChart])

   useEffect(() => {
      if (yearChart) {
         setData((prev) => ({
            ...prev,
            series: [
               {
                  data: Object.values(yearChart.over_time),
               },
            ],
         }))
         setYear(yearChart.selected_year)
      }
   }, [yearChart])

   const arrYears = (min) => {
      const max = new Date().getFullYear()

      const years = []

      for (var i = max; i >= min; i--) {
         years.push(i)
      }

      return years
   }
   const handleChange = (e) => {
      setYear(e.target.value)
      getYearChart(e.target.value)
   }
   const years = yearChart ? arrYears(yearChart.start_year) : []

   return (
      <div className={css.yearChart}>
         <div className={css.yearChart__header}>
            <h3>Active users over time</h3>

            <div className={css.yearChart__header_select}>
               <Form.Select onChange={handleChange} value={year}>
                  {years &&
                     years.map((item) => {
                        return (
                           <option key={item} value={item}>
                              {item}
                           </option>
                        )
                     })}
               </Form.Select>
            </div>
         </div>

         {yearChartFetch ? (
            <div className={css.yearChart__spinner}>
               <Spinner animation="border" variant="primary" />
            </div>
         ) : (
            <Chart options={data.options} series={data.series} type="line" height={170} />
         )}
      </div>
   )
}
const mapStateToProps = (state) => {
   return {
      yearChart: state.yearChart.data,
      yearChartFetch: state.yearChart.fetching,
      yearChartError: state.yearChart.error,
   }
}
export default connect(mapStateToProps, { getYearChart })(YearChart)
