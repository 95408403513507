import React, { useCallback } from "react"
import EditBlock from "../../../components/EditBlock"
import FileUpload from "../../../components/FileUpload"
import css from "../MoviesEdit.module.scss"
import { connect } from "react-redux"
import { deletePoster, uploadPoster } from "../../../redux/actions/listingActions"

const CreatePoster = ({ id, initialUrl, uploadPoster, deletePoster }) => {
   const updateUploadedFiles = useCallback(
      (file) => {
         if (file) {
            const formData = new FormData()
            formData.append("id", id)
            formData.append("poster", file, file.name)
            uploadPoster(formData)
         } else {
            deletePoster(id)
         }
      },
      [id, deletePoster, uploadPoster]
   )

   return (
      <EditBlock title="Movie Poster" titleNum="1" complete={Boolean(initialUrl)}>
         <div className={css.createContent}>
            <FileUpload accept=".jpg,.png,.jpeg" initialUrl={initialUrl} updateFilesCb={updateUploadedFiles} />
         </div>
      </EditBlock>
   )
}
export default connect(null, { uploadPoster, deletePoster })(CreatePoster)
