import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import HeaderEditPage from "../../components/HeaderEditPage"
import PublishModal from "./PublishModal"
import Analytics from "../../components/Analytics"
import CreateSettings from "./CreateSettings"
import CreateSegmentation from "../../components/CreateSegmentation"
import {
   clearSurveyById,
   deleteSurvey,
   duplicateSurvey,
   getSurveyById,
   setSurveyStatus,
   updateSurvey,
} from "../../redux/actions/listingActions"
import { schedulingCampaign } from "../../redux/actions/campaignsActions"
import { getStatus, getStatusToChange } from "../../utils/utils"
import TooltipModal from "../CampaignsEdit/TooltipModal"
import AccordionCalendar from "../../components/AccordionCalendar"
import history from "../../history"
import css from "./SurveysEdit.module.scss"

const SurveysEdit = ({
   getSurveyById,
   data,
   clearSurveyById,
   deleteSurvey,
   duplicateSurvey,
   setSurveyStatus,
   updateSurvey,
   schedulingCampaign,
}) => {
   const { id } = useParams()
   const [showModalPublish, setModalShowPublish] = useState(false)
   const [modalTooltip, setModalTooltip] = useState({
      type: null,
      show: false,
   })

   const handleShowModalTooltip = (type) => {
      setModalTooltip({
         type,
         show: true,
      })
   }

   useEffect(() => {
      getSurveyById(id)
      return () => {
         clearSurveyById()
      }
   }, [id, getSurveyById, clearSurveyById])

   const getActionLabel = () => {
      switch (data?.status) {
         case 2:
            return "Republish survey"
         case 1:
            return "Unpublish survey"
         case 0:
         default:
            return "Publish survey"
      }
   }

   const handleCloseTooltipModal = useCallback(() => {
      setModalTooltip({ show: false, type: null })
   }, [])

   const handleDeleteSurvey = useCallback(async () => {
      await deleteSurvey(id)
      handleCloseTooltipModal()
   }, [id, deleteSurvey, handleCloseTooltipModal])

   const handleDuplicateSurvey = useCallback(async () => {
      await duplicateSurvey(id)
      handleCloseTooltipModal()
   }, [id, duplicateSurvey, handleCloseTooltipModal])

   const handleSubmitTooltip = useCallback(() => {
      if (modalTooltip.type === "delete") {
         return handleDeleteSurvey()
      }
      return handleDuplicateSurvey()
   }, [handleDeleteSurvey, handleDuplicateSurvey, modalTooltip.type])

   const handlePublish = async () => {
      await setSurveyStatus({
         survey_id: id,
         status: getStatusToChange(data?.status),
      })
      setModalShowPublish(false)
   }

   const handleUpdate = async (data) => {
      await updateSurvey({
         id,
         ...data,
      })
   }
   const checkDisableBtn = () => {
      let disable = true
      if (data) {
         const { segmentation, title, dealer, url, message, subject_line } = data
         if (segmentation && title && dealer && subject_line && message && url) {
            disable = false
         }
      }
      return disable
   }

   const analyticsData = [
      {
         name: "Views",
         value: data?.viewed_count,
      },
      {
         name: "Clicks",
         value: data?.clicked_count,
      },
   ]
   const handleSaveScheduling = (data) => {
      const dataSend = {
         content_id: id,
         content_type: "survey",
         publish: data.publish,
         publish_at: data.publish_at,
         unpublish: data.unpublish,
         unpublish_at: data.unpublish_at,
      }
      schedulingCampaign(dataSend)
   }

   const renderScheduleStatus = () => {
      if (data && data.schedule) {
         const dateNow = new Date().toISOString()
         const { publish, publish_at, unpublish, unpublish_at } = data.schedule
         const datePublish = new Date(publish_at).toISOString()
         const dateUnpublish = new Date(unpublish_at).toISOString()

         if (publish && dateNow < datePublish) {
            return "green"
         } else if (unpublish && dateNow < dateUnpublish) {
            return "red"
         }
      }
      return null
   }
   return (
      <>
         <div className={css.campaignsEdit}>
            <HeaderEditPage
               handleTooltipModal={handleShowModalTooltip}
               title={data?.title ?? ""}
               status={getStatus(data?.status)}
               recipients={data?.recipients_count ?? 0}
               handlePublish={() => setModalShowPublish(true)}
               handleGoBack={() => history.push("/surveys/")}
               actionVariant={data?.status === 1 ? "warning" : undefined}
               btnText={getActionLabel()}
               disabledBtn={checkDisableBtn()}
               schedule={data && renderScheduleStatus()}
            />
            {data && <Analytics data={analyticsData} />}
            {data && <CreateSettings data={data} />}
            <div className="mb-5" />
            {data && <AccordionCalendar handleSaveScheduling={handleSaveScheduling} data={data.schedule} />}
            {data && <CreateSegmentation data={data} onUpdate={handleUpdate} />}
         </div>
         <PublishModal
            show={showModalPublish}
            onHide={() => setModalShowPublish(false)}
            status={getStatus(data?.status)}
            handleSubmit={handlePublish}
         />
         <TooltipModal
            title={"survey"}
            show={modalTooltip.show}
            type={modalTooltip.type}
            onHide={handleCloseTooltipModal}
            handleSubmit={handleSubmitTooltip}
         />
      </>
   )
}

const mapStateToProps = (state) => ({
   data: state.surveysById.data,
   fetching: state.surveysById.fetching,
   error: state.surveysById.error,
})

export default connect(mapStateToProps, {
   getSurveyById,
   clearSurveyById,
   deleteSurvey,
   duplicateSurvey,
   setSurveyStatus,
   updateSurvey,
   schedulingCampaign,
})(SurveysEdit)
