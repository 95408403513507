import { Spinner, Form } from "react-bootstrap"

import css from "../Users.module.scss"

const OwnerForm = ({ ownerDataFetching, handleChange, inputData }) => {
   return (
      <>
         <Form.Group className="mb-3">
            <Form.Label>Company</Form.Label>
            <Form.Control
               type="text"
               placeholder="Search for company name or orgno."
               onChange={handleChange}
               value={inputData}
            />
         </Form.Group>
         {ownerDataFetching && (
            <div className={css.ownerForm__fetching}>
               <Spinner animation="border" variant="primary" />
            </div>
         )}
      </>
   )
}

export default OwnerForm
