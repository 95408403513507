import React from "react"
import { AnalyticsViewIcon } from "../../assets/icons"
import css from "./Analytics.module.scss"

const Analytics = ({ data }) => {
   return (
      <>
         <div className={css.analytics}>
            <h3>
               <AnalyticsViewIcon /> Analytics
            </h3>
            <div className={css.analytics__block}>
               {data.map((item) => {
                  return (
                     <div className={css.analytics__item} key={item.name}>
                        <div className={css.analytics__item_inner}>
                           <p>{item.name}</p>
                           <h4>{item.value}</h4>
                        </div>
                     </div>
                  )
               })}
            </div>
         </div>
      </>
   )
}
export default Analytics
