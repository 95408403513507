import React from "react"
import { Form } from "react-bootstrap"
import css from "./Segmentation.module.scss"

const TabRoles = ({ data, onChange }) => {
   const checkboxesRoles = [
      {
         type: "Role",
         items: [
            {
               label: "Owner + Drivers with extended permissions",
               name: "owner",
               checked: data.owner,
            },
            {
               label: "Drivers",
               name: "driver",
               checked: data.driver,
            },
         ],
      }
   ]

   return (
      <div className={css.tabInner}>
         {checkboxesRoles.map((item) => {
            return (
               <div className={css.tabInner__item} key={item.type}>
                  <div className={css.tabInner__item_title}>
                     <p>{item.type}</p>
                  </div>
                  <div className={css.tabInner__item_list}>
                     {item.items.map((checkbox) => {
                        return (
                           <Form.Check
                              label={checkbox.label}
                              checked={checkbox.checked}
                              name={checkbox.name}
                              onChange={onChange}
                              type="checkbox"
                              bsPrefix={css.tabInner__item_checkbox}
                              key={checkbox.name}
                           />
                        )
                     })}
                  </div>
               </div>
            )
         })}
      </div>
   )
}
export default TabRoles
