import React, { useState } from "react"
import Chart from "react-apexcharts"
import css from "./Analytics.module.scss"

const DoughnutChart = ({ title, name1, name2, value1, value2, count = null }) => {
   const [data] = useState({
      series: [value1, value2],
      options: {
         chart: {
            type: "donut",
            selection: {
               enabled: false,
            },
            states: {
               active: {
                  filter: {
                     type: "none",
                  },
               },
            },
         },
         plotOptions: {
            pie: {
               expandOnClick: false,
               donut: {
                  size: "70%",
               },
            },
         },
         dataLabels: {
            enabled: false,
         },
         legend: {
            show: false,
         },
         tooltip: {
            custom: function ({ series, seriesIndex }) {
               const percentage = (series[seriesIndex] * 100) / (value1 + value2)
               return `<div class=${css["doughnut__tooltip"]}><span>${percentage.toFixed(1)}%</span></div>`
            },
         },
         colors: ["#06D6A0", "#118AB2"],
      },
   })

   return (
      <div className={css.doughnut}>
         <h3>
            {title} {count && `(${count})`}
         </h3>
         <div className={css.doughnut__data}>
            <div className={css.doughnut__data_titles}>
               <p>
                  {name1}: {value1}
               </p>
               <p>
                  {name2}: {value2}
               </p>
            </div>
            <div>
               <Chart options={data.options} series={data.series} type="donut" width="150px" />
            </div>
         </div>
      </div>
   )
}
export default DoughnutChart
