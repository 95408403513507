import React from "react"
import UserInfoBlock from "../../../components/UserInfoBlock"
import css from "../UserInfo.module.scss"

const UserInformation = ({ data, isOnboardingReady }) => {
   const renderData = (data) => {
      if (data && Object.keys(data).length > 0) {
         const arr = [
            {
               key: "First name",
               value: data.contact_first_name ? data.contact_first_name : "",
            },
            {
               key: "Last name",
               value: data.contact_last_name ? data.contact_last_name : "",
            },
            {
               key: "Email",
               value: data.contact_email ? data.contact_email : "",
            },
            {
               key: "Phone",
               value: data.contact_tel ? data.contact_tel : "-",
            },
            {
               key: "Country",
               value: "NEED TO ADD COUNTRY",
            },
            {
               key: "Company",
               value: data.name ? data.name : "-",
            },
            {
               key: "Onbording Ready",
               value: isOnboardingReady ? (<span className="text-success">Yes</span>) : (<span className="text-danger">No</span>),
            }
         ]
         return arr
      }
      return
   }

   const user = renderData(data)

   return (
      <UserInfoBlock title="User Information">
         <div className={css.userInfo}>
            {user &&
               user.map((item) => {
                  return (
                     <div className={css.userInfo__item} key={item.key}>
                        <h3>{item.key}</h3>
                        <p>{item.value}</p>
                     </div>
                  )
               })}
         </div>
      </UserInfoBlock>
   )
}
export default UserInformation
