import { Modal, Button } from "react-bootstrap"
import { InviteCheckIcon, ErrorIcon } from "../../assets/icons"
import css from "./Modal.module.scss"

const InviteModal = ({ children, onHide, error, ...props }) => {
   return (
      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered onHide={onHide}>
         <div className={css.modalInvite}>
            <div className={css.modalInvite__header}>
               {error ? <ErrorIcon /> : <InviteCheckIcon />}
               {error ? <h3>Something went wrong</h3> : <h3>Invite sent</h3>}
            </div>
            <div className={css.modalInvite__body}>{children}</div>
            <div className={css.modalInvite__footer}>
               <Button onClick={onHide}>Close</Button>
            </div>
         </div>
      </Modal>
   )
}

export default InviteModal
