/* eslint-disable no-useless-escape */
// noinspection RegExpRedundantEscape

import React, { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import css from "./EditorC5.module.scss"

const VideoModuleDialog = ({ done, cancel, uri, show, hide }) => {
   const [value, setValue] = useState(uri ? uri : "")
   const handleChange = ({ target: { value } }) => {
      setValue(value)
   }
   const handleCancel = () => {
      cancel()
      hide()
   }
   const handleSave = () => {
      try {
         const uri = value.match(/src\=\"([^\"]*)\"/)[1]
         const width = value.match(/width\=\"([^\"]*)\"/)[1]
         const height = value.match(/height\=\"([^\"]*)\"/)[1]
         const aspectRatio = parseInt(width) / parseInt(height)

         const data = { uri, aspectRatio }
         done(data)
         hide()
      } catch (e) {
         cancel()
         hide()
      }
   }

   return (
      <Modal show={show} size="md" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCancel}>
         <div className={css.linkDialog}>
            <h3>CREATE VIDEO MODULE</h3>
            <p>Paste the embed link below from video sources like YouTube, Vimeo etc.</p>
            <Form.Group className="mb-4">
               <Form.Label>Embed code</Form.Label>
               <Form.Control
                  as="textarea"
                  placeholder="Enter embed code"
                  onChange={handleChange}
                  value={value}
                  style={{ height: "175" }}
               />
            </Form.Group>
            <div className={css.linkDialog__footer}>
               <Button variant="outline-primary" onClick={handleCancel}>
                  Cancel
               </Button>
               <Button onClick={handleSave}>Create video module</Button>
            </div>
         </div>
      </Modal>
   )
}
export default VideoModuleDialog
