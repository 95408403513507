import axios from "../../utils/axios"
import {
   CAMPAIGN_TEMPLATES_SUCCESS,
   CAMPAIGN_TEMPLATES_FAILURE,
   CAMPAIGN_TEMPLATE_DATA_SUCCESS,
   CAMPAIGN_TEMPLATE_DATA_FAILURE,
} from "./actionTypes"

import { callFailure, callSuccess } from "./functions"

export const getCampaignTemplates = () => (dispatch) => {
   axios
      .get(`/template`)
      .then((res) => {
         dispatch(callSuccess(CAMPAIGN_TEMPLATES_SUCCESS, res.data.data.templates))
      })
      .catch((error) => {
         dispatch(callFailure(CAMPAIGN_TEMPLATES_FAILURE, error))
      })
}

export const getCampaignTemplateData = (id) => (dispatch) => {
   axios
      .get(`/template/editor?template=${id}`)
      .then((res) => {
         dispatch(callSuccess(CAMPAIGN_TEMPLATE_DATA_SUCCESS, res.data.data.editor))
      })
      .catch((error) => {
         dispatch(callFailure(CAMPAIGN_TEMPLATE_DATA_FAILURE, error))
      })
}
