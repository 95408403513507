import React from "react"
import { connect } from "react-redux"
import { removeNotification } from "../../redux/actions/otherActions"
import Message from "./Message"

const Notifications = ({ removeNotification, notifications }) => {
   const removeNotificationItem = (id) => {
      removeNotification(id)
   }

   const handleRemove = (id) => (e) => {
      removeNotification(id)
   }

   return (
      Object.keys(notifications).length > 0 && (
         <Message notice={notifications} handleRemove={handleRemove} removeNotification={removeNotificationItem} />
      )
   )
}

const mapStateToProps = (state) => {
   return {
      notifications: state.notifications.data,
   }
}

export default connect(mapStateToProps, { removeNotification })(Notifications)
