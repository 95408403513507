class LocalStorageService {
   static setToken(tokenObj) {
      localStorage.setItem("access_token", tokenObj.access_token)
      localStorage.setItem("refresh_token", tokenObj.refresh_token)
   }
   static getAccessToken() {
      return localStorage.getItem("access_token")
   }
   static getRefreshToken() {
      return localStorage.getItem("refresh_token")
   }
   static clearToken() {
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
   }
}

export default LocalStorageService
