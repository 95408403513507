import * as yup from "yup"

const requiredField = "Field is required"
const minCharacters = (num) => `The length must be at least ${num} characters`
const maxCharacters = (num) => `The length must be at most ${num} characters`

export const handleValidate = async (setFormErrors, validate) => {
   let isValid = true
   const errors = await validate()

   if (errors) {
      setFormErrors(errors)
      isValid = false
   } else {
      setFormErrors({})
   }

   return isValid
}

export const createCampaignSchema = yup.object().shape({
   title: yup.string().required(requiredField).min(3, minCharacters(3)).max(100, maxCharacters(100)),
})

export const campaignsSettingsSchema = yup.object().shape({
   title: yup.string().required(requiredField).min(3, minCharacters(3)).max(100, maxCharacters(100)),
   subject_line: yup.string().required(requiredField).min(3, minCharacters(3)).max(100, maxCharacters(100)),
   intro_text: yup.string().required(requiredField).min(3, minCharacters(3)).max(100, maxCharacters(100)),
})

export const surveysSettingsSchema = yup.object().shape({
   title: yup.string().required(requiredField).min(3, minCharacters(3)).max(100, maxCharacters(100)),
   url: yup.string().required(requiredField).min(3, minCharacters(3)),
   subject_line: yup.string().required(requiredField).min(3, minCharacters(3)).max(100, maxCharacters(100)),
   message: yup.string().required(requiredField).min(3, minCharacters(3)).max(1000, maxCharacters(1000)),
})

export const movieSettingsSchema = yup.object().shape({
   title: yup.string().required(requiredField).min(3, minCharacters(3)).max(100, maxCharacters(100)),
   subject_line: yup.string().required(requiredField).min(3, minCharacters(3)).max(100, maxCharacters(100)),
   intro_text: yup.string().required(requiredField).min(3, minCharacters(3)),
   url: yup.string().required(requiredField).min(3, minCharacters(3)).max(100, maxCharacters(100)),
})
export const FPCCodesSchema = yup.object().shape({
   fpc_codes: yup.string().max(200, maxCharacters(200)),
})
export const loginSchema = yup.object().shape({
   email: yup.string().required(requiredField).max(100, maxCharacters(100)),
   password: yup.string().required(requiredField).max(100, maxCharacters(100)),
})
export const emailSchema = yup.object().shape({
   email: yup.string().required(requiredField).email("Please enter a correct email"),
})
export const changePasswordSchema = yup.object().shape({
   currentPassword: yup.string().required(requiredField).min(6, minCharacters(6)).max(100, maxCharacters(100)),
   newPassword: yup
      .string()
      .required(requiredField)
      .min(16, minCharacters(16))
      .max(100, maxCharacters(100))
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[^A-Za-z0-9])(?=.*[!@#$%^&*])*/, "Need one special character"),

   repeatNewPassword: yup
      .string()
      .required(requiredField)
      .when("newPassword", {
         is: (password) => (password && password.length > 0 ? true : false),
         then: yup.string().oneOf([yup.ref("newPassword")], "Please make sure that your passwords are matching "),
      }),
})
export const createPasswordSchema = yup.object().shape({
   password: yup
      .string()
      .required(requiredField)
      .min(16, minCharacters(16))
      .max(100, maxCharacters(100))
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{16,}$/, "Need one special character"),

   passwordRepeat: yup
      .string()
      .required(requiredField)
      .when("password", {
         is: (password) => (password && password.length > 0 ? true : false),
         then: yup.string().oneOf([yup.ref("password")], "Please make sure that your passwords are matching "),
      }),
})
export const editUserSchema = yup.object().shape({
   first_name: yup.string().required(requiredField).min(2, minCharacters(2)).max(100, maxCharacters(100)),
   last_name: yup.string().required(requiredField).min(2, minCharacters(2)).max(100, maxCharacters(100)),
   email: yup.string().required(requiredField).email("Please enter a correct email"),
   phone_number: yup
      .string()
      .required(requiredField)
      .matches(
         /^([+]46)\s*(7[0236])\s*(\d{4})\s*(\d{3})$/,
         "Please make sure that your phone number is correct. F.e +46 70 1111 222"
      ),
   company: yup.string().required(requiredField).min(3, minCharacters(3)).max(100, maxCharacters(100)),
   country: yup.string().required(requiredField).min(2, minCharacters(2)).max(100, maxCharacters(100)),
})
