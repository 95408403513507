import { Button } from "react-bootstrap"
import clx from "classnames"
import { PlusIcon, CreateIcon } from "../../assets/icons"
import css from "./EditorC5.module.scss"

const CreateContentPreview = ({ handleShow, postImg }) => {
   return (
      <div className={css.createContent}>
         <div className={css.createContent__inner}>
            {!postImg ? (
               <div className={css.createContent__field}>
                  <Button variant="icon" onClick={handleShow}>
                     <PlusIcon />
                  </Button>
               </div>
            ) : (
               <div className={clx(css.createContent__field_img, css.createContent__field)}>
                  <img
                     onClick={handleShow}
                     className={css.createContent__preview}
                     src={postImg}
                     width={"100%"}
                     alt="post-img"
                  />
               </div>
            )}
            <Button onClick={handleShow}>
               <CreateIcon /> {postImg ? "Edit" : "Create"} Content
            </Button>
         </div>
      </div>
   )
}

export default CreateContentPreview
