import React, { useEffect, useState } from "react"
import { Button, Tabs, Tab } from "react-bootstrap"
import TabVehicles from "./TabVehicles"
import TabSegment from "./TabSegment"
import TabOwnership from "./TabOwnership"

import css from "./Segmentation.module.scss"
import TabRoles from "./TabRoles"
import { collectSegmentationFormData } from "./mapUtils"

const SegmentationTabs = ({ handleSave, segmentation, dealer, status, setStatisticsView }) => {
   const [activeTab, setActiveTab] = useState("vehicles")
   const [formData, setFormData] = useState(collectSegmentationFormData())

   useEffect(() => {
      if (segmentation) {
         const fd = collectSegmentationFormData(segmentation)
         setFormData(fd)
      }
   }, [segmentation])

   const onChange = (e) => {
      const { name, checked } = e.target

      setFormData({
         ...formData,
         [name]: checked,
      })
   }

   const handleSelectAll = (data) => {
      setFormData({
         ...formData,
         ...data,
      })
   }

   const handleChangeSlider = (value) => {
      setFormData({
         ...formData,
         model_year: value,
      })
   }

   const tabsItems = [
      {
         name: "Vehicles",
         key: "vehicles",
         content: <TabVehicles data={formData} onChange={onChange} handleChangeSlider={handleChangeSlider} />,
      },
      {
         name: "Segment",
         key: "segment",
         content: <TabSegment data={formData} onChange={onChange} handleSelectAll={handleSelectAll} />,
      },
      {
         name: "Ownership",
         key: "ownership",
         content: (
            <TabOwnership data={formData} dealer={dealer} onChange={onChange} handleSelectAll={handleSelectAll} />
         ),
      },
      {
         name: "Roles",
         key: "roles",
         content: <TabRoles data={formData} onChange={onChange} />,
      },
   ]

   return (
      <>
         <div className={css.segmentation__tabs}>
            <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
               {tabsItems.map((item) => {
                  return (
                     <Tab
                        key={item.key}
                        eventKey={item.key}
                        title={item.name}
                        tabClassName={css.segmentation__tabs_nav}
                     >
                        {item.content}
                     </Tab>
                  )
               })}
            </Tabs>
         </div>
         <div className={css.segmentation__btns}>
            { status === 1 ?
                <Button onClick={() => setStatisticsView(true)} variant="outline-primary">View statistics</Button>
                :
                <Button disabled={status === 1} onClick={() => handleSave(formData)}>Save segmentation</Button>}
         </div>
      </>
   )
}
export default SegmentationTabs
