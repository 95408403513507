import { useState, useEffect, useRef } from "react"
import clx from "classnames"
import { Form, Button } from "react-bootstrap"
import { usePrevious } from "../../customHooks/usePrevious"
import { SelectIcon } from "../../assets/icons"
import css from "./CustomSelect.module.scss"

const CustomSelect = ({ title, data, onChange, handleSelectAll, className = null }) => {
   const [activeGroup, setActiveGroup] = useState("notActive")
   const [activeSelect, setActiveSelect] = useState(false)
   const handleActiveSelect = () => {
      setActiveSelect((prev) => !prev)
   }

   const wrapperRef = useRef(null)
   const prevData = usePrevious(data)

   useEffect(() => {
      if (JSON.stringify(prevData) !== JSON.stringify(data)) {
         const active = data.find((item) => item.checked === true)
         if (active) {
            const notActive = data.find((item) => !item.checked)
            if (notActive) {
               setActiveGroup("partiallyActive")
            } else {
               setActiveGroup("allActive")
            }
         } else {
            setActiveGroup("notActive")
         }
      }
   }, [data, prevData])

   const handleSelectGroup = () => {
      let updateData = {}
      if (activeGroup === "allActive") {
         data.map((item) => {
            updateData[item.name] = false
            return item
         })
      } else {
         data.map((item) => {
            updateData[item.name] = true
            return item
         })
      }

      handleSelectAll(updateData)
   }

   useEffect(() => {
      document.addEventListener("click", handleClickOutside, false)
      return () => {
         document.removeEventListener("click", handleClickOutside, false)
      }
   }, [])

   const handleClickOutside = (event) => {
      if (!wrapperRef.current?.contains(event.target)) {
         setActiveSelect(false)
      }
   }

   const activeClass = activeSelect ? "select__data_active" : ""

   return (
      <div className={clx(css.select, className ? css[`select_${className}`] : "")} ref={wrapperRef}>
         <div className={clx(css.select__inner, css[`select__inner_${activeGroup}`])}>
            <div className={clx("item", css.select__inner_title)} onClick={handleSelectGroup}>
               {title}
            </div>
            <div className={clx("item", css.select__inner_btn)}>
               <Button
                  variant="icon"
                  onClick={() => {
                     handleActiveSelect(title)
                  }}
               >
                  <SelectIcon />
               </Button>
            </div>
         </div>
         <div className={clx(css.select__data, css[activeClass])}>
            {data &&
               data.length > 0 &&
               data.map((checkbox) => {
                  return (
                     <Form.Check
                        label={checkbox.label}
                        checked={checkbox.checked}
                        name={checkbox.name}
                        onChange={onChange}
                        type="checkbox"
                        bsPrefix={css.tabInner__item_checkbox}
                        key={checkbox.name}
                     />
                  )
               })}
         </div>
      </div>
   )
}
export default CustomSelect
