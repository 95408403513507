import { useState, useEffect } from "react"
import { connect } from "react-redux"
import clx from "classnames"
import { Button, Form } from "react-bootstrap"
import useValidationSchema from "../../../customHooks/useValidation"
import { editUserSchema, handleValidate } from "../../../helpers/validation"
import { getUserData, updateUserData } from "../../../redux/actions/userActions"
import Notifications from "../../../components/Notifications"
import css from "../Sidebar.module.scss"

const EditUserInfo = ({ onHide, getUserData, errorUserData, userData, updateUserData, errorUpdate }) => {
   const [formData, setFormData] = useState({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      country: "",
      company: "",
   })
   const [formErrors, setFormErrors] = useState({})

   useEffect(() => {
      getUserData()
   }, [getUserData])

   useEffect(() => {
      if (userData) {
         const { first_name, last_name, company, country, phone_number, email } = userData
         setFormData({
            first_name: first_name ? first_name : "",
            last_name: last_name ? last_name : "",
            email: email ? email : "",
            phone_number: phone_number ? phone_number : "",
            country: country ? country : "",
            company: company ? company : "",
         })
      }
   }, [userData])

   useEffect(() => {
      if (errorUpdate && Object.keys(errorUpdate).length > 0) {
         Object.keys(errorUpdate).forEach((key) => {
            setFormErrors((prev) => {
               return {
                  ...prev,
                  [key]: errorUpdate[key],
               }
            })
         })
      }
   }, [errorUpdate])

   const validate = useValidationSchema(editUserSchema, formData)

   const handleChange = (e) => {
      const { name, value } = e.target
      setFormData({
         ...formData,
         [name]: value,
      })
   }

   const compareDataArr = (arr1, arr2) => {
      const arrNew = {}
      Object.keys(arr1).forEach((element) => {
         if (arr1[element] !== arr2[element]) {
            arrNew[element] = arr1[element]
         }
      })
      return arrNew
   }

   const handleSubmit = async () => {
      const isValid = await handleValidate(setFormErrors, validate)

      if (isValid) {
         const data = compareDataArr(formData, userData)
         updateUserData(data)
      }
   }
   const disableBtn = () => {
      return Object.values(formData).includes("")
   }

   console.log("errorUpdate", errorUpdate)
   // console.log(userData)
   return (
      <div className={clx("modalInner", css.modalForm)}>
         <Notifications />
         <div className={css.modalInvite__header}>
            <h3>Edit User Profile</h3>
         </div>
         {errorUserData ? (
            <p>Sorry, we have error to loading data</p>
         ) : (
            <Form>
               <div className={css.modalForm__twoCols}>
                  <Form.Group className="mb-4">
                     <Form.Label>First name</Form.Label>

                     <Form.Control
                        placeholder="First name"
                        onChange={handleChange}
                        value={formData.first_name}
                        name="first_name"
                        isInvalid={!!formErrors.first_name}
                     />
                     <Form.Control.Feedback type="invalid">{formErrors.first_name}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-4">
                     <Form.Label>Last name</Form.Label>

                     <Form.Control
                        placeholder="Last name"
                        onChange={handleChange}
                        value={formData.last_name}
                        name="last_name"
                        isInvalid={!!formErrors.last_name}
                     />
                     <Form.Control.Feedback type="invalid">{formErrors.last_name}</Form.Control.Feedback>
                  </Form.Group>
               </div>

               <Form.Group className="mb-4">
                  <Form.Label>Email</Form.Label>

                  <Form.Control
                     type="email"
                     placeholder="Email"
                     onChange={handleChange}
                     value={formData.email}
                     name="email"
                     isInvalid={!!formErrors.email}
                  />
                  <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
               </Form.Group>
               <Form.Group className="mb-4">
                  <Form.Label>Phone number</Form.Label>
                  <Form.Control
                     placeholder="Phone number"
                     onChange={handleChange}
                     value={formData.phone_number}
                     name="phone_number"
                     isInvalid={!!formErrors.phone_number}
                  />
                  <Form.Control.Feedback type="invalid">{formErrors.phone_number}</Form.Control.Feedback>
               </Form.Group>
               <div className={css.modalForm__twoCols}>
                  <Form.Group className="mb-4">
                     <Form.Label>Company</Form.Label>
                     <Form.Control
                        placeholder="Company"
                        onChange={handleChange}
                        value={formData.company}
                        name="company"
                        isInvalid={!!formErrors.company}
                     />
                     <Form.Control.Feedback type="invalid">{formErrors.company}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-4">
                     <Form.Label>Country</Form.Label>
                     <Form.Control
                        placeholder="Country"
                        onChange={handleChange}
                        value={formData.country}
                        name="country"
                        isInvalid={!!formErrors.country}
                     />
                     <Form.Control.Feedback type="invalid">{formErrors.country}</Form.Control.Feedback>
                  </Form.Group>
               </div>
               <div className={css.modalForm__btns}>
                  <Button variant="outline-primary" onClick={onHide}>
                     Close
                  </Button>
                  <Button onClick={handleSubmit} disabled={disableBtn()}>
                     Save
                  </Button>
               </div>
            </Form>
         )}
      </div>
   )
}
const mapStateToProps = (state) => {
   return {
      userData: state.user.data,
      errorUserData: state.user.error,
      errorUpdate: state.user.errorUpdate,
   }
}
export default connect(mapStateToProps, { getUserData, updateUserData })(EditUserInfo)
