import React, { useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import EditBlock from "../../../components/EditBlock"
import useValidationSchema from "../../../customHooks/useValidation"
import { campaignsSettingsSchema, handleValidate } from "../../../helpers/validation"
import { updateNews } from "../../../redux/actions/listingActions"
import { connect } from "react-redux"
import { SelectDealer } from "../../../components/SelectDealer/SelectDealer"
import { DEFAULT_DEALER } from "../../../utils/constants"

import css from "../NewsfeedEdit.module.scss"
import TheHubExperienceCompany from "../../../components/TheHubExperienceCompany"

const blankPost = {
   title: "",
   subject_line: "",
   intro_text: "",
   dealer: DEFAULT_DEALER,
   hub_experience_company: false,
   push_notification: false,
}
const CreateSettings = ({ data, updateNews }) => {
   const [formData, setFormData] = useState({ ...blankPost })
   const [formErrors, setFormErrors] = useState({})
   const [touched, setTouched] = useState(false)
   const [complete, setComplete] = useState(false)

   const validate = useValidationSchema(campaignsSettingsSchema, formData)

   useEffect(() => {
      if (data) {
         setFormData({
            title: data?.title ?? "",
            subject_line: data?.subject_line ?? "",
            intro_text: data?.intro_text ?? "",
            dealer: data?.dealer ?? DEFAULT_DEALER,
            hub_experience_company: data?.hub_experience_company ?? false,
            push_notification: data?.push_notification ?? false,
         })
      } else {
         setFormData({ ...blankPost })
      }
      setFormErrors({})
      setTouched(false)
      if (data.title && data.subject_line && data.intro_text) {
         setComplete(true)
      } else {
         setComplete(false)
      }
   }, [data])

   const handleChange = (e) => {
      const { name } = e.target
      const value = e.target.type === "checkbox" ? e.target.checked : e.target.value
      setFormData({
         ...formData,
         [name]: value,
      })
      setTouched(true)
   }
   const handleChangeCheckbox = (e) => {
      const { name, checked } = e.target

      setFormData({
         ...formData,
         [name]: checked,
      })
      setTouched(true)
   }

   const handleSave = async () => {
      const isValid = await handleValidate(setFormErrors, validate)
      // const segmentation = data.segmentation ? JSON.parse(data.segmentation) : SEGMENTATION_BY_DEFAULT
      // let newSegmentation = null
      // if (formData.dealer === "Scania Syd") {
      //    newSegmentation = {
      //       ...segmentation,
      //       ownership: {
      //          ...segmentation.ownership,
      //          sales_dealer: ["Scania Syd"],
      //       },
      //    }
      // } else if (formData.dealer === "Scania Sthlm Norr") {
      //    newSegmentation = {
      //       ...segmentation,
      //       ownership: {
      //          ...segmentation.ownership,
      //          sales_dealer: ["Scania Sthlm Norr"],
      //       },
      //    }
      // } else if (formData.dealer === "Scania Sverige") {
      //    newSegmentation = {
      //       ...segmentation,
      //       ownership: {
      //          ...segmentation.ownership,
      //          sales_dealer: [],
      //       },
      //    }
      // }
      if (isValid) {
         await updateNews({
            ...formData,
            id: data?.id,
            // segmentation: JSON.stringify(newSegmentation),
         })
      }
   }

   const handleDiscard = () => {
      if (data) {
         setFormData({
            title: data?.title ?? "",
            subject_line: data?.subject_line ?? "",
            intro_text: data?.intro_text ?? "",
            dealer: data?.dealer ?? DEFAULT_DEALER,
            hub_experience_company: data?.hub_experience_company ?? false,
            push_notification: data?.push_notification ?? false,
         })
      } else {
         setFormData({ ...blankPost })
      }
      setFormErrors({})
      setTouched(false)
   }

   return (
      <EditBlock title="Settings" titleNum="2" complete={complete}>
         <Form className={css.settings__form}>
            <Row className="mb-4">
               <Form.Group as={Col}>
                  <Form.Label>Internal title</Form.Label>
                  <Form.Control
                     type="text"
                     placeholder="Enter internal title"
                     onChange={handleChange}
                     value={formData.title}
                     name="title"
                     isInvalid={!!formErrors.title}
                  />
                  <Form.Control.Feedback type="invalid">{formErrors.title}</Form.Control.Feedback>
               </Form.Group>
               <SelectDealer value={formData.dealer} errors={formErrors.dealer} onChange={handleChange} />
            </Row>

            <Form.Group className="mb-4">
               <Form.Label>Subject line</Form.Label>
               <Form.Control
                  type="text"
                  placeholder="Enter your subject line"
                  onChange={handleChange}
                  value={formData.subject_line}
                  name="subject_line"
                  isInvalid={!!formErrors.subject_line}
               />
               <Form.Control.Feedback type="invalid">{formErrors.subject_line}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Intro text (pre-header)</Form.Label>
               <Form.Control
                  type="text"
                  placeholder="Enter your intro text"
                  onChange={handleChange}
                  value={formData.intro_text}
                  name="intro_text"
                  isInvalid={!!formErrors.intro_text}
               />
               <Form.Control.Feedback type="invalid">{formErrors.intro_text}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Check
                  type="checkbox"
                  label="Push notification"
                  bsPrefix="form-check-p"
                  onChange={handleChangeCheckbox}
                  name="push_notification"
                  checked={formData.push_notification}
               />
            </Form.Group>
            <TheHubExperienceCompany
               hub_experience_company={formData.hub_experience_company}
               status={data.status}
               handleChange={handleChange}
            />

            <div className={css.settings__btns}>
               {touched ? (
                  <Button onClick={handleDiscard} variant="outline-primary">
                     Discard
                  </Button>
               ) : null}
               <Button disabled={!touched} onClick={handleSave}>
                  {touched ? "Save" : "Saved"}
               </Button>
            </div>
         </Form>
      </EditBlock>
   )
}
export default connect(null, { updateNews })(CreateSettings)
