import { Button } from "react-bootstrap"
import { CloseIcon } from "../../../assets/icons"
import css from "../Users.module.scss"

const OwnerSelected = ({ owner, removeOwner, inviteOwnerData }) => {
   return (
      owner && (
         <div className={css.ownerSelected}>
            <h3>Selected</h3>
            <div className={css.ownerSelected__item}>
               <div className={css.ownerSelected__item_data}>
                  <p>
                     <span>Company:</span> {inviteOwnerData.name}
                  </p>
                   <p>
                     <span>Owner:</span> {owner}
                  </p>
               </div>
               <div className={css.ownerSelected__item_closeBtn}>
                  <Button variant="icon" onClick={removeOwner}>
                     <CloseIcon />
                  </Button>
               </div>
            </div>
         </div>
      )
   )
}

export default OwnerSelected
