import { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Spinner, Form, FloatingLabel } from "react-bootstrap"
import {
   searchUser,
   clearSearchUserData,
   inviteOwner,
   clearInviteOwnerData,
   getUserStaff,
   clearStaffData,
} from "../../../redux/actions/listingActions"
import Modal from "../../../components/Modal"
import InviteUser from "./InviteModal"
import OwnerList from "./OwnerList"
import OwnerSelected from "./OwnerSelected"
import OwnerForm from "./OwnerForm"

import css from "../Users.module.scss"

const NewOwnerModal = ({
   onHide,
   show,
   searchUser,
   ownerData,
   ownerDataFetching,
   inviteOwnerFetching,
   inviteOwnerError,
   inviteOwnerData,
   clearSearchUserData,
   inviteOwner,
   clearInviteOwnerData,
   getUserStaff,
   userStaff,
   userStaffFetching,
   clearStaffData,
}) => {
   const [inputData, setInputData] = useState("")
   const [inputDataSearch, setInputDataSearch] = useState("")
   const [owner, setOwner] = useState(null)
   const [inviteModal, setInviteModal] = useState(false)
   const [selectStaff, setSelectStaff] = useState({
      name: "",
      externalStaffReference: "",
      externalCustomerReference: "",
   })

   useEffect(() => {
      let timeOutId = null
      if (!ownerDataFetching) {
         timeOutId = setTimeout(() => {
            if (inputData !== "" && inputData !== inputDataSearch) {
               setInputDataSearch(inputData)
               removeSelectOwner()
               return searchUser(inputData)
            } else if (inputData === "") {
               clearSearchUserData()
            }
         }, 1000)
      }
      return () => clearTimeout(timeOutId)
   }, [inputData, inputDataSearch, ownerDataFetching, searchUser])

   const handleChange = (e) => {
      const { value } = e.target
      setInputData(value)
   }

   const handleSendInvite = () => {
      // console.log("create modal")
      setInputData("")
      inviteOwner({
         externalStaffReference: selectStaff.externalStaffReference,
         externalCustomerReference: selectStaff.externalCustomerReference,
      })
   }

   useEffect(() => {
      if (inviteOwnerError || inviteOwnerData) {
         onHide()
         setInviteModal(true)
      }
   }, [inviteOwnerData, inviteOwnerError, onHide])

   const handleCancel = () => {
      onHide()
      setInputData("")
      setInputDataSearch("")
      setOwner(null)
      clearSearchUserData()
      clearStaffData()
   }

   const handleClickOwner = (data) => {
      setOwner(data)
      setInputData("")
      setInputDataSearch("")
      clearSearchUserData()
      getUserStaff({
         externalCustomerReference: data.ext_customer_ref,
      })
   }

   const handleCloseInvite = () => {
      setInviteModal(false)
      setOwner(null)
      clearInviteOwnerData()
      clearStaffData()
      removeSelectOwner()
   }

   const handleChangeSelect = (e) => {
      const { value } = e.target
      const name = e.target[e.target.selectedIndex].getAttribute("data-staff")
      const customerRef = e.target[e.target.selectedIndex].getAttribute("data-customerref")

      setSelectStaff({
         externalStaffReference: value,
         externalCustomerReference: customerRef,
         name,
      })
   }

   const removeSelectOwner = () => {
      setSelectStaff({
         name: "",
         externalStaffReference: "",
         externalCustomerReference: "",
      })
   }

   return inviteModal ? (
      <InviteUser
         inviteOwnerError={inviteOwnerError}
         inviteOwnerData={inviteOwnerData}
         show={inviteModal}
         handleCloseInvite={handleCloseInvite}
      />
   ) : (
      <Modal
         title="Invite new owner"
         subtitle="Use the search field to find the company and owner that you want to onboard. Once the owner is onboarded they can invite the drivers in the company during the onboarding process in the app."
         show={show}
         onHide={handleCancel}
         handleSubmit={handleSendInvite}
         btnCreateText="Send invite"
         btnDisable={Boolean(!selectStaff.name)}
      >
         <div className={css.ownerForm}>
            <OwnerForm ownerDataFetching={ownerDataFetching} handleChange={handleChange} inputData={inputData} />
            <OwnerList handleClickOwner={handleClickOwner} ownerData={ownerData} />
         </div>
         {userStaffFetching ? (
            <Spinner animation="border" variant="primary" />
         ) : (
            userStaff && (
               <div className={css.owner}>
                  <FloatingLabel controlId="floatingSelect" label={`Company: ${owner.name}`}>
                     <Form.Select
                        aria-label="Floating label select example"
                        onChange={handleChangeSelect}
                        value={selectStaff.externalStaffReference}
                     >
                        <option>Open this select menu</option>
                        {userStaff.map((item) => {
                           return (
                              <option
                                 key={item.ext_staff_ref}
                                 value={item.ext_staff_ref}
                                 data-customerref={item.ext_customer_ref}
                                 data-staff={`${item.first_name} ${item.last_name}`}
                              >
                                 {item.first_name} {item.last_name}
                              </option>
                           )
                        })}
                     </Form.Select>
                  </FloatingLabel>
               </div>
            )
         )}

         <OwnerSelected removeOwner={removeSelectOwner} owner={selectStaff.name} inviteOwnerData={owner} />

         {inviteOwnerFetching && (
            <div className={css.inviteFetching}>
               <Spinner animation="border" variant="primary" className={css.inviteFetching__spinner} />
            </div>
         )}
      </Modal>
   )
}
const mapStateToProps = (state) => {
   return {
      ownerData: state.searchUser.data,
      ownerDataFetching: state.searchUser.fetching,
      inviteOwnerFetching: state.inviteOwner.fetching,
      inviteOwnerError: state.inviteOwner.error,
      inviteOwnerData: state.inviteOwner.data,

      userStaff: state.userStaff.data,
      userStaffFetching: state.userStaff.fetching,
   }
}
export default connect(mapStateToProps, {
   searchUser,
   clearSearchUserData,
   inviteOwner,
   clearInviteOwnerData,
   getUserStaff,
   clearStaffData,
})(NewOwnerModal)
