import React from "react"
import Header from "../../components/Header"
import YearChart from "./YearChart"
import UserAnalytics from "./UserAnalytics"
import ContentAnalytics from "./ContentAnalytics"
import TableTop10 from "./TableTop10"

const Analytics = () => {
   return (
      <div>
         <Header title="User Analytics" />

         <YearChart />
         <UserAnalytics />
         <ContentAnalytics />

         <TableTop10 />
      </div>
   )
}

export default Analytics
