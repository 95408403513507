import React, { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import css from "./EditorC5.module.scss"

const LinkDialog = ({ done, cancel, uri, show, hide }) => {
   const [formData, setFormData] = useState(uri ? uri : "")
   const handleChange = (e) => {
      const { value } = e.target
      setFormData(value)
   }
   const handleCancel = () => {
      cancel()
      hide()
   }
   const handleSave = () => {
      done(formData)
      hide()
   }

   return (
      <Modal show={show} size="md" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCancel}>
         <div className={css.linkDialog}>
            <h3>CREATE LINK</h3>
            <p>
               Paste or enter your web address. If you enter an email address it automatically becomes a “mailto” link.
               Leave the field empty to remove the link.
            </p>
            <Form.Group className="mb-4">
               <Form.Label>LINK URI</Form.Label>
               <Form.Control type="text" placeholder="Enter URL" onChange={handleChange} value={formData} />
            </Form.Group>
            <div className={css.linkDialog__footer}>
               <Button variant="outline-primary" onClick={handleCancel}>
                  Cancel
               </Button>
               <Button onClick={handleSave}>Create link</Button>
            </div>
         </div>
      </Modal>
   )
}
export default LinkDialog
