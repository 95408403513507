import { useEffect, useState } from "react"
import { connect } from "react-redux"
import CreateBtnHeader from "../../components/CreateBtnHeader"
import Header from "../../components/Header"
import SearchBar from "../../components/SearchBar"
import ItemsList from "../../components/TableLists/ItemsList"
import CreateCampaignModal from "./CreateCampaignModal"
import { getSustainability } from "../../redux/actions/listingActions"

const dataTitles = [
   {
      name: "Title",
      key: "title",
   },
   {
      name: "Subject",
      key: "subject_line",
   },
   {
      name: "Views",
      key: "viewed_count",
      default: 0,
   },
   {
      name: "Published",
      key: "published_at",
   },
   {
      name: "Published by",
      key: "published_by",
   },
   {
      name: "Status",
      key: "status",
      default: "Draft",
   },
]

const Sustainability = ({ getSustainability, data, fetch, error }) => {
   const [modalShow, setModalShow] = useState(false)

   useEffect(() => {
      getSustainability()
   }, [getSustainability])

   const handleSearch = (inputValue) => {
      getSustainability(null, 1, inputValue)
   }
   const getDataByQuery = (sort, page = 1) => {
      getSustainability(sort, page)
   }

   return (
      <>
         <Header title="Sustainability">
            <SearchBar handleSearch={handleSearch} />
            <CreateBtnHeader title="Create sustainability" clickHandler={() => setModalShow(true)} />
         </Header>

         <ItemsList
            urlEdit="/sustainability/edit/"
            initialSort={"title"}
            data={data}
            fetch={fetch}
            error={error}
            getDataByQuery={getDataByQuery}
            dataTitles={dataTitles}
         />
         <CreateCampaignModal show={modalShow} onHide={() => setModalShow(false)} />
      </>
   )
}

const mapStateToProps = (state) => ({
   data: state.sustainability.data,
   fetch: state.sustainability.fetching,
   error: state.sustainability.error,
})

export default connect(mapStateToProps, { getSustainability })(Sustainability)
