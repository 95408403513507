import React from "react"
import { Col, Form } from "react-bootstrap"

export const SelectDealer = ({ value, onChange, errors }) => {
   return (
      <Form.Group as={Col}>
         <Form.Label>Sender</Form.Label>
         <Form.Select aria-label="Default select" onChange={onChange} value={value} name="dealer">
            <option value="Scania Sverige">Scania Sverige</option>
            <option value="Scania Syd">Scania Syd</option>
            <option value="Scania Sthlm Norr">Scania Sthlm Norr</option>
         </Form.Select>
         <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
      </Form.Group>
   )
}
