import { useState, useEffect } from "react"
import { connect } from "react-redux"
import CreateBtnHeader from "../../components/CreateBtnHeader"
import Header from "../../components/Header"
import SearchBar from "../../components/SearchBar"
import UsersList from "../../components/TableLists/UsersList"
import NewAdminModal from "./NewAdminModal"
import { getAdmins } from "../../redux/actions/listingActions"

const Admins = ({ admins, getAdmins, fetch, error }) => {
   const [modalShow, setModalShow] = useState(false)
   useEffect(() => {
      getAdmins()
   }, [getAdmins])

   const getDataByQuery = (sort, page = 1) => {
      getAdmins(sort, page)
   }
   const handleSearch = (inputValue) => {
      getAdmins(null, 1, inputValue)
   }
   const dataTitles = [
      {
         name: "Name",
         key: "first_name",
      },
      {
         name: "Email",
         key: "email",
      },
      {
         name: "Company",
         key: "company",
      },
      {
         name: "Orgno.",
         key: "orgno",
      },
      {
         name: "Status",
         key: "status",
      },
   ]
   return (
      <>
         <Header title="Admins">
            <SearchBar handleSearch={handleSearch} />
            <CreateBtnHeader title="New Admin" clickHandler={() => setModalShow(true)} />
         </Header>

         <UsersList
            url="/admins"
            data={admins}
            getDataByQuery={getDataByQuery}
            dataTitles={dataTitles}
            fetch={fetch}
            error={error}
         />
         <NewAdminModal show={modalShow} onHide={() => setModalShow(false)} />
      </>
   )
}
const mapStateToProps = (state) => {
   return {
      admins: state.admins.data,
      fetch: state.admins.fetching,
      error: state.admins.error,
   }
}
export default connect(mapStateToProps, { getAdmins })(Admins)
