import { combineReducers } from "redux"
import listings from "./listings"
import notifications from "./notifications"
import auth from "./auth"
import user from "./user"

export const rootReducer = combineReducers({
   notifications: notifications("notification"),
   auth: auth("login"),
   user: user("user"),
   password: user("password"),
   users: listings("users"),
   movies: listings("movies"),
   createMovie: listings("createMovie"),
   movieById: listings("movieById"),
   news: listings("news"),
   createNews: listings("createNews"),
   newsById: listings("newsById"),
   sustainability: listings("sustainability"),
   createSustainability: listings("createSustainability"),
   sustainabilityById: listings("sustainabilityById"),
   surveys: listings("surveys"),
   createSurveys: listings("createSurveys"),
   surveysById: listings("surveysById"),
   videos: listings("videos"),
   userById: listings("userById"),
   searchUser: listings("searchUser"),
   userStaff: listings("userStaff"),
   inviteOwner: listings("inviteOwner"),
   admins: listings("admins"),
   adminById: listings("adminById"),
   inviteAdmin: listings("inviteAdmin"),
   vehicles: listings("vehicles"),
   adminToken: user("adminToken"),
   adminInvite: user("adminInvite"),
   campaigns: listings("campaigns"),
   campaignCreate: listings("campaignCreate"),
   campaign: listings("campaign"),
   campaignTemplates: listings("campaignTemplates"),
   campaignTemplateData: listings("campaignTemplateData"),
   templates: listings("templates"),
   statistics: listings("statistics"),
   analyticUsers: listings("analyticsUsers"),
   yearChart: listings("yearChart"),
   analyticsContent: listings("analyticsContent"),
   analyticsTop: listings("analyticsTop"),
})

export default rootReducer
