import { useMemo } from "react"

const makeValidate = (schema, formData) => {
   return async function validate() {
      try {
         await schema.validate(formData, { abortEarly: false })
      } catch (err) {
         const allErrors = err.inner.reduce(
            (errors, currentValidation) =>
               Object.assign(errors, {
                  [currentValidation.path]: currentValidation.errors[0],
               }),
            {}
         )

         return allErrors
      }
   }
}

const useValidationSchema = (schema, formData) => {
   const validate = useMemo(() => makeValidate(schema, formData), [schema, formData])

   return validate
}

export default useValidationSchema
