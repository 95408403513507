import { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Form } from "react-bootstrap"
import {clearCampaign, createCampaign} from "../../redux/actions/campaignsActions"
import { getCampaignTemplates } from "../../redux/actions/c5editorActions"
import Modal from "../../components/Modal"
import useValidationSchema from "../../customHooks/useValidation"
import { createCampaignSchema, handleValidate } from "../../helpers/validation"
import css from "./Campaigns.module.scss"

const CreateCampaignModal = ({
   onHide,
   show,
   handleShowEdit,
   createCampaign,
   campaignCreate,
   clearCampaign,
   error,
   getCampaignTemplates,
   campaignTemplates,
   campaignTemplatesError,
}) => {
   const [formData, setFormData] = useState({
      title: "",
      template: "1",
   })
   const [formErrors, setFormErrors] = useState({
      title: "",
   })
   const validate = useValidationSchema(createCampaignSchema, formData)
   useEffect(() => {
      getCampaignTemplates()
   }, [getCampaignTemplates])

   const handleChange = (e) => {
      const { name, value } = e.target
      setFormData({
         ...formData,
         [name]: value,
      })
   }

   useEffect(() => {
      if (campaignTemplates) {
         setFormData(fd => ({
            ...fd,
            template: Object.keys(campaignTemplates)[0],
         }))
      }
   }, [campaignTemplates, campaignTemplatesError])

   const handleCreate = async () => {
      const isValidate = await handleValidate(setFormErrors, validate)

      if (isValidate) {
         createCampaign({ title: formData.title, template: formData.template, category: 1 })
      }
   }

   useEffect(() => {
      if (campaignCreate && !error) {
         handleCancel();
         clearCampaign();
         handleShowEdit(campaignCreate.id);
         setFormErrors(null)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [campaignCreate, error])

   const handleCancel = () => {
      onHide()
      setFormData({
         title: "",
         template: "1",
      })
      setFormErrors(null)
   }

   return (
      <Modal
         title="Create Campaign"
         subtitle="Enter the internal title of your campaign and select which template you want to use for the campaign."
         show={show}
         onHide={handleCancel}
         handleSubmit={handleCreate}
         btnCreateText="Create campaign"
      >
         <Form.Group className="mb-3">
            <Form.Label>Internal title</Form.Label>
            <Form.Control
               type="text"
               placeholder="Enter internal title"
               onChange={handleChange}
               value={formData.title}
               name="title"
               isInvalid={formErrors && !!formErrors.title}
            />
            <Form.Control.Feedback type="invalid">{formErrors && formErrors.title}</Form.Control.Feedback>
         </Form.Group>
         <Form.Group className="mb-3">
            <Form.Label>Select template</Form.Label>
            <Form.Select aria-label="Default select" onChange={handleChange} value={formData.template} name="template">
               {campaignTemplates &&
                  Object.keys(campaignTemplates).map((item) => {
                     return (
                        <option value={item} key={item}>
                           {campaignTemplates[item]}
                        </option>
                     )
                  })}
            </Form.Select>
         </Form.Group>
         {error && <p className={css.createContent__errorMessage}>Something went wrong</p>}
      </Modal>
   )
}
const mapStateToProps = (state) => {
   return {
      campaignCreate: state.campaignCreate.data,
      fetch: state.campaignCreate.fetching,
      error: state.campaignCreate.error,
      campaignTemplates: state.campaignTemplates.data,
      campaignTemplatesError: state.campaignTemplates.error,
   }
}
export default connect(mapStateToProps, { createCampaign, getCampaignTemplates, clearCampaign })(CreateCampaignModal)
