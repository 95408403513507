import React, { useEffect } from "react"
import { connect } from "react-redux"
import { getCampaigns } from "../../../redux/actions/campaignsActions"
import ItemsList from "../../../components/TableLists/ItemsList"

const dataTitles = [
   {
      name: "Title",
      key: "title",
   },
   {
      name: "Subject",
      key: "subject_line",
   },
   {
      name: "Views",
      key: "viewed_count",
   },
   {
      name: "Clicks",
      key: "clicked_count",
   },
   {
      name: "Leads created",
      key: "leads_count",
   },
   {
      name: "Booked",
      key: "booked_count",
   },
   {
      name: "Performed",
      key: "performed_count",
   },
]

const TabCampaigns = ({ id, getCampaigns, campaigns, fetch, error }) => {
   const getDataByQuery = (sort, page = 1) => {
      getCampaigns(sort, page, null, id)
   }

   useEffect(() => {
      getCampaigns(null, null, null, id)
   }, [getCampaigns, id])

   return (
      <ItemsList
         urlEdit="/campaigns/edit/"
         data={campaigns}
         initialSort="title"
         getDataByQuery={getDataByQuery}
         dataTitles={dataTitles}
         fetch={fetch}
         error={error}
      />
   )
}
const mapStateToProps = (state) => {
   return {
      campaigns: state.campaigns.data,
      fetch: state.campaigns.fetching,
      error: state.campaigns.error,
   }
}
export default connect(mapStateToProps, { getCampaigns })(TabCampaigns)
