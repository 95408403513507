import { Button } from "react-bootstrap"
import logo from "../../assets/images/scania-logo.svg"

import css from "./OnBoarding.module.scss"

const WelcomeMessage = ({ nextStep, adminToken, adminTokenError }) => {
   return (
      <div className={css.onBoarding__welcome}>
         <img src={logo} alt="logo" />
         <h3>Welcome to the Scania Hub Admin!</h3>
         {adminTokenError ? (
            <p>Please, check your email. Your token is invalid</p>
         ) : adminToken ? (
            <>
               <p>
                  You are only a few steps away from getting started with content creation and management of the app,
                  users and customers.
               </p>
               <Button onClick={nextStep}>Next</Button>
            </>
         ) : null}
      </div>
   )
}

export default WelcomeMessage
