import { useRef, useState } from "react"
import { Button, Offcanvas } from "react-bootstrap"
import ContentEditorMain from "./ContentEditorMain"
import axios from "../../utils/axios"
import logo from "../../assets/images/scania-logo.svg"
import LinkDialog from "./LinkDialog"
import VideoModuleDialog from "./VideoModuleDialog"

import css from "./EditorC5.module.scss"

const REQUEST_URL = `${axios.defaults.baseURL}/c5-editor/v1/`

const Editor = ({ data, templateId, title, handleClose, show, htmlData, handleUpdateEditor, ...props }) => {
   const [dataChanged, setDataChanged] = useState(false)

   const [openDialog, setOpenDialog] = useState({
      show: false,
      value: "",
      onApply: null,
      onCancel: null,
   })
   const [openDialogVideo, setOpenDialogVideo] = useState({
      show: false,
      value: "",
      onApply: null,
      onCancel: null,
   })

   const editorMyRef = useRef()

   const onChangeContent = () => {
      setDataChanged(true)
   }

   const handleDiscard = () => {
      editorMyRef.current.replaceContentData(JSON.parse(htmlData))
      setDataChanged(false)
   }

   const handleSave = () => {
      handleUpdateEditor(editorMyRef.current.getSerializedData())
      setDataChanged(false)
   }

   const openLinkDialog = (done, cancel, uri) => {
      setOpenDialog({
         show: true,
         value: uri,
         onApply: (value) => {
            done(value)
         },
         onCancel: () => {
            cancel()
         },
      })
   }

   const closeLinkDialog = () => {
      setOpenDialog({
         show: false,
         value: "",
         onApply: null,
         onCancel: null,
      })
   }

   const openVideoDialog = (done, cancel) => {
      setOpenDialogVideo({
         show: true,
         value: "",
         onApply: (value) => {
            done(value)
         },
         onCancel: () => {
            cancel()
         },
      })
   }

   const closeVideoDialog = () => {
      setOpenDialogVideo({
         show: false,
         value: "",
         onApply: null,
         onCancel: null,
      })
   }

   return (
      <>
         <Offcanvas show={show} onHide={handleClose} placement="end" backdrop={false} {...props}>
            <div className={css.createContent__editor}>
               <div className={css.createContent__editor_header}>
                  <div className={css.createContent__editor_container}>
                     <div className={css.createContent__editor_left}>
                        <img src={logo} alt="logo" />
                        <h3>{title}</h3>
                     </div>
                     <div className={css.createContent__editor_right}>
                        {dataChanged ? (
                           <>
                              <Button variant="outline-secondary" onClick={handleDiscard}>
                                 Discard
                              </Button>
                              <Button onClick={handleSave}>Save</Button>
                           </>
                        ) : (
                           <Button onClick={handleClose}>Close</Button>
                        )}
                     </div>
                  </div>
               </div>

               <ContentEditorMain
                  contentData={htmlData && htmlData.length > 0 ? JSON.parse(htmlData) : ""}
                  editorConfig={data.editorConfig}
                  languageCode={data.languageCode}
                  themeOptions={data.themeOptions}
                  baseRequestUrl={REQUEST_URL}
                  requestHeaders={""}
                  onChange={onChangeContent}
                  onEditorReady={() => console.log("ready")}
                  onOpenLinkDialog={openLinkDialog}
                  onOpenExternalMediaDialog={openVideoDialog}
                  saveRef={editorMyRef}
               />
            </div>
         </Offcanvas>
         {openDialog.show && (
            <LinkDialog
               done={openDialog.onApply}
               cancel={openDialog.onCancel}
               uri={openDialog.value}
               show={openDialog.show}
               hide={closeLinkDialog}
            />
         )}
         {openDialogVideo.show && (
            <VideoModuleDialog
               done={openDialogVideo.onApply}
               cancel={openDialogVideo.onCancel}
               uri={openDialogVideo.value}
               show={openDialogVideo.show}
               hide={closeVideoDialog}
            />
         )}
      </>
   )
}

export default Editor
