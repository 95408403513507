import { useEffect } from "react"
import { connect } from "react-redux"
import Header from "../../components/Header"
import AnalyticsBlock from "./AnalyticsBlock"
import { getUserData } from "../../redux/actions/userActions"
import { getHomeStatistics } from "../../redux/actions/listingActions"
import css from "./Start.module.scss"

const Start = ({ getUserData, user, getHomeStatistics, statistics }) => {
   useEffect(() => {
      getUserData()
      getHomeStatistics()
   }, [getUserData, getHomeStatistics])

   return (
      <div className={css.start}>
         <Header
            title={`Welcome ${user ? user?.first_name ?? "" : ""}!`}
            subtitle="Here is the latest analytics from the Scania Hub App"
         />

         {statistics && <AnalyticsBlock statistics={statistics} />}
      </div>
   )
}
const mapStateToProps = (state) => {
   return {
      user: state.user.data,
      statistics: state.statistics.data,
   }
}
export default connect(mapStateToProps, { getUserData, getHomeStatistics })(Start)
