import React from "react"
import UserInfoBlock from "../../../components/UserInfoBlock"
import css from "../AdminInfo.module.scss"

const UserInformation = ({ data }) => {
   const renderData = (data) => {
      if (data && Object.keys(data).length > 0) {
         const arr = [
            {
               key: "First name",
               value: data.first_name ? data.first_name : "",
            },
            {
               key: "Last name",
               value: data.last_name ? data.last_name : "",
            },
            {
               key: "Email",
               value: data.email ? data.email : "",
            },
            {
               key: "Phone",
               value: data.phone_number ? data.phone_number : "-",
            },
            {
               key: "Country",
               value: data.country ? data.country : "-",
            },
            {
               key: "Company",
               value: data.company ? data.company : "-",
            },
         ]
         return arr
      }
      return
   }

   const user = renderData(data)

   return (
      <UserInfoBlock title="User Information">
         <div className={css.userInfo}>
            {user &&
               user.map((item) => {
                  return (
                     <div className={css.userInfo__item} key={item.key}>
                        <h3>{item.key}</h3>
                        <p>{item.value}</p>
                     </div>
                  )
               })}
         </div>
      </UserInfoBlock>
   )
}
export default UserInformation
