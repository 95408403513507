import { useEffect, useState } from "react"
import clx from "classnames"
import { Table, Button, Spinner } from "react-bootstrap"
import { ArrowDownIcon, ArrowUpIcon } from "../../assets/icons"
import Pagination from "../Pagination"
import history from "../../history"

import css from "./TableLists.module.scss"
import { getStatus } from "../../utils/utils"

const mockDataTitles = [
   {
      name: "Title",
      key: "title",
   },
   {
      name: "Views",
      key: "viewed_count",
      default: 0,
   },
   {
      name: "Published",
      key: "publishedDate",
   },
   {
      name: "Published by",
      key: "author",
   },
   {
      name: "Status",
      key: "status",
   },
]

const mockData = [
   {
      title: "All-​electric deliveries for Sweden",
      viewed_count: "125",
      publishedDate: "2021-07-22   14:36",
      author: "Andrew Smith",
      status: "Published",
   },
   {
      title: "EU confirms Scania fuel efficiency leadership",
      viewed_count: "0",
      publishedDate: "2021-07-18   12:02",
      author: "Melanie Cox",
      status: "Draft",
   },
   {
      title: "The company’s spirit made her choose Scania",
      viewed_count: "10",
      publishedDate: "2021-06-30   16:15",
      author: "Timmothy Cooper",
      status: "Unpublished",
   },
   {
      title: "Working together for change in sustainable transpor",
      viewed_count: "248",
      publishedDate: "Published",
      author: "Daniel Rose",
      status: "Published",
   },
   {
      title: "Milestone – half a million vehicles connected",
      viewed_count: "0",
      publishedDate: "Published",
      author: "Andrew Smith",
      status: "Published",
   },
   {
      title: "All-​electric deliveries for Sweden",
      viewed_count: "125",
      publishedDate: "2021-07-22   14:36",
      author: "Andrew Smith",
      status: "Published",
   },
   {
      title: "EU confirms Scania fuel efficiency leadership",
      viewed_count: "0",
      publishedDate: "2021-07-18   12:02",
      author: "Melanie Cox",
      status: "Draft",
   },
   {
      title: "The company’s spirit made her choose Scania",
      viewed_count: "10",
      publishedDate: "2021-06-30   16:15",
      author: "Timmothy Cooper",
      status: "Unpublished",
   },
   {
      title: "Working together for change in sustainable transpor",
      viewed_count: "248",
      publishedDate: "Published",
      author: "Daniel Rose",
      status: "Published",
   },
]

let PageSize = 1

const ItemsList = ({
   urlEdit,
   data = mockData,
   dataTitles = mockDataTitles,
   fetch,
   error,
   getDataByQuery,
   initialSort = "publishedDate",
}) => {
   const [page, setPage] = useState({
      pageSize: data === mockData ? PageSize : data?.length ?? 9,
      pageCount: data?.length ?? 1,
      currentPage: 1,
   })

   useEffect(() => {
      if (data && data !== mockData) {
         setPage({
            pageSize: data.per_page,
            pageCount: data.total,
            currentPage: data.current_page,
         })
      }
   }, [data])

   // TODO: Replace after connecting all pages

   const [sortConfig, setSortConfig] = useState({
      direction: "asc",
      key: initialSort,
   })

   const requestSort = (key) => {
      let direction = "asc"
      if (sortConfig.key === key && sortConfig.direction === "asc") {
         direction = "desc"
      }
      setSortConfig({ key, direction })
      if (getDataByQuery) {
         getDataByQuery({ key, direction }, data.currentPage)
      }
   }

   const getClassNamesFor = (name) => {
      if (!sortConfig) {
         return
      }
      return sortConfig.key === name ? `btn-icon_active` : undefined
   }

   const getIconDirection = (name) => {
      if (!sortConfig) {
         return
      }
      return sortConfig.key === name ? (
         sortConfig.direction === "asc" ? (
            <ArrowDownIcon />
         ) : (
            <ArrowUpIcon />
         )
      ) : (
         <ArrowDownIcon />
      )
   }

   const handlePageChange = (currentPage) => {
      setPage({ ...page, currentPage })

      if (getDataByQuery) {
         getDataByQuery(sortConfig, currentPage)
      }
   }

   const getRow = (item, key, def) => {
      switch (key) {
         case "status":
            return (
               <td key={key}>
                  <span className={clx(css.items__status, css[`items__status_${item.status ?? 0}`])} />
                  {getStatus(item.status)}
               </td>
            )
         case "published_by":
            return <td key={key}>{item.publisher_name ?? item[key]}</td>
         case "published_at":
         case "created_at":
            return <td key={key}>{item[key] && new Date(item[key]).toLocaleString()}</td>
         default:
            return <td key={key}>{item[key] ?? def}</td>
      }
   }

   return (
      <div className={css.items__list}>
         <Table size="sm">
            <thead>
               <tr>
                  {dataTitles.map((item) => {
                     return (
                        <th key={item.name}>
                           {item.name}{" "}
                           <Button
                              variant="icon"
                              onClick={() => requestSort(item.key)}
                              className={getClassNamesFor(item.key)}
                           >
                              {getIconDirection(item.key)}
                           </Button>
                        </th>
                     )
                  })}
               </tr>
            </thead>
            <tbody>
               {fetch ? (
                  <tr>
                     <td colSpan={dataTitles.length} align={"center"}>
                        <Spinner animation="border" variant="primary" className={css.inviteFetching__spinner} />
                     </td>
                  </tr>
               ) : error ? (
                  <tr>
                     <td align={"center"} colSpan={dataTitles.length}>
                        Something went wrong
                     </td>
                  </tr>
               ) : data && (data.data?.length ?? data.length) > 0 ? (
                  (data?.data ?? data).map((item, index) => {
                     return (
                        <tr key={index} onClick={() => history.push(`${urlEdit}${item?.id ?? index}`)}>
                           {dataTitles.map(({ key, default: def }) => getRow(item, key, def))}
                        </tr>
                     )
                  })
               ) : (
                  <tr>
                     <td align={"center"} colSpan={dataTitles.length}>
                        No data to show
                     </td>
                  </tr>
               )}
            </tbody>
         </Table>

         <Pagination
            className="pagination-bar"
            currentPage={page.currentPage}
            totalCount={page.pageCount}
            pageSize={page.pageSize}
            onPageChange={(page) => handlePageChange(page)}
         />
      </div>
   )
}
export default ItemsList
