import { Switch } from "react-router-dom"
import { PublicRoute, PrivateRoute } from "./routes"
import MainLayout from "./layouts/MainLayout"
import LoginLayout from "./layouts/LoginLayout"
import OnBoardingLayout from "./layouts/OnBoardingLayout"

import Start from "./pages/Start"
import Login from "./pages/Login"
import Campaigns from "./pages/Campaigns"
import Newsfeed from "./pages/Newsfeed"
import CampaignsEdit from "./pages/CampaignsEdit"
import NewsfeedEdit from "./pages/NewsfeedEdit"
import Movies from "./pages/Movies"
import MoviesEdit from "./pages/MoviesEdit"
import Users from "./pages/Users"
import UserInfo from "./pages/UserInfo"
import Admins from "./pages/Admins"
import AdminInfo from "./pages/AdminInfo"
import Sustainability from "./pages/Sustainability"
import SustainabilityEdit from "./pages/SustainabilityEdit"
import Surveys from "./pages/Surveys"
import SurveysEdit from "./pages/SurveysEdit"
import OnBoarding from "./pages/OnBoarding"
import Analytics from "./pages/Analytics"
import PageNotFound from "./pages/PageNotFound"

import "bootstrap/dist/css/bootstrap.min.css"
import "./styles/main.scss"

const App = () => {
   return (
      <>
         <Switch>
            <PublicRoute exact path="/login" component={Login} layout={LoginLayout} />
            <PublicRoute exact path="/boarding" component={OnBoarding} layout={OnBoardingLayout} />
            <PrivateRoute exact path="/" component={Start} layout={MainLayout} />
            <PrivateRoute exact path="/campaigns" component={Campaigns} layout={MainLayout} />
            <PrivateRoute exact path="/campaigns/edit" component={CampaignsEdit} layout={MainLayout} />
            <PrivateRoute exact path="/campaigns/edit/:id" component={CampaignsEdit} layout={MainLayout} />
            <PrivateRoute exact path="/newsfeed" component={Newsfeed} layout={MainLayout} />
            <PrivateRoute exact path="/newsfeed/edit" component={NewsfeedEdit} layout={MainLayout} />
            <PrivateRoute exact path="/newsfeed/edit/:id" component={NewsfeedEdit} layout={MainLayout} />
            <PrivateRoute exact path="/movies" component={Movies} layout={MainLayout} />
            <PrivateRoute exact path="/movies/edit" component={MoviesEdit} layout={MainLayout} />
            <PrivateRoute exact path="/movies/edit/:id" component={MoviesEdit} layout={MainLayout} />
            <PrivateRoute exact path="/users" component={Users} layout={MainLayout} />
            <PrivateRoute exact path="/users/:id" component={UserInfo} layout={MainLayout} />
            <PrivateRoute exact path="/admins" component={Admins} layout={MainLayout} />
            <PrivateRoute exact path="/admins/:id" component={AdminInfo} layout={MainLayout} />
            <PrivateRoute exact path="/sustainability" component={Sustainability} layout={MainLayout} />
            <PrivateRoute exact path="/sustainability/edit/:id" component={SustainabilityEdit} layout={MainLayout} />
            <PrivateRoute exact path="/surveys" component={Surveys} layout={MainLayout} />
            <PrivateRoute exact path="/surveys/edit/:id" component={SurveysEdit} layout={MainLayout} />
            <PrivateRoute exact path="/analytics" component={Analytics} layout={MainLayout} />
            <PrivateRoute component={PageNotFound} layout={MainLayout} />
         </Switch>
      </>
   )
}

export default App
