import React, { forwardRef } from "react"
import clx from "classnames"

import css from "./Accordion.module.scss"

export const CustomInputDate = forwardRef(({ value, onClick, placeholderText, disabled }, ref) => (
   <button className={clx(css.customInput, disabled ? css.customInput_disable : "")} onClick={onClick} ref={ref}>
      {value ? value : placeholderText}
   </button>
))
export const CustomInputTime = forwardRef(({ value, onClick, placeholderText, disabled }, ref) => (
   <button className={clx(css.customInput, disabled ? css.customInput_disable : "")} onClick={onClick} ref={ref}>
      {value ? value : placeholderText}
   </button>
))
