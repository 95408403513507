import React from "react"
import css from "./UserInfoBlock.module.scss"

const UserInfoBlock = ({ title, children }) => {
   return (
      <div className={css.userBlock}>
         <h3>{title}</h3>
         {children}
      </div>
   )
}
export default UserInfoBlock
