import React from "react"
import { Accordion } from "react-bootstrap"
import Calendar from "./Calendar"
import css from "./Accordion.module.scss"

const AccordionCalendar = ({ handleSaveScheduling, data }) => {
   return (
      <div className={css.accordionBlock}>
         <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
               <Accordion.Header className={css.accordionBlock__header}>
                  <h3>Scheduling</h3>
               </Accordion.Header>
               <Accordion.Body className={css.accordionBlock__body}>
                  <Calendar handleSaveScheduling={handleSaveScheduling} schedule={data} />
               </Accordion.Body>
            </Accordion.Item>
         </Accordion>
      </div>
   )
}
export default AccordionCalendar
