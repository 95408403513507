import React from "react"
import UsersHeaderInfo from "../UsersHeaderInfo"
import css from "./Header.module.scss"

const Header = ({ title, subtitle = false, userInfoActive = null, children }) => {
   return (
      <header className={css.header}>
         <div>
            <h1>{title}</h1>
            {subtitle && <p>{subtitle}</p>}
         </div>
         {userInfoActive && <UsersHeaderInfo data={userInfoActive} />}
         <div className={css.header__btns}>{children}</div>
      </header>
   )
}
export default Header
