import { useState, useEffect } from "react"
import clx from "classnames"
import { Table, Button } from "react-bootstrap"
import { ArrowDownIcon, ArrowUpIcon } from "../../assets/icons"
import Pagination from "../Pagination"

import css from "./TableLists.module.scss"

const VehiclesList = ({ data, getDataByQuery, dataTitles }) => {
   const [page, setPage] = useState({
      pageSize: 10,
      pageCount: 1,
      currentPage: 1,
   })
   const [sortConfig, setSortConfig] = useState({
      direction: "asc",
      key: dataTitles[0].key,
   })

   useEffect(() => {
      if (data) {
         setPage({
            pageSize: data.per_page,
            pageCount: data.total,
            currentPage: data.current_page,
         })
      }
   }, [data])

   const requestSort = (key) => {
      let direction = "asc"
      if (sortConfig.key === key && sortConfig.direction === "asc") {
         direction = "desc"
      }

      setSortConfig({ key, direction })
      getDataByQuery({ key, direction })
   }

   const getClassNamesFor = (name) => {
      if (!sortConfig) {
         return
      }
      return sortConfig.key === name ? `btn-icon_active` : undefined
   }

   const getIconDirection = (name) => {
      if (!sortConfig) {
         return
      }
      return sortConfig.key === name ? (
         sortConfig.direction === "desc" ? (
            <ArrowDownIcon />
         ) : (
            <ArrowUpIcon />
         )
      ) : (
         <ArrowDownIcon />
      )
   }

   const handlePageChange = (pageNum) => {
      setPage({
         ...page,
         currentPage: pageNum,
      })
      getDataByQuery(sortConfig, pageNum)
   }

   //console.log(page)

   return (
      <div className={clx(css.items__list, css.items__list_mb)}>
         <Table size="sm">
            <thead>
               <tr>
                  {dataTitles.map((item) => {
                     return (
                        <th key={item.name}>
                           {item.name}{" "}
                           <Button
                              variant="icon"
                              onClick={() => requestSort(item.key)}
                              className={getClassNamesFor(item.key)}
                           >
                              {getIconDirection(item.key)}
                           </Button>
                        </th>
                     )
                  })}
               </tr>
            </thead>
            <tbody>
               {data && data.data.length > 0 ? (
                  data.data.map((item) => {
                     return (
                        <tr key={item.id}>
                           <td>{item[dataTitles[0].key]}</td>
                           <td>{item[dataTitles[1].key] ? item[dataTitles[1].key] : "-"}</td>
                           <td>{item[dataTitles[2].key]}</td>
                           <td>{item[dataTitles[3].key]}</td>
                           <td></td>
                        </tr>
                     )
                  })
               ) : (
                  <tr>
                     <td>No data to show</td>
                  </tr>
               )}
            </tbody>
         </Table>
         {data && data.data.length > 0 && (
            <Pagination
               className="pagination-bar"
               currentPage={page.currentPage}
               totalCount={page.pageCount}
               pageSize={page.pageSize}
               onPageChange={(page) => handlePageChange(page)}
            />
         )}
      </div>
   )
}
export default VehiclesList
