import { useEffect, useState } from "react"
import CreateBtnHeader from "../../components/CreateBtnHeader"
import Header from "../../components/Header"
import SearchBar from "../../components/SearchBar"
import CreatePostModal from "./CreatePostModal"
import ItemsList from "../../components/TableLists/ItemsList"
import { connect } from "react-redux"
import { getNews } from "../../redux/actions/listingActions"

const dataTitles = [
   {
      name: "Title",
      key: "title",
   },
   {
      name: "Subject",
      key: "subject_line",
   },
   {
      name: "Views",
      key: "viewed_count",
      default: 0,
   },
   {
      name: "Published",
      key: "published_at",
   },
   {
      name: "Published by",
      key: "published_by",
   },
   {
      name: "Status",
      key: "status",
      default: "Draft",
   },
]

const Newsfeed = ({ getNews, data, fetch, error }) => {
   const [modalShow, setModalShow] = useState(false)

   useEffect(() => {
      getNews()
   }, [getNews])

   const handleSearch = (inputValue) => {
      getNews(null, 1, inputValue)
   }
   const getDataByQuery = (sort, page = 1) => {
      getNews(sort, page)
   }

   return (
      <>
         <Header title="Newsfeed">
            <SearchBar handleSearch={handleSearch} />
            <CreateBtnHeader title="Create post" clickHandler={() => setModalShow(true)} />
         </Header>

         <ItemsList
            urlEdit="/newsfeed/edit/"
            initialSort={"title"}
            data={data}
            fetch={fetch}
            error={error}
            getDataByQuery={getDataByQuery}
            dataTitles={dataTitles}
         />

         <CreatePostModal show={modalShow} onHide={() => setModalShow(false)} />
      </>
   )
}
const mapStateToProps = (state) => ({
   data: state.news.data,
   fetch: state.news.fetching,
   error: state.news.error,
})

export default connect(mapStateToProps, { getNews })(Newsfeed)
