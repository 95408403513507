import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import {
   getSustainabilityById,
   clearSustainabilityById,
   deleteSustainability,
   duplicateSustainability,
   setSustainabilityStatus,
   updateSustainability,
} from "../../redux/actions/listingActions"
import { schedulingCampaign } from "../../redux/actions/campaignsActions"
import { getCampaignTemplateData } from "../../redux/actions/c5editorActions"
import TooltipModal from "../CampaignsEdit/TooltipModal"
import { getStatus, getStatusToChange } from "../../utils/utils"
import HeaderEditPage from "../../components/HeaderEditPage"
import CreateSettings from "./CreateSettings"
import CreateSegmentation from "../../components/CreateSegmentation"
import PublishModal from "./PublishModal"
import EditorC5 from "../../components/EditorC5"
import Analytics from "../../components/Analytics"
import AccordionCalendar from "../../components/AccordionCalendar"
import history from "../../history"
import css from "./SustainabilityEdit.module.scss"

const SustainabilityEdit = ({
   data,
   getSustainabilityById,
   clearSustainabilityById,
   duplicateSustainability,
   deleteSustainability,
   setSustainabilityStatus,
   updateSustainability,
   getCampaignTemplateData,
   campaignTemplateData,
   campaignTemplateError,
   schedulingCampaign,
}) => {
   const { id } = useParams()
   const [showModalPublish, setModalShowPublish] = useState(false)

   const [modalTooltip, setModalTooltip] = useState({
      type: null,
      show: false,
   })

   const handleShowModalTooltip = (type) => {
      setModalTooltip({
         type,
         show: true,
      })
   }

   useEffect(() => {
      getSustainabilityById(id)
      return () => {
         clearSustainabilityById()
      }
   }, [id, getSustainabilityById, clearSustainabilityById])

   useEffect(() => {
      if (data && data.template) {
         getCampaignTemplateData(data.template)
      }
   }, [data, getCampaignTemplateData])

   const handleCloseTooltipModal = useCallback(() => {
      setModalTooltip({ show: false, type: null })
   }, [])

   const handleDeleteSustainability = useCallback(async () => {
      await deleteSustainability(id)
      handleCloseTooltipModal()
   }, [id, deleteSustainability, handleCloseTooltipModal])

   const handleDuplicateSustainability = useCallback(async () => {
      await duplicateSustainability(id)
      handleCloseTooltipModal()
   }, [id, duplicateSustainability, handleCloseTooltipModal])

   const handleSubmitTooltip = useCallback(() => {
      if (modalTooltip.type === "delete") {
         return handleDeleteSustainability()
      }
      return handleDuplicateSustainability()
   }, [handleDeleteSustainability, handleDuplicateSustainability, modalTooltip.type])

   const handlePublish = async () => {
      await setSustainabilityStatus({
         sustainability_id: id,
         status: getStatusToChange(data?.status),
      })
      setModalShowPublish(false)
   }

   const handleUpdateEditor = (data) => {
      updateSustainability({
         id,
         html_editor: JSON.stringify(data),
      })
   }

   const handleUpdate = async (data) => {
      await updateSustainability({
         id,
         ...data,
      })
   }

   const getActionLabel = () => {
      switch (data?.status) {
         case 2:
            return "Republish post"
         case 1:
            return "Unpublish post"
         case 0:
         default:
            return "Publish post"
      }
   }
   const checkDisableBtn = () => {
      let disable = true
      if (data) {
         const { html_editor, title, subject_line, intro_text } = data
         if (html_editor && title && subject_line && intro_text) {
            disable = false
         }
      }
      return disable
   }

   const analyticsData = [
      {
         name: "Views",
         value: data?.viewed_count,
      },
      {
         name: "Clicks",
         value: data?.clicked_count,
      },
   ]
   const handleSaveScheduling = (data) => {
      const dataSend = {
         content_id: id,
         content_type: "sustainability",
         publish: data.publish,
         publish_at: data.publish_at,
         unpublish: data.unpublish,
         unpublish_at: data.unpublish_at,
      }
      schedulingCampaign(dataSend)
   }

   const renderScheduleStatus = () => {
      if (data && data.schedule) {
         const dateNow = new Date().toISOString()
         const { publish, publish_at, unpublish, unpublish_at } = data.schedule
         const datePublish = new Date(publish_at).toISOString()
         const dateUnpublish = new Date(unpublish_at).toISOString()

         if (publish && dateNow < datePublish) {
            return "green"
         } else if (unpublish && dateNow < dateUnpublish) {
            return "red"
         }
      }
      return null
   }
   return (
      <>
         <div className={css.campaignsEdit}>
            <HeaderEditPage
               title={data?.title ?? ""}
               handleTooltipModal={handleShowModalTooltip}
               status={getStatus(data?.status)}
               recipients={data?.recipients_count ?? 0}
               handlePublish={() => setModalShowPublish(true)}
               handleGoBack={() => history.push("/sustainability/")}
               actionVariant={data?.status === 1 ? "warning" : undefined}
               btnText={getActionLabel()}
               disabledBtn={checkDisableBtn()}
               schedule={data && renderScheduleStatus()}
            />
            {data && <Analytics data={analyticsData} />}
            <div className={css.campaignsEdit__row}>
               <div className={css.campaignsEdit__left}>
                  <EditorC5
                     templateId={data?.template}
                     title={data?.title}
                     htmlData={data?.html_editor}
                     postImg={data?.post_img}
                     data={campaignTemplateData}
                     error={campaignTemplateError}
                     handleUpdateEditor={handleUpdateEditor}
                  />
               </div>
               <div className={css.campaignsEdit__right}>{data && <CreateSettings data={data} />}</div>
            </div>
            {data && <AccordionCalendar handleSaveScheduling={handleSaveScheduling} data={data.schedule} />}
            {data && <CreateSegmentation data={data} onUpdate={handleUpdate} />}
         </div>
         <PublishModal
            show={showModalPublish}
            onHide={() => setModalShowPublish(false)}
            status={getStatus(data?.status)}
            handleSubmit={handlePublish}
         />
         <TooltipModal
            title={"post"}
            show={modalTooltip.show}
            type={modalTooltip.type}
            onHide={handleCloseTooltipModal}
            handleSubmit={handleSubmitTooltip}
         />
      </>
   )
}

const mapStateToProps = (state) => ({
   data: state.sustainabilityById.data,
   campaignTemplateData: state.campaignTemplateData.data,
   campaignTemplateError: state.campaignTemplateData.error,
})

export default connect(mapStateToProps, {
   getSustainabilityById,
   clearSustainabilityById,
   updateSustainability,
   deleteSustainability,
   duplicateSustainability,
   setSustainabilityStatus,
   getCampaignTemplateData,
   schedulingCampaign,
})(SustainabilityEdit)
