const initialState = {
   fetching: false,
   data: {},
   error: false,
   errorUpdate: false,
}

export default function user(params) {
   return (state = initialState, action = {}) => {
      switch (action.type) {
         case `data/${params}/REQUEST`:
            return {
               ...state,
               fetching: true,
            }
         case `data/${params}/SUCCESS`:
            return {
               ...state,
               fetching: false,
               error: false,
               data: action.payload,
               errorUpdate: false,
            }
         case `data/${params}/FAILURE`:
            return {
               ...state,
               fetching: false,
               error: action.payload,
               errorUpdate: false,
            }
         case `data/${params}/UPDATE_REQUEST`:
            return {
               ...state,
               fetching: true,
               error: false,
               errorUpdate: false,
            }
         case `data/${params}/UPDATE_SUCCESS`:
            return {
               ...state,
               fetching: false,
               error: false,
               data: action.payload,
               errorUpdate: false,
            }
         case `data/${params}/UPDATE_FAILURE`:
            return {
               ...state,
               fetching: false,
               error: false,
               errorUpdate: action.payload,
            }

         default:
            return state
      }
   }
}
