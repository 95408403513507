import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import {
   getCampaignById,
   updateCampaign,
   deleteCampaign,
   setCampaignStatus,
   duplicateCampaign,
   schedulingCampaign,
} from "../../redux/actions/campaignsActions"
import { getCampaignTemplateData } from "../../redux/actions/c5editorActions"
import HeaderEditPage from "../../components/HeaderEditPage"
import CreateSettings from "./CreateSettings"
import CreateSegmentation from "../../components/CreateSegmentation"
import PublishModal from "./PublishModal"
import TooltipModal from "./TooltipModal"
import Analytics from "../../components/Analytics"
import EditorC5 from "../../components/EditorC5"
import { getStatus, getStatusToChange } from "../../utils/utils"
import AccordionCalendar from "../../components/AccordionCalendar"
import history from "../../history"
import css from "./CampaignsEdit.module.scss"

const CampaignsEdit = ({
   getCampaignById,
   campaign,
   updateCampaign,
   deleteCampaign,
   setCampaignStatus,
   duplicateCampaign,
   getCampaignTemplateData,
   campaignTemplateData,
   campaignTemplateError,
   schedulingCampaign,
}) => {
   const { id } = useParams()
   const [showModalPublish, setModalShowPublish] = useState(false)
   const [modalTooltip, setModalTooltip] = useState({
      type: null,
      show: false,
   })

   const handleShowModalTooltip = (type) => {
      setModalTooltip({
         type,
         show: true,
      })
   }
   useEffect(() => {
      getCampaignById(id)
   }, [id, getCampaignById])
   //console.log("cam", campaign)

   useEffect(() => {
      if (campaign && campaign.template) {
         getCampaignTemplateData(campaign.template)
      }
   }, [campaign, getCampaignTemplateData])

   const handleUpdate = async (data) => {
      await updateCampaign({
         ...data,
         id,
      })
   }

   const handleUpdateEditor = (data) => {
      updateCampaign({
         id,
         title: campaign.title,
         html_editor: JSON.stringify(data),
      })
   }

   const getActionLabel = () => {
      switch (campaign?.status) {
         case 2:
            return "Republish campaign"
         case 1:
            return "Unpublish campaign"
         case 0:
         default:
            return "Publish campaign"
      }
   }

   const handlePublish = async () => {
      await setCampaignStatus({
         campaign_id: id,
         status: getStatusToChange(campaign?.status),
      })
      setModalShowPublish(false)
   }

   const handleDuplicate = async () => {
      await duplicateCampaign(id)
      setModalTooltip({ show: false, type: null })
   }

   const checkDisableBtn = () => {
      let disable = true
      if (campaign) {
         const { html_editor, title, subject_line, intro_text } = campaign
         if (html_editor && title && subject_line && intro_text) {
            disable = false
         }
      }
      return disable
   }

   const analyticsData = [
      {
         name: "Views",
         value: campaign?.viewed_count,
      },
      {
         name: "Clicks",
         value: campaign?.clicked_count,
      },
      {
         name: "Leads created",
         value: campaign?.leads_count,
      },
      {
         name: "Booked",
         value: campaign?.booked_count,
      },
      {
         name: "Performed",
         value: campaign?.performed_count,
      },
   ]

   const handleSaveScheduling = (data) => {
      console.log(data)

      const dataSend = {
         content_id: id,
         content_type: "campaign",
         publish: data.publish,
         publish_at: data.publish_at,
         unpublish: data.unpublish,
         unpublish_at: data.unpublish_at,
      }
      schedulingCampaign(dataSend)
   }

   const renderScheduleStatus = () => {
      if (campaign && campaign.schedule) {
         const dateNow = new Date().toISOString()
         const { publish, publish_at, unpublish, unpublish_at } = campaign.schedule
         const datePublish = new Date(publish_at).toISOString()
         const dateUnpublish = new Date(unpublish_at).toISOString()

         if (publish && dateNow < datePublish) {
            return "green"
         } else if (unpublish && dateNow < dateUnpublish) {
            return "red"
         }
      }
      return null
   }

   return (
      <>
         <div className={css.campaignsEdit}>
            <HeaderEditPage
               title={campaign && campaign.title}
               status={getStatus(campaign?.status)}
               recipients={campaign?.recipients_count ?? 0}
               handlePublish={() => setModalShowPublish(true)}
               handleTooltipModal={handleShowModalTooltip}
               handleGoBack={() => history.push("/campaigns")}
               actionVariant={campaign?.status === 1 ? "warning" : undefined}
               btnText={getActionLabel()}
               disabledBtn={checkDisableBtn()}
               schedule={campaign && renderScheduleStatus()}
            />
            {campaign && <Analytics data={analyticsData} />}

            <div className={css.campaignsEdit__row}>
               <div className={css.campaignsEdit__left}>
                  {campaign && (
                     <EditorC5
                        templateId={campaign.template}
                        title={campaign.title}
                        htmlData={campaign.html_editor}
                        postImg={campaign.post_img}
                        data={campaignTemplateData}
                        error={campaignTemplateError}
                        handleUpdateEditor={handleUpdateEditor}
                     />
                  )}
               </div>
               <div className={css.campaignsEdit__right}>
                  {campaign && <CreateSettings data={campaign} update={handleUpdate} />}
               </div>
            </div>
            {campaign && <AccordionCalendar handleSaveScheduling={handleSaveScheduling} data={campaign.schedule} />}

            {campaign && <CreateSegmentation data={campaign} onUpdate={handleUpdate} />}
         </div>
         <PublishModal
            show={showModalPublish}
            onHide={() => setModalShowPublish(false)}
            status={getStatus(campaign?.status)}
            handleSubmit={handlePublish}
         />
         <TooltipModal
            show={modalTooltip.show}
            type={modalTooltip.type}
            onHide={() => setModalTooltip({ show: false, type: null })}
            handleSubmit={modalTooltip.type === "delete" ? () => deleteCampaign(id) : handleDuplicate}
         />
      </>
   )
}
const mapStateToProps = (state) => {
   return {
      campaign: state.campaign.data,
      fetch: state.campaign.fetching,
      error: state.campaign.error,
      campaignTemplateData: state.campaignTemplateData.data,
      campaignTemplateError: state.campaignTemplateData.error,
   }
}
export default connect(mapStateToProps, {
   getCampaignById,
   updateCampaign,
   deleteCampaign,
   setCampaignStatus,
   duplicateCampaign,
   getCampaignTemplateData,
   schedulingCampaign,
})(CampaignsEdit)
