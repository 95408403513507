import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { getAnalyticsTop } from "../../redux/actions/analyticsActions"
import { Form } from "react-bootstrap"
import AnalyticsTop from "../../components/TableLists/AnalyticsTop"
import css from "./Analytics.module.scss"

const TableTop10 = ({ getAnalyticsTop, data, dataFetch, dataError }) => {
   const [select, setSelect] = useState({
      type: "campaign",
      period: "week",
   })

   useEffect(() => {
      getAnalyticsTop()
   }, [getAnalyticsTop])

   const dataTitles = [
      {
         name: "#",
         key: "index",
      },
      {
         name: "Title",
         key: "title",
      },
      {
         name: "Recipients",
         key: "recipients_count",
      },
      {
         name: select.type === "survey" ? "Opened" : "Views",
         key: "viewed_count",
      },
      {
         name: select.type === "survey" ? "Discarded" : "Clicks",
         key: "clicked_count",
      },
      {
         name: "Leads",
         key: "leads_count",
      },
      {
         name: "Appointments",
         key: "booked_count",
      },
      {
         name: "Performed",
         key: "performed_count",
      },
      {
         name: "Status",
         key: "status",
      },
   ]

   const handleChange = (e) => {
      const { name, value } = e.target

      setSelect({
         ...select,
         [name]: value,
      })
   }

   useEffect(() => {
      getAnalyticsTop(select.type, select.period)
   }, [select, getAnalyticsTop])

   return (
      <div className={css.analytics__content}>
         <div className={css.analytics__header}>
            <h2>Top-10</h2>
            <div className={css.analytics__header_selects}>
               <Form.Select onChange={handleChange} value={select.type} name="type">
                  <option value="campaign">Campaigns</option>
                  <option value="newsfeed">Newsfeed</option>
                  <option value="sustainability">Sustainability</option>
                  <option value="movie">Movies</option>
                  <option value="survey">Surveys</option>
               </Form.Select>
               <Form.Select onChange={handleChange} value={select.period} name="period">
                  <option value="week">Last week</option>
                  <option value="month">Last month</option>
                  <option value="all">All time</option>
               </Form.Select>
            </div>
         </div>

         <AnalyticsTop data={data} fetch={dataFetch} error={dataError} dataTitles={dataTitles} url={select.type} />
      </div>
   )
}
const mapStateToProps = (state) => {
   return {
      data: state.analyticsTop.data,
      dataFetch: state.analyticsTop.fetching,
      dataError: state.analyticsTop.error,
   }
}
export default connect(mapStateToProps, { getAnalyticsTop })(TableTop10)
