import React from "react"
import clx from "classnames"
import { NavLink } from "react-router-dom"
import Logo from "../../../assets/images/scania-logo.svg"
import {
   StartIcon,
   AdminIcon,
   AnalyticsIcon,
   MoviesIcon,
   NewsfeedIcon,
   SurveysIcon,
   UsersIcon,
   CampaignsIcon,
   SustainabilityIcon,
} from "../../../assets/icons"
import css from "../Sidebar.module.scss"

const links = [
   {
      name: "Start",
      icon: <StartIcon />,
      url: "",
   },
   {
      name: "Campaigns",
      icon: <CampaignsIcon />,
      url: "/campaigns",
   },
   {
      name: "Newsfeed",
      icon: <NewsfeedIcon />,
      url: "/newsfeed",
   },
   {
      name: "Sustainability",
      icon: <SustainabilityIcon />,
      url: "/sustainability",
   },
   {
      name: "Movies",
      icon: <MoviesIcon />,
      url: "/movies",
   },
   {
      name: "Surveys",
      icon: <SurveysIcon />,
      url: "/surveys",
   },
   {
      name: "Users",
      icon: <UsersIcon />,
      url: "/users",
   },
   {
      name: "Admins",
      icon: <AdminIcon />,
      url: "/admins",
   },
   {
      name: "Analytics",
      icon: <AnalyticsIcon />,
      url: "/analytics",
   },
]

const Menu = () => {
   return (
      <div className={css.sidebar__inner}>
         <img src={Logo} alt="logo" className={css.sidebar__logo} />
         <nav className={css.sidebar__nav}>
            {links.map((link) => {
               return link.disabled ? (
                  <p className={clx(css.sidebar__nav_link, css.sidebar__nav_link_disabled)} key={link.name}>
                     {link.icon} {link.name}
                  </p>
               ) : (
                  <NavLink
                     to={`${link.url}`}
                     exact={link.name === "Start"}
                     strict
                     activeClassName={css.sidebar__nav_activeLink}
                     className={css.sidebar__nav_link}
                     key={link.name}
                  >
                     {link.icon} {link.name}
                  </NavLink>
               )
            })}
         </nav>
      </div>
   )
}
export default Menu
