import { useState, useEffect } from "react"
import { connect } from "react-redux"
import { getCampaigns } from "../../redux/actions/campaignsActions"
import CreateBtnHeader from "../../components/CreateBtnHeader"
import Header from "../../components/Header"
import SearchBar from "../../components/SearchBar"
import CreateCampaignModal from "./CreateCampaignModal"
import ItemsList from "../../components/TableLists/ItemsList"
import history from "../../history"

const Campaigns = ({ getCampaigns, campaigns, fetch, error }) => {
   const [modalShow, setModalShow] = useState(false)
   useEffect(() => {
      getCampaigns()
   }, [getCampaigns])

   const handleSearch = (inputValue) => {
      getCampaigns(null, 1, inputValue)
   }
   const getDataByQuery = (sort, page = 1) => {
      getCampaigns(sort, page)
   }
   const dataTitles = [
      {
         name: "Title",
         key: "title",
      },
      {
         name: "Subject",
         key: "subject_line",
      },
      {
         name: "Views",
         key: "viewed_count",
      },
      {
         name: "Published",
         key: "published_at",
      },
      {
         name: "Published by",
         key: "published_by",
      },
      {
         name: "Status",
         key: "status",
      },
   ]

   return (
      <>
         <Header title="Campaigns">
            <SearchBar handleSearch={handleSearch} />
            <CreateBtnHeader title="Create campaign" clickHandler={() => setModalShow(true)} />
         </Header>

         <ItemsList
            urlEdit="/campaigns/edit/"
            data={campaigns}
            initialSort="title"
            getDataByQuery={getDataByQuery}
            dataTitles={dataTitles}
            fetch={fetch}
            error={error}
         />
         <CreateCampaignModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            handleShowEdit={(id) => history.push(`/campaigns/edit/${id}`)}
         />
      </>
   )
}
const mapStateToProps = (state) => {
   return {
      campaigns: state.campaigns.data,
      fetch: state.campaigns.fetching,
      error: state.campaigns.error,
   }
}
export default connect(mapStateToProps, { getCampaigns })(Campaigns)
