export const getStatus = (status) => {
   switch (status) {
      case 2:
         return "Unpublished";
      case 1:
         return "Published";
      case 0:
      default:
         return "Draft";
   }
}

export const getNextStatus = (status) => {
   switch (status) {
      case 1:
         return 2;
      case 2:
      case 0:
      default:
         return 1;
   }
}

export const getStatusToChange = (status) => {
   return getStatus(getNextStatus(status))
      .toUpperCase().replace("ED", "");
}