import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { checkAdminToken, adminInvite } from "../../redux/actions/auth"
import WelcomeMessage from "./WelcomeMessage"
import PersonalDetails from "./PersonalDetails"
import CreatePassword from "./CreatePassword"
import Success from "./Success"
import history from "../../history"
import css from "./OnBoarding.module.scss"

const OnBoarding = ({ adminTokenError, adminToken, checkAdminToken, adminInviteError, adminInvite }) => {
   const [data, setData] = useState({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      passwordRepeat: "",
      country: "",
      company: "",
      token: "",
   })

   useEffect(() => {
      if (document.URL.match("token")) {
         checkAdminToken(document.URL.match("\\?token=([^&]*)")[1])
      } else {
         history.push("/login")
      }
   }, [checkAdminToken])

   useEffect(() => {
      if (adminToken) {
         setData((prevData) => {
            return {
               ...prevData,
               email: adminToken.email,
               token: adminToken.token,
            }
         })
      }
   }, [adminToken])

   const [step, setStep] = useState(1)

   const prevStep = () => {
      setStep((prev) => prev - 1)
   }

   const nextStep = () => {
      setStep((prev) => prev + 1)
   }

   const handleChangeData = (newData) => {
      setData({
         ...data,
         ...newData,
      })
   }
   const handleSubmit = (data) => {
      adminInvite(data)
   }

   const renderSteps = () => {
      switch (step) {
         case 1:
            return <WelcomeMessage nextStep={nextStep} adminToken={adminToken} adminTokenError={adminTokenError} />
         case 2:
            return <PersonalDetails nextStep={nextStep} handleChangeData={handleChangeData} values={data} />
         case 3:
            return (
               <CreatePassword
                  prevStep={prevStep}
                  nextStep={nextStep}
                  handleChangeData={handleChangeData}
                  handleSubmit={handleSubmit}
                  values={data}
               />
            )
         case 4:
            return <Success adminInviteError={adminInviteError} />
         default:
            return null
      }
   }

   return <div className={css.onBoarding}>{renderSteps()}</div>
}
const mapStateToProps = (state) => {
   return {
      adminToken: state.adminToken.data,
      adminTokenError: state.adminToken.error,
      adminInviteError: state.adminInvite.error,
   }
}
export default connect(mapStateToProps, { checkAdminToken, adminInvite })(OnBoarding)
