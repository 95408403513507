export const NOTIFICATION_ADD = "notifications/notification/ADD"
export const NOTIFICATION_REMOVE = "notifications/notification/REMOVE"

export const LOGIN_SUCCESS = "user/login/SUCCESS"
export const LOGIN_FAILURE = "user/login/FAILURE"

export const REGISTER_SUCCESS = "user/register/SUCCESS"
export const REGISTER_FAILURE = "user/register/FAILURE"

export const LOGOUT = "user/logout/SUCCESS"

export const USER_DATA_REQUEST = "data/user/REQUEST"
export const USER_DATA_SUCCESS = "data/user/SUCCESS"
export const USER_DATA_FAILURE = "data/user/FAILURE"

export const UPDATE_USER_REQUEST = "data/user/UPDATE_REQUEST"
export const UPDATE_USER_SUCCESS = "data/user/UPDATE_SUCCESS"
export const UPDATE_USER_FAILURE = "data/user/UPDATE_FAILURE"

export const UPDATE_PASSWORD_REQUEST = "data/password/REQUEST"
export const UPDATE_PASSWORD_SUCCESS = "data/password/SUCCESS"
export const UPDATE_PASSWORD_FAILURE = "data/password/FAILURE"

export const CHECK_TOKEN_SUCCESS = "data/adminToken/SUCCESS"
export const CHECK_TOKEN_FAILURE = "data/adminToken/FAILURE"

export const ADMIN_INVITE_SUCCESS = "data/adminInvite/SUCCESS"
export const ADMIN_INVITE_FAILURE = "data/adminInvite/FAILURE"

export const USERS_REQUEST = "listings/users/REQUEST"
export const USERS_SUCCESS = "listings/users/SUCCESS"
export const USERS_FAILURE = "listings/users/FAILURE"

export const USER_BY_ID_REQUEST = "listings/userById/REQUEST"
export const USER_BY_ID_SUCCESS = "listings/userById/SUCCESS"
export const USER_BY_ID_FAILURE = "listings/userById/FAILURE"

export const SEARCH_USER_REQUEST = "listings/searchUser/REQUEST"
export const SEARCH_USER_SUCCESS = "listings/searchUser/SUCCESS"
export const SEARCH_USER_FAILURE = "listings/searchUser/FAILURE"
export const SEARCH_USER_CLEAR = "listings/searchUser/CLEAR"

export const USER_STAFF_REQUEST = "listings/userStaff/REQUEST"
export const USER_STAFF_SUCCESS = "listings/userStaff/SUCCESS"
export const USER_STAFF_FAILURE = "listings/userStaff/FAILURE"
export const USER_STAFF_CLEAR = "listings/userStaff/CLEAR"

export const INVITE_OWNER_REQUEST = "listings/inviteOwner/REQUEST"
export const INVITE_OWNER_SUCCESS = "listings/inviteOwner/SUCCESS"
export const INVITE_OWNER_FAILURE = "listings/inviteOwner/FAILURE"
export const INVITE_OWNER_CLEAR = "listings/inviteOwner/CLEAR"

export const INVITE_OWNER_BG_CALL_REQUEST = "listings/inviteOwnerBgCall/REQUEST"
export const INVITE_OWNER_BG_CALL_SUCCESS = "listings/inviteOwnerBgCall/SUCCESS"
export const INVITE_OWNER_BG_CALL_FAILURE = "listings/inviteOwnerBgCall/FAILURE"

export const DELETE_USER_REQUEST = "listings/deleteUser/REQUEST"
export const DELETE_USER_SUCCESS = "listings/deleteUser/SUCCESS"
export const DELETE_USER_FAILURE = "listings/deleteUser/FAILURE"

export const ADMINS_REQUEST = "listings/admins/REQUEST"
export const ADMINS_SUCCESS = "listings/admins/SUCCESS"
export const ADMINS_FAILURE = "listings/admins/FAILURE"

export const ADMIN_BY_ID_REQUEST = "listings/adminById/REQUEST"
export const ADMIN_BY_ID_SUCCESS = "listings/adminById/SUCCESS"
export const ADMIN_BY_ID_FAILURE = "listings/adminById/FAILURE"

export const DELETE_ADMIN_REQUEST = "listings/deleteAdmin/REQUEST"
export const DELETE_ADMIN_SUCCESS = "listings/deleteAdmin/SUCCESS"
export const DELETE_ADMIN_FAILURE = "listings/deleteAdmin/FAILURE"

export const INVITE_ADMIN_REQUEST = "listings/inviteAdmin/REQUEST"
export const INVITE_ADMIN_SUCCESS = "listings/inviteAdmin/SUCCESS"
export const INVITE_ADMIN_FAILURE = "listings/inviteAdmin/FAILURE"
export const INVITE_ADMIN_CLEAR = "listings/inviteAdmin/CLEAR"

export const VEHICLES_REQUEST = "listings/vehicles/REQUEST"
export const VEHICLES_SUCCESS = "listings/vehicles/SUCCESS"
export const VEHICLES_FAILURE = "listings/vehicles/FAILURE"

export const MOVIES_REQUEST = "listings/movies/REQUEST"
export const MOVIES_SUCCESS = "listings/movies/SUCCESS"
export const MOVIES_FAILURE = "listings/movies/FAILURE"

export const CREATE_MOVIES_REQUEST = "listings/createMovie/REQUEST"
export const CREATE_MOVIES_SUCCESS = "listings/createMovie/SUCCESS"
export const CREATE_MOVIES_FAILURE = "listings/createMovie/FAILURE"
export const CREATE_MOVIES_CLEAR = "listings/createMovie/CLEAR"

export const MOVIE_BY_ID_REQUEST = "listings/movieById/REQUEST"
export const MOVIE_BY_ID_SUCCESS = "listings/movieById/SUCCESS"
export const MOVIE_BY_ID_FAILURE = "listings/movieById/FAILURE"
export const MOVIE_BY_ID_CLEAR = "listings/movieById/CLEAR"

export const DELETE_MOVIE_REQUEST = "listings/deleteMovie/REQUEST"
export const DELETE_MOVIE_SUCCESS = "listings/deleteMovie/SUCCESS"
export const DELETE_MOVIE_FAILURE = "listings/deleteMovie/FAILURE"

export const DUPLICATE_MOVIE_REQUEST = "listings/duplicateMovie/REQUEST"
export const DUPLICATE_MOVIE_SUCCESS = "listings/duplicateMovie/SUCCESS"
export const DUPLICATE_MOVIE_ERROR = "listings/duplicateMovie/FAILURE"

export const UPDATE_MOVIE_REQUEST = "listings/movieById/UPDATE_REQUEST"
export const UPDATE_MOVIE_SUCCESS = "listings/movieById/UPDATE_SUCCESS"
export const UPDATE_MOVIE_FAILURE = "listings/movieById/UPDATE_FAILURE"

// NEWS

export const NEWS_REQUEST = "listings/news/REQUEST"
export const NEWS_SUCCESS = "listings/news/SUCCESS"
export const NEWS_FAILURE = "listings/news/FAILURE"

export const CREATE_NEWS_REQUEST = "listings/createNews/REQUEST"
export const CREATE_NEWS_SUCCESS = "listings/createNews/SUCCESS"
export const CREATE_NEWS_FAILURE = "listings/createNews/FAILURE"
export const CREATE_NEWS_CLEAR = "listings/createNews/CLEAR"

export const NEWS_BY_ID_REQUEST = "listings/newsById/REQUEST"
export const NEWS_BY_ID_SUCCESS = "listings/newsById/SUCCESS"
export const NEWS_BY_ID_FAILURE = "listings/newsById/FAILURE"
export const NEWS_BY_ID_CLEAR = "listings/newsById/CLEAR"
export const UPDATE_NEWS_REQUEST = "listings/newsById/UPDATE_REQUEST"
export const UPDATE_NEWS_SUCCESS = "listings/newsById/UPDATE_SUCCESS"
export const UPDATE_NEWS_FAILURE = "listings/newsById/UPDATE_FAILURE"

export const DELETE_NEWS_REQUEST = "listings/deleteNews/REQUEST"
export const DELETE_NEWS_SUCCESS = "listings/deleteNews/SUCCESS"
export const DELETE_NEWS_FAILURE = "listings/deleteNews/FAILURE"

export const DUPLICATE_NEWS_REQUEST = "listings/duplicateNews/REQUEST"
export const DUPLICATE_NEWS_SUCCESS = "listings/duplicateNews/SUCCESS"
export const DUPLICATE_NEWS_ERROR = "listings/duplicateNews/FAILURE"

export const CAMPAIGNS_REQUEST = "listings/campaigns/REQUEST"
export const CAMPAIGNS_SUCCESS = "listings/campaigns/SUCCESS"
export const CAMPAIGNS_FAILURE = "listings/campaigns/FAILURE"

export const CAMPAIGN_CREATE_REQUEST = "listings/campaignCreate/REQUEST"
export const CAMPAIGN_CREATE_SUCCESS = "listings/campaignCreate/SUCCESS"
export const CAMPAIGN_CREATE_FAILURE = "listings/campaignCreate/FAILURE"
export const CAMPAIGN_CREATE_CLEAR = "listings/campaignCreate/CLEAR"

export const CAMPAIGN_BY_ID_REQUEST = "listings/campaign/REQUEST"
export const CAMPAIGN_BY_ID_SUCCESS = "listings/campaign/SUCCESS"
export const CAMPAIGN_BY_ID_FAILURE = "listings/campaign/FAILURE"
export const CAMPAIGN_UPDATE_SUCCESS = "listings/campaign/UPDATE"
export const CAMPAIGN_UPDATE_FAILURE = "listings/campaign/FAILURE"

export const DELETE_CAMPAIGN_SUCCESS = "listings/campaignDelete/SUCCESS"
export const DELETE_CAMPAIGN_FAILURE = "listings/campaignDelete/FAILURE"

export const DUPLICATE_CAMPAIGN_REQUEST = "listings/campaignDuplicate/REQUEST"
export const DUPLICATE_CAMPAIGN_SUCCESS = "listings/campaignDuplicate/SUCCESS"
export const DUPLICATE_CAMPAIGN_FAILURE = "listings/campaignDuplicate/FAILURE"

export const CAMPAIGN_TEMPLATES_SUCCESS = "listings/campaignTemplates/SUCCESS"
export const CAMPAIGN_TEMPLATES_FAILURE = "listings/campaignTemplates/FAILURE"

export const CAMPAIGN_TEMPLATE_DATA_SUCCESS = "listings/campaignTemplateData/SUCCESS"
export const CAMPAIGN_TEMPLATE_DATA_FAILURE = "listings/campaignTemplateData/FAILURE"

// Sustainability

export const SUSTAINABILITY_REQUEST = "listings/sustainability/REQUEST"
export const SUSTAINABILITY_SUCCESS = "listings/sustainability/SUCCESS"
export const SUSTAINABILITY_FAILURE = "listings/sustainability/FAILURE"

export const CREATE_SUSTAINABILITY_REQUEST = "listings/createSustainability/REQUEST"
export const CREATE_SUSTAINABILITY_SUCCESS = "listings/createSustainability/SUCCESS"
export const CREATE_SUSTAINABILITY_FAILURE = "listings/createSustainability/FAILURE"
export const CREATE_SUSTAINABILITY_CLEAR = "listings/createSustainability/CLEAR"

export const SUSTAINABILITY_BY_ID_REQUEST = "listings/sustainabilityById/REQUEST"
export const SUSTAINABILITY_BY_ID_SUCCESS = "listings/sustainabilityById/SUCCESS"
export const SUSTAINABILITY_BY_ID_FAILURE = "listings/sustainabilityById/FAILURE"
export const SUSTAINABILITY_BY_ID_CLEAR = "listings/sustainabilityById/CLEAR"
export const UPDATE_SUSTAINABILITY_REQUEST = "listings/sustainabilityById/UPDATE_REQUEST"
export const UPDATE_SUSTAINABILITY_SUCCESS = "listings/sustainabilityById/UPDATE_SUCCESS"
export const UPDATE_SUSTAINABILITY_FAILURE = "listings/sustainabilityById/UPDATE_FAILURE"

export const DELETE_SUSTAINABILITY_REQUEST = "listings/deleteSustainability/REQUEST"
export const DELETE_SUSTAINABILITY_SUCCESS = "listings/deleteSustainability/SUCCESS"
export const DELETE_SUSTAINABILITY_FAILURE = "listings/deleteSustainability/FAILURE"

export const DUPLICATE_SUSTAINABILITY_REQUEST = "listings/duplicateSustainability/REQUEST"
export const DUPLICATE_SUSTAINABILITY_SUCCESS = "listings/duplicateSustainability/SUCCESS"
export const DUPLICATE_SUSTAINABILITY_ERROR = "listings/duplicateSustainability/FAILURE"

// Surveys

export const SURVEYS_REQUEST = "listings/surveys/REQUEST"
export const SURVEYS_SUCCESS = "listings/surveys/SUCCESS"
export const SURVEYS_FAILURE = "listings/surveys/FAILURE"

export const CREATE_SURVEYS_REQUEST = "listings/createSurveys/REQUEST"
export const CREATE_SURVEYS_SUCCESS = "listings/createSurveys/SUCCESS"
export const CREATE_SURVEYS_FAILURE = "listings/createSurveys/FAILURE"
export const CREATE_SURVEYS_CLEAR = "listings/createSurveys/CLEAR"

export const SURVEYS_BY_ID_REQUEST = "listings/surveysById/REQUEST"
export const SURVEYS_BY_ID_SUCCESS = "listings/surveysById/SUCCESS"
export const SURVEYS_BY_ID_FAILURE = "listings/surveysById/FAILURE"
export const SURVEYS_BY_ID_CLEAR = "listings/surveysById/CLEAR"
export const UPDATE_SURVEYS_REQUEST = "listings/surveysById/UPDATE_REQUEST"
export const UPDATE_SURVEYS_SUCCESS = "listings/surveysById/UPDATE_SUCCESS"
export const UPDATE_SURVEYS_FAILURE = "listings/surveysById/UPDATE_FAILURE"

export const DELETE_SURVEYS_REQUEST = "listings/deleteSurveys/REQUEST"
export const DELETE_SURVEYS_SUCCESS = "listings/deleteSurveys/SUCCESS"
export const DELETE_SURVEYS_FAILURE = "listings/deleteSurveys/FAILURE"

export const DUPLICATE_SURVEYS_REQUEST = "listings/duplicateSurveys/REQUEST"
export const DUPLICATE_SURVEYS_SUCCESS = "listings/duplicateSurveys/SUCCESS"
export const DUPLICATE_SURVEYS_ERROR = "listings/duplicateSurveys/FAILURE"

//Statistics home
export const STATISTICS_REQUEST = "listings/statistics/REQUEST"
export const STATISTICS_SUCCESS = "listings/statistics/SUCCESS"
export const STATISTICS_ERROR = "listings/statistics/FAILURE"
// Templates
export const TEMPLATES_REQUEST = "listings/templates/REQUEST"
export const TEMPLATES_SUCCESS = "listings/templates/SUCCESS"
export const TEMPLATES_ERROR = "listings/templates/FAILURE"

export const VIDEOS_REQUEST = "listings/videos/REQUEST"
export const VIDEOS_SUCCESS = "listings/videos/SUCCESS"
// export const VIDEOS_ERROR = "listings/videos/FAILURE";

export const SCHEDULING_REQUEST = "listings/scheduling/REQUEST"
export const SCHEDULING_SUCCESS = "listings/scheduling/SUCCESS"
export const SCHEDULING_FAILURE = "listings/scheduling/FAILURE"

//Advanced Analytics
export const ANALYTICS_USERS_REQUEST = "listings/analyticsUsers/REQUEST"
export const ANALYTICS_USERS_SUCCESS = "listings/analyticsUsers/SUCCESS"
export const ANALYTICS_USERS_FAILURE = "listings/analyticsUsers/FAILURE"

export const YEAR_CHART_REQUEST = "listings/yearChart/REQUEST"
export const YEAR_CHART_SUCCESS = "listings/yearChart/SUCCESS"
export const YEAR_CHART_FAILURE = "listings/yearChart/FAILURE"

export const ANALYTICS_CONTENT_REQUEST = "listings/analyticsContent/REQUEST"
export const ANALYTICS_CONTENT_SUCCESS = "listings/analyticsContent/SUCCESS"
export const ANALYTICS_CONTENT_FAILURE = "listings/analyticsContent/FAILURE"

export const ANALYTICS_TOP_REQUEST = "listings/analyticsTop/REQUEST"
export const ANALYTICS_TOP_SUCCESS = "listings/analyticsTop/SUCCESS"
export const ANALYTICS_TOP_FAILURE = "listings/analyticsTop/FAILURE"
