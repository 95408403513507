import React, { useState, useEffect } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import EditBlock from "../../../components/EditBlock"
import useValidationSchema from "../../../customHooks/useValidation"
import { campaignsSettingsSchema, handleValidate } from "../../../helpers/validation"
import { DEFAULT_CURRENCY, DEFAULT_DEALER } from "../../../utils/constants"
import { SelectDealer } from "../../../components/SelectDealer/SelectDealer"
import TheHubExperienceCompany from "../../../components/TheHubExperienceCompany"

import css from "../CampaignsEdit.module.scss"

const CreateSettings = ({ data, update }) => {
   const [touched, setTouched] = useState(false)
   const [complete, setComplete] = useState(false)
   const [formData, setFormData] = useState({
      title: "",
      subject_line: "",
      intro_text: "",
      dealer: DEFAULT_DEALER,
      customer_campaign_id: "",
      currency: DEFAULT_CURRENCY,
      advertisement_price: 0,
      description: "",
      hub_experience_company: false,
      push_notification: false,
   })
   const [formErrors, setFormErrors] = useState({})
   //const query = new URLSearchParams(useLocation().search)

   const validate = useValidationSchema(campaignsSettingsSchema, formData)

   const handleChange = (e) => {
      const { name, value } = e.target

      setFormData({
         ...formData,
         [name]: value,
      })
      setTouched(true)
   }
   const handleChangeCheckbox = (e) => {
      const { name, checked } = e.target

      setFormData({
         ...formData,
         [name]: checked,
      })
      setTouched(true)
   }

   useEffect(() => {
      setFormData({
         title: data.title ? data.title : "",
         subject_line: data.subject_line ? data.subject_line : "",
         intro_text: data.intro_text ? data.intro_text : "",
         dealer: data?.dealer ?? DEFAULT_DEALER,
         customer_campaign_id: data?.customer_campaign_id ?? "",
         currency: data?.currency ?? DEFAULT_CURRENCY,
         advertisement_price: data?.advertisement_price ?? 0,
         description: data?.description ?? "",
         hub_experience_company: data?.hub_experience_company ?? false,
         push_notification: data?.push_notification ?? false,
      })
      setTouched(false)
      setFormErrors({})
      if (data.title && data.subject_line && data.intro_text) {
         setComplete(true)
      } else {
         setComplete(false)
      }
   }, [data])

   const handleSave = async () => {
      const isValid = await handleValidate(setFormErrors, validate)
      // const segmentation = data.segmentation ? JSON.parse(data.segmentation) : SEGMENTATION_BY_DEFAULT
      // let newSegmentation = null
      // if (formData.dealer === "Scania Syd") {
      //    newSegmentation = {
      //       ...segmentation,
      //       ownership: {
      //          ...segmentation.ownership,
      //          sales_dealer: ["Scania Syd"],
      //       },
      //    }
      // } else if (formData.dealer === "Scania Sthlm Norr") {
      //    newSegmentation = {
      //       ...segmentation,
      //       ownership: {
      //          ...segmentation.ownership,
      //          sales_dealer: ["Scania Sthlm Norr"],
      //       },
      //    }
      // } else if (formData.dealer === "Scania Sverige") {
      //    newSegmentation = {
      //       ...segmentation,
      //       ownership: {
      //          ...segmentation.ownership,
      //          sales_dealer: [],
      //       },
      //    }
      // }

      if (isValid) {
         await update({
            subject_line: formData.subject_line,
            intro_text: formData.intro_text,
            title: formData.title,
            dealer: formData.dealer,
            customer_campaign_id: formData.customer_campaign_id,
            currency: formData.currency,
            advertisement_price: formData.advertisement_price,
            description: formData.description,
            // segmentation: JSON.stringify(newSegmentation),
            hub_experience_company: formData.hub_experience_company,
            push_notification: formData.push_notification,
         })
      }
   }

   const handleDiscard = () => {
      setFormData({
         title: data.title ? data.title : "",
         subject_line: data.subject_line ? data.subject_line : "",
         intro_text: data.intro_text ? data.intro_text : "",
         dealer: data.dealer ? data.dealer : DEFAULT_DEALER,
         customer_campaign_id: data?.customer_campaign_id ?? "",
         currency: data?.currency ?? DEFAULT_CURRENCY,
         advertisement_price: data?.advertisement_price ?? 0,
         description: data?.description ?? "",
         hub_experience_company: data?.hub_experience_company ?? false,
         push_notification: data?.push_notification ?? false,
      })
      setFormErrors({})
      setTouched(false)
   }

   return (
      <EditBlock title="Settings" titleNum="2" complete={complete}>
         <Form className={css.settings__form}>
            <Row className="mb-4">
               <Form.Group as={Col}>
                  <Form.Label>Internal title</Form.Label>
                  <Form.Control
                     type="text"
                     placeholder="Enter internal title"
                     onChange={handleChange}
                     value={formData.title}
                     name="title"
                     isInvalid={!!formErrors.title}
                  />
                  <Form.Control.Feedback type="invalid">{formErrors.title}</Form.Control.Feedback>
               </Form.Group>
               <SelectDealer value={formData.dealer} onChange={handleChange} errors={formErrors.dealer} />
            </Row>
            <Form.Group className="mb-4">
               <Form.Label>Subject line</Form.Label>
               <Form.Control
                  type="text"
                  placeholder="Enter your subject line"
                  onChange={handleChange}
                  value={formData.subject_line}
                  name="subject_line"
                  isInvalid={!!formErrors.subject_line}
               />
               <Form.Control.Feedback type="invalid">{formErrors.subject_line}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Intro text (pre-header)</Form.Label>
               <Form.Control
                  type="text"
                  placeholder="Enter your intro text"
                  onChange={handleChange}
                  value={formData.intro_text}
                  name="intro_text"
                  isInvalid={!!formErrors.intro_text}
               />
               <Form.Control.Feedback type="invalid">{formErrors.intro_text}</Form.Control.Feedback>
            </Form.Group>
            <Row className="mb-4">
               <Form.Group as={Col}>
                  <Form.Label>Campaign ID</Form.Label>
                  <Form.Control
                     type="text"
                     placeholder="Enter your campaign ID"
                     onChange={handleChange}
                     value={formData.customer_campaign_id}
                     name="customer_campaign_id"
                     isInvalid={!!formErrors.customer_campaign_id}
                  />
                  <Form.Control.Feedback type="invalid">{formErrors.customer_campaign_id}</Form.Control.Feedback>
               </Form.Group>
               <Form.Group as={Col}>
                  <Form.Label>Campaign Description</Form.Label>
                  <Form.Control
                     type="text"
                     placeholder="Enter your campaign description"
                     onChange={handleChange}
                     value={formData.description}
                     name="description"
                     isInvalid={!!formErrors.description}
                  />
                  <Form.Control.Feedback type="invalid">{formErrors.description}</Form.Control.Feedback>
               </Form.Group>
            </Row>
            <Row className="mb-4">
               <Form.Group as={Col}>
                  <Form.Label>Advertisement price</Form.Label>
                  <Form.Control
                     type="number"
                     placeholder="Enter your ad. price"
                     onChange={handleChange}
                     value={formData.advertisement_price}
                     name="advertisement_price"
                     isInvalid={!!formErrors.advertisement_price}
                  />
               </Form.Group>
               <Form.Group as={Col}>
                  <Form.Label>Currency</Form.Label>
                  <Form.Select name="currency" onChange={handleChange} value={formData.currency}>
                     <option value="SEK">SEK</option>
                     <option value="EUR">EUR</option>
                  </Form.Select>
               </Form.Group>
            </Row>
            <Form.Group className="mb-4">
               <Form.Check
                  type="checkbox"
                  label="Push notification"
                  bsPrefix="form-check-p"
                  onChange={handleChangeCheckbox}
                  name="push_notification"
                  checked={formData.push_notification}
               />
            </Form.Group>
            <TheHubExperienceCompany
               hub_experience_company={formData.hub_experience_company}
               status={data.status}
               handleChange={handleChangeCheckbox}
            />

            <div className={css.settings__btns}>
               {touched ? (
                  <Button onClick={handleDiscard} variant="outline-primary">
                     Discard
                  </Button>
               ) : null}
               <Button disabled={!touched} onClick={handleSave}>
                  {touched ? "Save" : "Saved"}
               </Button>
            </div>
         </Form>
      </EditBlock>
   )
}
export default CreateSettings
