import React from "react"
import { UpIcon, DownIcon } from "../../assets/icons"

import css from "./Analytics.module.scss"

const AverageInfo = ({ title, previous, week, timeMin = false }) => {
   const renderPrevious = () => {
      if (week > previous || week === previous) {
         const percentage = 100 - (previous * 100) / week
         return (
            <>
               <UpIcon /> Up {percentage.toFixed(0)} % this week
            </>
         )
      } else if (week < previous) {
         const percentage = 100 - (week * 100) / previous
         return (
            <>
               <DownIcon /> Down {percentage.toFixed(0)} % this week
            </>
         )
      }
   }

   const min = (week / 60).toFixed(2)

   return (
      <div className={css.average}>
         <h3>{title}</h3>
         <div className={css.average__info}>
            {timeMin ? (
               <p>
                  <span>{min}</span> min/session
               </p>
            ) : (
               <p>
                  <span>{week}</span>/week
               </p>
            )}

            <div className={css.average__info_prev}>{renderPrevious()}</div>
         </div>
      </div>
   )
}
export default AverageInfo
