import { useState, useEffect } from "react"
import { connect } from "react-redux"
import CreateBtnHeader from "../../components/CreateBtnHeader"
import Header from "../../components/Header"
import SearchBar from "../../components/SearchBar"
import UsersList from "../../components/TableLists/UsersList"
import NewOwnerModal from "./NewOwnerModal"
import { getUsers, getHomeStatistics } from "../../redux/actions/listingActions"

const Users = ({ getUsers, users, fetch, error, getHomeStatistics, statistics }) => {
   const [modalShow, setModalShow] = useState(false)

   const dataTitles = [
      {
         name: "Name",
         key: "contact_first_name",
      },
      {
         name: "Email",
         key: "contact_email",
      },
      {
         name: "Company",
         key: "name",
      },
      {
         name: "Vehicles",
         key: "vehicle_count",
      },
      {
         name: "Staff",
         key: "staff_count",
      },

      {
         name: "Orgno.",
         key: "orgno",
      },
      {
         name: "Status",
         key: "status",
      },
   ]

   useEffect(() => {
      getUsers()
      getHomeStatistics()
   }, [getUsers, getHomeStatistics])

   const getDataByQuery = (sort, page = 1) => {
      getUsers(sort, page)
   }

   const handleSearch = (inputValue) => {
      getUsers(null, 1, inputValue)
   }

   const headerData = [
      {
         name: "Active owners",
         value: statistics?.owner_extended.active,
      },
      {
         name: "Active drivers",
         value: statistics?.drivers.active,
      },
      {
         name: "Active vehicles",
         value: statistics?.vehicles.active,
      },
   ]

   return (
      <>
         <Header title="Users" userInfoActive={headerData}>
            <SearchBar handleSearch={handleSearch} />
            <CreateBtnHeader title="New Owner" clickHandler={() => setModalShow(true)} />
         </Header>

         <UsersList
            url="/users"
            data={users}
            fetch={fetch}
            error={error}
            getDataByQuery={getDataByQuery}
            dataTitles={dataTitles}
         />

         <NewOwnerModal show={modalShow} onHide={() => setModalShow(false)} />
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      users: state.users.data,
      fetch: state.users.fetching,
      error: state.users.error,
      statistics: state.statistics.data,
   }
}
export default connect(mapStateToProps, { getUsers, getHomeStatistics })(Users)
