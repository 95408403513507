import React from "react"
import css from "./UsersHeaderInfo.module.scss"

const UsersHeaderInfo = ({ data }) => {
   return (
      <div className={css.usersInfo}>
         {data.map((item) => {
            return (
               <div key={item.name} className={css.usersInfo__inner}>
                  <span>{item.name}</span>: {item.value}
               </div>
            )
         })}
      </div>
   )
}
export default UsersHeaderInfo
