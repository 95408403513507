import React from "react"
import Modal from "../../../components/Modal"

const PublishModal = ({ show, onHide, handleSubmit, status }) => {
   const renderModal = () => {
      if (status === "Draft") {
         return (
            <Modal
               title="Publish Survey"
               subtitle="Click the publish button to confirm that you want to publish the survey and have it send to the segmented app users."
               show={show}
               onHide={onHide}
               handleSubmit={handleSubmit}
               btnCreateText="Publish survey"
               btnVariant="success"
            ></Modal>
         )
      } else if (status === "Published") {
         return (
            <Modal
               title="Unpublish Survey"
               subtitle="Click the publish button to confirm that you want to unpublish the survey and have it removed from the users feeds."
               show={show}
               onHide={onHide}
               handleSubmit={handleSubmit}
               btnCreateText="Unpublish survey"
               btnVariant="warning"
            ></Modal>
         )
      } else if (status === "Unpublished") {
         return (
            <Modal
               title="Publish Survey"
               subtitle="Click the publish button to confirm that you want to publish the survey and have it send to the segmented app users."
               show={show}
               onHide={onHide}
               handleSubmit={handleSubmit}
               btnCreateText="Republish survey"
               btnVariant="success"
            ></Modal>
         )
      }
   }
   return renderModal()
}
export default PublishModal
