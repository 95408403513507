import axios from "../../utils/axios"
import {
   USER_DATA_REQUEST,
   USER_DATA_SUCCESS,
   USER_DATA_FAILURE,
   UPDATE_USER_REQUEST,
   UPDATE_USER_SUCCESS,
   UPDATE_USER_FAILURE,
   UPDATE_PASSWORD_REQUEST,
   UPDATE_PASSWORD_SUCCESS,
   UPDATE_PASSWORD_FAILURE,
} from "./actionTypes"

import { callFailure, callSuccess, callRequest } from "./functions"
import { addNotification } from "./otherActions"

export const getUserData = () => (dispatch) => {
   dispatch(callRequest(USER_DATA_REQUEST))
   axios
      .get(`/user`)
      .then((res) => {
         return dispatch(callSuccess(USER_DATA_SUCCESS, res.data.data))
      })
      .catch((error) => {
         return dispatch(callFailure(USER_DATA_FAILURE, error))
      })
}
export const updateUserData = (data) => (dispatch) => {
   dispatch(callRequest(UPDATE_USER_REQUEST))
   axios
      .put(`/user`, data)
      .then((response) => {
         dispatch(callSuccess(UPDATE_USER_SUCCESS, response.data.data))
         dispatch(
            addNotification({
               type: "success",
               text: "Changes Saved",
            })
         )
         return
      })
      .catch((error) => {
         if (error.response) {
            dispatch(callFailure(UPDATE_USER_FAILURE, error.response.data.data))
            dispatch(
               addNotification({
                  type: "error",
                  text: "Sorry, we have error",
               })
            )
            return
         }
      })
}
export const changePassword = (data) => (dispatch) => {
   dispatch(callRequest(UPDATE_PASSWORD_REQUEST))
   axios
      .patch(`/user`, data)
      .then((response) => {
         dispatch(callSuccess(UPDATE_PASSWORD_SUCCESS, response.data.data))
         dispatch(
            addNotification({
               type: "success",
               text: "Password changed",
            })
         )
         return
      })
      .catch((error) => {
         if (error.response) {
            dispatch(callFailure(UPDATE_PASSWORD_FAILURE, error.response.data.message))
            dispatch(
               addNotification({
                  type: "error",
                  text: "Sorry, we have error",
               })
            )
            return
         }
      })
}
